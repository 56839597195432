import Button from "@/common/components/button";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import Stepper from "@/common/components/stepper/Stepper";
import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import {
  createCompanyJob,
  deleteCompanyJob,
  processCompanyJob,
} from "@/redux/reducers/dataImport/company/slices/import";
import {
  createProductJob,
  deleteProductJob,
  processProductJob,
} from "@/redux/reducers/dataImport/product/slices/import";
import {
  createProspectJob,
  deleteProspectJob,
  processProspectJob,
} from "@/redux/reducers/dataImport/prospect/slices/import";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  KnDocumentListResponseKnFirestoreImportJobResults,
  KnFirestoreImportJobImportJobTypeEnum,
} from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Badge, Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowRightOutline } from "heroicons-react";
import React from "react";
import { FormProvider, SubmitErrorHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ImportTypeStep } from "./steps/ImportTypeStep";
import MapStep from "./steps/MapStep";
import UploadStep from "./steps/UploadStep";
import { DataImportFormFields, dataImportSchema, DataImportSchema } from "./types";

const requiredFieldsMap = {
  [KnFirestoreImportJobImportJobTypeEnum.Company]: ["name"],
  [KnFirestoreImportJobImportJobTypeEnum.Contact]: ["firstName", "lastName", "phoneNumber"],
  [KnFirestoreImportJobImportJobTypeEnum.Product]: ["name"],
};

export default function NewDataImport() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const importData = useAppSelector((selector) => selector.dataImport.list.data);
  const prospectLoading = useAppSelector((selector) => selector.dataImport.prospect.import.loading);
  const productLoading = useAppSelector((selector) => selector.dataImport.product.import.loading);
  const companyLoading = useAppSelector((selector) => selector.dataImport.company.import.loading);
  const loading = companyLoading || productLoading || prospectLoading;

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const methods = useForm<DataImportSchema>({
    resolver: yupResolver(dataImportSchema),
    context: { step: activeStep },
    defaultValues: { columnMappingStrategyList: [] },
  });

  const { watch, handleSubmit, setValue } = methods;

  const importType = watch("importType");
  const id = watch("id");
  const importJobName = watch("importJobName");

  const handleCancelImport = async () => {
    const onCancelAction = {
      [KnFirestoreImportJobImportJobTypeEnum.Company]: (id: string) => dispatch(deleteCompanyJob(id)).unwrap(),
      [KnFirestoreImportJobImportJobTypeEnum.Contact]: (id: string) => dispatch(deleteProspectJob(id)).unwrap(),
      [KnFirestoreImportJobImportJobTypeEnum.Product]: (id: string) => dispatch(deleteProductJob(id)).unwrap(),
    };

    if (id) {
      await onCancelAction[importType as keyof typeof onCancelAction](id);
    }

    navigate(routes.dataImport.table.path);
  };

  const onError: SubmitErrorHandler<Yup.InferType<typeof dataImportSchema>> = (errors) => {
    console.log(errors, watch());
  };

  const onSubmit = async (data: DataImportFormFields) => {
    if (activeStep === 0) {
      setActiveStep((step) => step + 1);
    }
    if (activeStep === 1 && id) {
      let response = null;
      try {
        switch (importType) {
          case KnFirestoreImportJobImportJobTypeEnum.Company:
            response = await dispatch(createCompanyJob({ id, importJobName })).unwrap();
            break;
          case KnFirestoreImportJobImportJobTypeEnum.Contact:
            response = await dispatch(createProspectJob({ id, importJobName })).unwrap();
            break;
          case KnFirestoreImportJobImportJobTypeEnum.Product:
            response = await dispatch(createProductJob({ id, importJobName })).unwrap();
            break;
          default:
        }
      } catch (err) {
        toast.error(err);
      }

      if (
        response?.originalFileColumnNameAndExampleDataMap &&
        response?.firestoreDocumentFieldAndMatchedColumnHeaderMap
      ) {
        const matchedColumnsHeader = response.firestoreDocumentFieldAndMatchedColumnHeaderMap ?? {};

        const columnMappingStrategyList = Object.entries(response.originalFileColumnNameAndExampleDataMap).map(
          ([originalFileColumnName, sample]) => {
            const mappedField = matchedColumnsHeader[originalFileColumnName] || "";
            return {
              originalFileColumnName,
              sample,
              mappedFirestoreFieldName: mappedField === "<UNKNOWN>" ? "" : mappedField,
              object: importType!,
              overwriteExistingValueIfPresent: false,
            };
          },
        );
        setValue("columnMappingStrategyList", columnMappingStrategyList);

        setActiveStep((step) => step + 1);
      }
      return;
    }

    const columnMappingStrategyList =
      (data?.columnMappingStrategyList?.map(
        ({ originalFileColumnName, mappedFirestoreFieldName, overwriteExistingValueIfPresent }) => ({
          originalFileColumnName,
          mappedFirestoreFieldName,
          overwriteExistingValueIfPresent,
        }),
      ) as KnDocumentListResponseKnFirestoreImportJobResults) || [];

    const validateRequiredFields = (importType: KnFirestoreImportJobImportJobTypeEnum) => {
      const requiredFields = requiredFieldsMap[importType] || [];
      const mappedFields = data.columnMappingStrategyList?.filter(
        (item) => item.mappedFirestoreFieldName && requiredFields.includes(item.mappedFirestoreFieldName),
      );

      if (mappedFields?.length !== requiredFields.length) {
        const formattedFields = requiredFields
          .map((field) => field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()))
          .join(", ")
          .replace(/,([^,]*)$/, " and$1");
        toast.error(`${formattedFields} are required fields.`, {
          autoClose: 10000,
          closeButton: ({ closeToast }) => (
            <button
              onClick={closeToast}
              style={{
                marginRight: "12px",
                padding: "6px",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: "white",
              }}
            >
              ✖
            </button>
          ),
        });
        return false;
      }
      return true;
    };

    if (!id || !importType || !requiredFieldsMap[importType]) return;

    if (!validateRequiredFields(importType)) return;

    try {
      switch (importType) {
        case KnFirestoreImportJobImportJobTypeEnum.Company:
          await dispatch(processCompanyJob({ id, columnMappingStrategyList })).unwrap();
          break;
        case KnFirestoreImportJobImportJobTypeEnum.Contact:
          await dispatch(processProspectJob({ id, columnMappingStrategyList, leadSource: data.leadSource })).unwrap();
          break;
        case KnFirestoreImportJobImportJobTypeEnum.Product:
          await dispatch(processProductJob({ id, columnMappingStrategyList })).unwrap();
          break;
      }
      navigate(routes.dataImport.table.path);
    } catch (err) {
      toast.error(err);
    }
  };

  const goToPreviousStep = () => {
    if (activeStep === 0) return;
    setActiveStep((step) => step - 1);
  };

  const controlStepLabel = activeStep >= 2 ? "Finish Import" : activeStep > 0 ? "Upload" : "Next";

  return (
    <>
      <FullLoadingOverlay overlay loading={loading} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb="16px">
        <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
          <Typography fontSize="24px" fontWeight="bold">
            Data Import
          </Typography>
          <Badge
            badgeContent={importData?.length || 0}
            max={999}
            color="primary"
            sx={{
              background: theme.palette.mint.p,
              color: theme.palette.txt.default,
            }}
          />
        </Box>

        <Button data-testid="openTaskDrawer" variant="secondary" onClick={handleCancelImport}>
          Cancel Import
        </Button>
      </Stack>

      <Stack
        direction="column"
        style={{
          background: "#fff",
          padding: "20px",
        }}
      >
        <Box>
          <Stepper
            steps={[
              { idx: 0, label: "Type" },
              { idx: 1, label: "Upload" },
              { idx: 2, label: "Map" },
            ]}
            activeStep={activeStep}
          />
        </Box>

        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <FormProvider {...methods}>
            <Stack
              direction="column"
              style={{
                background: "#fff",
                padding: "42px 24px",
                maxWidth: "1500px",
                margin: "0 auto",
              }}
            >
              {activeStep === 0 && <ImportTypeStep />}
              {activeStep === 1 && <UploadStep />}
              {activeStep === 2 && <MapStep />}
            </Stack>
          </FormProvider>
        </form>

        <Divider sx={{ margin: "20px 0px" }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: activeStep === 0 ? "flex-end" : "space-between",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {activeStep > 0 && (
            <Button variant="secondary" onClick={goToPreviousStep}>
              Back
            </Button>
          )}

          <Button
            variant="primary"
            onClick={() => {
              handleSubmit(onSubmit, onError)();
            }}
            rightIcon={<ArrowRightOutline size={16} />}
          >
            {controlStepLabel}
          </Button>
        </Box>
      </Stack>
    </>
  );
}
