import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router";
import { CogOutline } from "heroicons-react";
import { SquaresPlusIcon, ViewColumnsIcon } from "@heroicons/react/24/outline";
import routes from "@/common/constants/routes";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

type SettingsMenuProps = {
  onEditPipeline: () => void;
};

export default function SettingsMenu({ onEditPipeline }: SettingsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonBase
        sx={(theme) => ({
          p: 1.2,
          bgcolor: "white",
          borderRadius: 2,
          border: 1,
          borderColor: theme.palette.commonColors.light,
        })}
        aria-controls="demo-customized-menu"
        onClick={handleClick}
      >
        <CogOutline />
      </ButtonBase>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div style={{ margin: "10px" }}>
          <MenuItem
            disableRipple
            onClick={() => {
              onEditPipeline();
            }}
            sx={{ gap: 1 }}
          >
            <SquaresPlusIcon width={24} />
            Edit Deal Flow Pipeline
          </MenuItem>

          <Divider sx={{ my: 0.5 }} />
          <MenuItem
            disableRipple
            onClick={() => {
              // navigate(routes.dealFlow.create.path);
            }}
            sx={{ gap: 1 }}
          >
            <ViewColumnsIcon width={24} />
            Create Deal Flow Pipeline
          </MenuItem>
        </div>
      </StyledMenu>
    </div>
  );
}
