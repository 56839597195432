import { GuardProps } from "@/common/components/route-guard/types";
import routes from "@/common/constants/routes";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import { getBillingInfo } from "@/redux/reducers/settings/billing";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";

/**
 * Enforce user has accepted Terms of Service.
 */
export const BillingGuard = ({ children }: GuardProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const { info, loading } = useAppSelector(({ settings }) => settings.billing);

  useEffect(() => {
    dispatch(getBillingInfo(user?.team_id));
  }, [dispatch, user?.team_id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!info) {
    return <Navigate to={routes.onboard.billing.path} />;
  }

  return children;
};
