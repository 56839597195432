import { combineReducers } from "@reduxjs/toolkit";
import listCallsReducer from "./listCalls";
import listAgentsReducer from "./listAgents";
import upsertAgentReducer from "./upsertAgent";
import callToActionsReducer from "./callToActions";
import listTransferPhoneNumberReducer from "./listTransferPhoneNumber";
import summaryReducer from "./summary";

const aiVoiceReducer = combineReducers({
  listCalls: listCallsReducer,
  listAgents: listAgentsReducer,
  upsertAgent: upsertAgentReducer,
  callToActions: callToActionsReducer,
  listTransferPhoneNumber: listTransferPhoneNumberReducer,
  summary: summaryReducer,
});

export default aiVoiceReducer;
