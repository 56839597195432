import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo } from "react";
import { getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import FormLabel from "@/common/components/form-label/FormLabel";
import Dropdown from "@/common/components/dropdown";

export default function DealAssigneeChangeForm() {
  const dispatch = useAppDispatch();
  const methods = useFormContext();
  const { control } = methods;
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);

  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  const pipelineOptions = useMemo(() => {
    if (!pipelines) return [];

    return pipelines.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [pipelines]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Controller
        name="event.dealFlowId"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel label="Pipeline" aria-required />
            <Dropdown
              options={pipelineOptions}
              value={field.value}
              onChange={(option) => field.onChange(option.value)}
            />
          </FormControl>
        )}
      />
    </div>
  );
}
