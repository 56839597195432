import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import TsTable from "@/common/components/ts-table";
import { useAppSelector } from "@/redux/store";
import { SequenceContactsFiltersInput } from "@/types/sequence";
import { Table } from "@tanstack/react-table";

import { ReactComponent as Identification } from "@/common/assets/svg/sidebar/identification.svg";
import { DataImportSequenceContacts } from "@/types/prospects";

type ContactsTableProps = {
  filters: SequenceContactsFiltersInput | undefined;
  table: Table<DataImportSequenceContacts>;
};

export default function ContactsTable({ table, filters }: ContactsTableProps) {
  const { contacts, loadingContacts, errorContacts } = useAppSelector((selector) => selector.prospects.listProspects);
  const hasFilter = filters?.name && filters?.name.length > 0;

  if (loadingContacts) return <LoadingSpinner />;

  return (
    <>
      {contacts && (
        <TsTable
          table={table}
          outerSx={{ justifyContent: "space-between" }}
          innerSx={{ height: "calc(100dvh - 236px)", overflowY: "auto" }}
          headStyles={{ position: "sticky", top: 0, left: 0, right: 0 }}
          notFoundProps={{
            title:
              contacts.length === 0 && hasFilter ? "No contacts found that match your criteria" : "No contacts found",
            description:
              contacts.length === 0 && hasFilter ? (
                <span>Try a different search parameter or simply clear the filter</span>
              ) : (
                <span>No contacts were found for this record.</span>
              ),
            Icon: Identification,
          }}
        />
      )}
    </>
  );
}
