import AttachmentInput from "@/common/components/attachment/Attachment";
import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { TimePickerInput } from "@/common/components/time-picker";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import DrawerLayout from "@/layouts/DrawerLayout";
import ViewProspectDrawer from "@/pages/prospects/view/ViewProspectDrawer";
import { Deal } from "@/redux/reducers/dealFlow/slices/types";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { openViewDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import { getProspects } from "@/redux/reducers/prospects/slices/listProspects";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { closeViewTaskDrawer, deleteTask, getTasks, setCurrentTask } from "@/redux/reducers/tasks/slices/listTask";
import { updateTask } from "@/redux/reducers/tasks/slices/updateTask";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { AttachmentDto, TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import { ErrorMessage } from "@hookform/error-message";
import { Divider, FormControl, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { ChevronLeftOutline, ChevronRightOutline, PencilAltOutline, TrashOutline } from "heroicons-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@/common/components/date-picker/DatePicker";
import ViewInboxMessageDrawer from "@/pages/inbox/view/ViewInboxMessagesDrawer";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { Box } from "@mui/system";

const errorMessageStyle = {
  color: "red",
  fontSize: "12px",
};

type TaskFormProps = {
  type: TaskRequestTypeEnum;
  status: TaskRequestStatusEnum;
  dueDate?: Date | undefined;
  dueTime?: Date | undefined;
  description?: string | undefined;
  attachment?: AttachmentDto[] | undefined;
  prospect?: string;
  deal?: string;
  assignee?: string;
};

export const ViewTaskDrawerForm = ({ disablePagination = false }: { disablePagination?: boolean }) => {
  const dispatch = useAppDispatch();
  const currentTask = useAppSelector(({ tasks }) => tasks.listTask.currentTask);

  const openInboxDrawer = () => {
    dispatch(setCurrentProspect(currentTask?.prospect));
    dispatch(openViewDrawer());
  };

  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const { deleteConfirm } = useConfirmModal();
  const loadingCurrentTask = useAppSelector(({ tasks }) => tasks.listTask.loadingCurrentTask);
  const taskCurrentIndex =
    useAppSelector(({ tasks }) => tasks.listTask.tasks?.findIndex((task) => task.id === currentTask?.id)) || 0;
  const { tasks } = useAppSelector(({ tasks }) => tasks.listTask);

  const toggleDrawer = () => dispatch(closeViewTaskDrawer());
  const users = useAppSelector(({ team }) => team.listUsers.users);
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      type: currentTask?.type,
      status: currentTask?.status,
      assignee: currentTask?.assignee?.id,
      description: currentTask?.description,
      dueDate: currentTask?.dueDate,
      dueTime: currentTask?.dueDate,
      deal: currentTask?.deal?.id,
      prospect: currentTask?.prospect?.id,
      attachments: currentTask?.attachments || [],
    },
  });
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const { control, handleSubmit, watch, clearErrors, reset } = methods;
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (currentTask) {
      reset({
        type: currentTask.type,
        status: currentTask.status,
        assignee: currentTask.assignee?.id,
        description: currentTask.description,
        dueDate: currentTask.dueDate,
        dueTime: currentTask.dueDate,
        deal: currentTask.deal?.id,
        prospect: currentTask.prospect?.id,
        attachments: currentTask.attachments || [],
      });
    }
  }, [currentTask, reset]);

  const findPreviousTaskId = (currentIndex: number) => {
    if (currentIndex < 1) return null;
    if (!tasks) return null;
    const previousTask = tasks[currentIndex - 1];
    return previousTask.id;
  };

  const findNextTaskId = (currentIndex: number) => {
    if (!tasks) return null;

    if (currentIndex === tasks.length - 1) {
      const pageNumber = Math.floor(tasks.length / 10) + 1;
      dispatch(getTasks({ pageNumber, pageSize: 10 }) as any);
      return null;
    }

    const nextTask = tasks[currentIndex + 1];
    return nextTask.id;
  };

  const goToPreviousTask = () => {
    const previousTaskId = findPreviousTaskId(taskCurrentIndex);
    if (previousTaskId) {
      dispatch(setCurrentTask(previousTaskId));
    }
  };

  const goToNextTask = () => {
    const nextTaskId = findNextTaskId(taskCurrentIndex);
    if (nextTaskId) {
      dispatch(setCurrentTask(nextTaskId));
    }
  };

  useEffect(() => {
    if (prospects === undefined) {
      dispatch(getProspects());
    }
  }, [prospects, dispatch]);

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList());
    }
  }, [dispatch, deals]);

  useEffect(() => {
    if (users === undefined) {
      dispatch(getTeamUsers() as any);
    }
  }, [users, dispatch]);

  const onSubmit = (data: TaskFormProps) => {
    if (!data) return;

    if (data.type !== TaskRequestTypeEnum.Other) delete data.description;
    if (data.type === TaskRequestTypeEnum.Other && !data.description) {
      methods.setError("description", { type: "required", message: "Description is required" });
      return;
    }

    if (!data?.dueDate) {
      methods.setError("dueDate", { type: "required", message: "Due date is required" });
      return;
    }
    if (!data?.dueTime) {
      methods.setError("dueTime", { type: "required", message: "Due time is required" });
      return;
    }

    const date = dayjs(data?.dueDate).format("YYYY-MM-DD");
    const time = dayjs(data?.dueTime).format("HH:mm:ss");
    delete data.dueTime;
    delete data.dueDate;

    const newDate = `${date.split("T")[0]}T${time}`;

    const updateData = {
      ...data,
      dueDate: newDate,
      assigneeId: data.assignee,
      prospectId: data.prospect,
      dealId: data.deal,
    } as any;

    if (!currentTask?.id) {
      return;
    }

    try {
      dispatch(updateTask({ taskId: currentTask?.id, updatedTaskData: updateData }));
    } catch (e) {
      console.error("Error updating task", e);
    }
  };

  const navigateToProspect = () => {
    if (!currentTask?.prospect) return;

    dispatch(setCurrentProspect(currentTask?.prospect));
    dispatch(openViewDrawer());
  };

  const handleDeleteTask = (taskId: string | undefined) => {
    if (!taskId) return;
    dispatch(deleteTask(taskId));
  };

  const mapTaskType = (type: TaskRequestTypeEnum | undefined) => {
    switch (type) {
      case TaskRequestTypeEnum.Call:
        return "Call";
      case TaskRequestTypeEnum.ApproveAiMessage:
        return "Approve message";
      case TaskRequestTypeEnum.AttendMeeting:
        return "Meeting";
      case TaskRequestTypeEnum.Other:
        return "Other";
      default:
        return "Other";
    }
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          clearErrors();
          handleSubmit(onSubmit as any)();
        }}
      >
        <DrawerLayout
          title={`Task - ${mapTaskType(currentTask?.type)}`}
          closeDrawer={toggleDrawer}
          status={currentTask?.status}
          isLoading={loadingCurrentTask}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {!disablePagination ? (
                <>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <ChevronLeftOutline
                      size={24}
                      onClick={goToPreviousTask}
                      style={{
                        cursor: "pointer",
                      }}
                    />

                    <Typography variant="body2">{`${taskCurrentIndex + 1} of ${tasks?.length}`}</Typography>
                    <ChevronRightOutline size={24} onClick={goToNextTask} style={{ cursor: "pointer" }} />
                  </div>

                  <Divider />
                </>
              ) : null}
              {loadingCurrentTask ? (
                <LoadingSpinner loading={true} />
              ) : (
                <>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <FormLabel aria-required label="Task Type" />
                              <Dropdown
                                isDisabled={!editable}
                                label="Task Type"
                                options={TaskTypeSelectOptions}
                                onChange={(value) => {
                                  field.onChange(value.value);
                                }}
                                value={field.value}
                              />
                              <ErrorMessage
                                errors={methods.formState.errors}
                                name="type"
                                as="p"
                                style={errorMessageStyle}
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="assignee"
                        control={control}
                        render={({ field }) => {
                          const options = users?.map((user) => ({
                            label: `${user.firstName!} ${user.lastName!}`,
                            value: user.user_id!,
                          }));

                          return (
                            <>
                              <FormLabel aria-required label="Assignee" />
                              <Dropdown
                                isDisabled={!editable}
                                label="Assignee"
                                options={options || []}
                                {...field}
                                onChange={(value) => {
                                  field.onChange(value.value);
                                }}
                              />
                              <ErrorMessage
                                errors={methods.formState.errors}
                                name="assignee"
                                as="p"
                                style={errorMessageStyle}
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>
                  </Stack>
                  {watch("type") === TaskRequestTypeEnum.Other && (
                    <FormControl sx={{ width: "50%" }}>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <>
                            <FormLabel aria-required label="Please Specify" />
                            <Input disabled={!editable} type="text" placeholder="Description" {...field} />
                            <ErrorMessage
                              errors={methods.formState.errors}
                              name="description"
                              as="p"
                              style={errorMessageStyle}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  )}
                  <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="prospect"
                        control={control}
                        render={({ field }) => {
                          const options = prospects?.map((prospect) => ({
                            label: `${prospect.firstName!} ${prospect.lastName!}`,
                            value: prospect.id!,
                          }));
                          return (
                            <>
                              <FormLabel aria-required label="Contact" />
                              <Dropdown
                                isDisabled={!editable}
                                label="Contact"
                                options={options || []}
                                {...field}
                                onChange={(value) => {
                                  field.onChange(value.value);
                                }}
                              />
                              <ErrorMessage
                                errors={methods.formState.errors}
                                name="contact"
                                as="p"
                                style={errorMessageStyle}
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="deal"
                        control={control}
                        render={({ field }) => {
                          const options = deals?.map((deal: Deal) => ({
                            label: deal.name!,
                            value: deal.id!,
                          }));
                          return (
                            <>
                              <FormLabel aria-required label="Deal" />
                              <Dropdown
                                isDisabled={!editable}
                                label="Deal"
                                options={options || []}
                                {...field}
                                onChange={(value) => {
                                  field.onChange(value.value);
                                }}
                              />
                              <ErrorMessage
                                errors={methods.formState.errors}
                                name="deal"
                                as="p"
                                style={errorMessageStyle}
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="dueDate"
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <FormLabel aria-required label="Due Date" />
                              <DatePicker {...field} value={field.value ? new Date(field.value) : null} />
                              <ErrorMessage
                                errors={methods.formState.errors}
                                name="dueDate"
                                as="p"
                                style={errorMessageStyle}
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>

                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel aria-required label="Due Time" />
                      <Controller
                        name="dueTime"
                        control={control}
                        render={({ field }) => {
                          return <TimePickerInput disabled={!editable} {...field} dueDate={methods.watch("dueDate")} />;
                        }}
                      />
                      <ErrorMessage errors={methods.formState.errors} name="dueTime" as="p" style={errorMessageStyle} />
                    </FormControl>
                  </Stack>
                  <FormControl sx={{ width: "50%" }}>
                    <Controller
                      name="attachments"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel label="Attachments" />
                          <AttachmentInput disabled={!editable} {...field} />
                          <ErrorMessage
                            errors={methods.formState.errors}
                            name="attachments"
                            as="p"
                            style={errorMessageStyle}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                    <FormControl style={{ width: "50%" }}>{/* missing attachment input component */}</FormControl>
                  </Stack>
                </>
              )}
            </Box>
            {!editable ? (
              <DrawerButtonsLayout>
                <button
                  type="button"
                  style={{
                    padding: "12px 24px",
                    background: "transparent",
                    border: "1px solid",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderWidth: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => setEditable(true)}
                >
                  <PencilAltOutline size={18} />
                </button>

                <Button
                  type="button"
                  data-testid="closeTaskDrawer"
                  variant="secondary"
                  fullWidth
                  onClick={() => toggleDrawer()}
                >
                  {t("tasks.cancel")}
                </Button>

                {watch("type") === TaskRequestTypeEnum.ApproveAiMessage ? (
                  <Button type="button" variant="primary" fullWidth onClick={openInboxDrawer}>
                    Review Message
                  </Button>
                ) : (
                  <Button type="button" variant="primary" fullWidth onClick={navigateToProspect}>
                    View Contact
                  </Button>
                )}
              </DrawerButtonsLayout>
            ) : (
              <DrawerButtonsLayout>
                <Button
                  type="button"
                  onClick={() =>
                    deleteConfirm({
                      textDiv: (
                        <>
                          <Typography variant="body1">Are you sure you want to delete this task?</Typography>
                          <Typography variant="body1">This action cannot be undone.</Typography>
                        </>
                      ),
                    })
                      .then(() => {
                        handleDeleteTask(currentTask?.id);
                      })
                      .catch(() => {
                        console.log("cancelled");
                      })
                  }
                  variant="secondary"
                  sx={{
                    color: "red",
                    borderColor: "red",

                    "&:focus": {
                      color: "red",
                      borderColor: "red",
                    },
                    "&:hover": {
                      color: "red",
                      borderColor: "red",
                      backgroundColor: "rgba(255, 0, 0, 0.1)",
                    },
                  }}
                >
                  <TrashOutline size={18} />
                </Button>

                <Button
                  type="button"
                  data-testid="closeTaskDrawer"
                  variant="secondary"
                  fullWidth
                  onClick={() => setEditable(!editable)}
                >
                  {t("tasks.cancel")}
                </Button>

                <Button type="submit" variant="primary" fullWidth>
                  Save
                </Button>
              </DrawerButtonsLayout>
            )}
          </>
        </DrawerLayout>
      </form>
      <ViewProspectDrawer />

      <ViewInboxMessageDrawer />
    </>
  );
};
