import { Quill } from "react-quill-new";

const Embed: any = Quill.import("blots/embed");

export interface VariableEmbedDelta {
  variableEmbed: VariableData;
}

interface VariableData {
  value: string;
  valid: boolean;
}

export class VariableEmbed extends Embed {
  static create(data: VariableData) {
    const node = super.create(data.value);
    node.innerHTML += data.value;
    node.classList.add(data.valid ? "valid" : "invalid");
    node.setAttribute("contenteditable", false);
    node.setAttribute("value", data.value);
    node.setAttribute("valid", data.valid);
    return node;
  }

  static value(node: any) {
    return {
      value: node.getAttribute("value"),
      valid: node.getAttribute("valid") === "true",
    };
  }
}

VariableEmbed.blotName = "variableEmbed";
VariableEmbed.tagName = "span";
VariableEmbed.className = "variable";
