import SeqEnrollDialog from "@/common/components/enroll-in-sequence/SeqEnrollDialog";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { usePagination } from "@/common/components/pagination/usePagination";
import TsTable from "@/common/components/ts-table";
import toast from "@/lib/toast";
import {
  getImportData,
  hideSeqEnrollDialog,
  openViewDrawer,
  setCurrentLeadSource,
  triggerSeqEnrollLeadSourceId,
} from "@/redux/reducers/dataImport/slices/list";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { CheckCircleIcon, DocumentArrowDownIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect } from "react";
import { DataImportFiltersProps } from "../filters";
import { DataImportColumns } from "./columns";

export default function DataImportTable({ filters, setFilters }: DataImportFiltersProps) {
  const dispatch = useAppDispatch();
  const dataImport = useAppSelector(({ dataImport }) => dataImport.list);
  const seqEnrollShowDialog = useAppSelector(({ dataImport }) => dataImport.list.isShowSeqEnrollDialog);
  const { pagination, onPaginationChange } = usePagination();
  const currentLeadSource = useAppSelector(({ dataImport }) => dataImport.list.currentLeadSource);
  const seqEnrollLeadSourceIdList = useAppSelector(({ dataImport }) => dataImport.list.seqEnrollLeadSourceIdList);
  const seqEnrollPending = useAppSelector(({ prospects }) => prospects.listProspects.seqEnrollPending);

  useEffect(() => {
    dispatch(getImportData({ ...filters, pageIndex: pagination.pageIndex, pageSize: pagination.pageSize }));
  }, [dispatch, filters, pagination.pageIndex, pagination.pageSize]);

  const table = useReactTable({
    data: dataImport.data || [],
    columns: DataImportColumns({ filters, setFilters }) as any,
    pageCount: dataImport?.data?.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
  });

  const handleSeqEnrollClose = () => {
    dispatch(hideSeqEnrollDialog());
  };

  /**
   * Callback for Dialog submit.
   * @param sequenceId
   */
  const handleSeqEnroll = (sequencesIds: Array<string>) => {
    const leadSourceId = seqEnrollLeadSourceIdList.length
      ? seqEnrollLeadSourceIdList[0]
      : (currentLeadSource as any).id
      ? (currentLeadSource as any).id
      : undefined;

    for (const sequenceId of sequencesIds) {
      if (!leadSourceId.length || !sequenceId) {
        return;
      }

      dispatch(
        triggerSeqEnrollLeadSourceId({
          sequenceTemplateId: sequenceId,
          leadSourceId,
        }),
      )
        .unwrap()
        .then((response) => {
          if (response.successfulCount) {
            if (response.skippedDNCCount) {
              toast.success(
                <>
                  <span>
                    Successfully enrolled {response.successfulCount}{" "}
                    {response.successfulCount > 1 ? "contacts" : "contact"}, with {response.skippedDNCCount}{" "}
                    {response.skippedDNCCount > 1 ? "contacts" : "contact"} skipped due to <b>DNC (Do Not Call)</b>{" "}
                    status.
                  </span>
                </>,
                {
                  icon: <CheckCircleIcon />,
                },
              );
            } else {
              toast.success(
                <>
                  <span>
                    Successfully enrolled {response.successfulCount}{" "}
                    {response.successfulCount > 1 ? "contacts" : "contact"} to a sequence.
                  </span>
                </>,
                {
                  icon: <CheckCircleIcon />,
                },
              );
            }
          } else if (response.skippedDNCCount) {
            toast.warning(
              <>
                <span>
                  Contacts with <b>DNC (Do Not Call)</b> status can't be enrolled into a sequence.
                </span>
              </>,
              {
                icon: <ExclamationTriangleIcon />,
              },
            );
          }

          if (response.skippedOtherCount) {
            toast.error(`Failed to enroll ${response.skippedOtherCount} contacts into the sequence.`);
          }

          dispatch(hideSeqEnrollDialog());
        })
        .catch((error) => {
          if (error?.response?.data && error.response.data.startsWith("412 Lead source")) {
            toast.error("Lead Source Trigger already already exists for Lead Source.");
          } else {
            toast.error("Something went wrong!");
          }
        });
    }
  };

  const cutomPagination = {
    ...dataImport.pagination,
    onNext: () => onPaginationChange((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 })),
    onPrev: () => onPaginationChange((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 })),
    onPageSizeChange: (value: number) => onPaginationChange((prev) => ({ ...prev, pageSize: value })),
  };

  return (
    <>
      <FullLoadingOverlay loading={seqEnrollPending || dataImport.loading} overlay={true} />
      <TsTable
        table={table as any}
        notFoundProps={{
          Icon: DocumentArrowDownIcon,
          title: "No imports found",
          description: (
            <span>
              Begin importing contacts, companies, or products <br /> by clicking the <b>"New Import"</b> button above.
            </span>
          ),
        }}
        handleCellClick={(_, data) => {
          dispatch(openViewDrawer({}));
          dispatch(setCurrentLeadSource(data));
        }}
        customPaginationProps={cutomPagination}
      />
      {seqEnrollShowDialog && <SeqEnrollDialog handleClose={handleSeqEnrollClose} handleSubmit={handleSeqEnroll} />}
    </>
  );
}
