import toast from "@/lib/toast";
import Services from "@/services";
import { KnCreateCompanyImportJobRequest, KnProcessCompanyImportJobRequest } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
};

const initialState: State = {
  loading: false,
};

export const createCompanyJob = createAsyncThunk(
  "company/import/createCompanyJob",
  async (body: KnCreateCompanyImportJobRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportCompany.createCompanyImportJob(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const uploadCompanyCsv = createAsyncThunk(
  "company/import/uploadCompanyCsv",
  async (file: File, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportCompany.uploadCompanyCsv(file);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const processCompanyJob = createAsyncThunk(
  "company/import/processCompanyJob",
  async (body: Partial<KnProcessCompanyImportJobRequest>, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportCompany.processCompanyImportJob(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteCompanyJob = createAsyncThunk(
  "company/import/deleteCompanyJob",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportCompany.deleteCompanyImportJob(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const companyImportSlice = createSlice({
  name: "company/import",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCompanyJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCompanyJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createCompanyJob.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to create company job");
      })
      .addCase(deleteCompanyJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCompanyJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCompanyJob.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to cancel company job");
      })
      .addCase(uploadCompanyCsv.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadCompanyCsv.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadCompanyCsv.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to upload CSV file");
      })

      .addCase(processCompanyJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(processCompanyJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(processCompanyJob.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to process company job");
      });
  },
});

export default companyImportSlice.reducer;
