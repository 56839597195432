import { ReactComponent as NoCallLogsFound } from "@/common/assets/svg/ai-voice/no-call-logs-found.svg";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import PageHeader from "@/common/components/page-header/PageHeader";
import { usePagination } from "@/common/components/pagination/usePagination";
import TsTable from "@/common/components/ts-table";
import routes from "@/common/constants/routes";
import { TransferPhoneNumberColumns } from "./columns";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, FormControl, Typography } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TransferNumberFilters from "./filters";
import PhoneInput from "@/common/components/phone-input";
import Input from "@/common/components/input";
import Button from "@/common/components/button";
import FormLabel from "@/common/components/form-label/FormLabel";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { lookupPhoneNumbers, upsertTransferNumber } from "@/redux/reducers/ai-voice/listTransferPhoneNumber";
import { TransferNumberRequest } from "@/services/generated";
import TransferNumberViewDrawer from "./view";
import toast from "@/lib/toast";

export type TransferLibraryFiltersInput = {
  search?: string;
  page?: number;
  pageSize?: number;
};

const schema = Yup.object({
  number: Yup.string().required(),
  name: Yup.string().required(),
});

export default function AiAgentTransferNumberLibraryPage() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: "",
      name: "",
    },
  });
  const { control, reset, formState, handleSubmit } = methods;
  const [filters, setFilters] = useState<TransferLibraryFiltersInput>({});
  const { onPaginationChange, pagination } = usePagination();
  const transferPhoneNumbers = useAppSelector((selector) => selector.aiVoice.listTransferPhoneNumber.data);
  const totalCount = useAppSelector((selector) => selector.aiVoice.listTransferPhoneNumber.totalCount);
  const loading = useAppSelector((selector) => selector.aiVoice.listTransferPhoneNumber.loading);

  const currentPhoneNumber = useMemo(
    () => transferPhoneNumbers?.find((phoneNumber) => phoneNumber.id! === params.id),
    [params.id, transferPhoneNumbers],
  );

  const table = useReactTable({
    data: transferPhoneNumbers?.map((phoneNumber) => ({ ...phoneNumber, id: phoneNumber.id! })) || [],
    pageCount: totalCount,
    columns: TransferPhoneNumberColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  useEffect(() => {
    dispatch(lookupPhoneNumbers({ ...filters, page: pagination.pageIndex, pageSize: pagination.pageSize }));
  }, [dispatch, pagination, filters]);

  const showPhoneNumber = (phoneId: string) => {
    // navigate(routes.aiVoice.transferNumberLibrary.id.with({ id: phoneId }));
  };

  const onSubmit: SubmitHandler<TransferNumberRequest> = (data: TransferNumberRequest) => {
    if (!data) {
      return;
    }
    dispatch(upsertTransferNumber(data))
      .unwrap()
      .then(() => {
        toast.success("Transfer number added successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <PageHeader title="Transfer Number Library" />
        <Typography sx={{ fontWeight: 700 }}>Add New Transfer Number</Typography>
        <FormProvider {...methods}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "end" }}>
            <Controller
              name="number"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ maxWidth: 300 }}>
                  <FormLabel label="Phone Number" aria-required infoIcon />
                  <PhoneInput
                    error={fieldState.error?.message}
                    value={field.value}
                    onChange={(phone) => field.onChange(phone)}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ maxWidth: 300 }}>
                  <FormLabel label="Name" aria-required infoIcon />
                  <Input
                    error={fieldState.error?.message}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </FormControl>
              )}
            />
            <Box sx={{ flex: "none" }}>
              <Button onClick={handleSubmit(onSubmit)} size="sm">
                Add Number
              </Button>
            </Box>
          </Box>
        </FormProvider>
        <TransferNumberFilters filters={filters} setFilters={setFilters} loading={loading} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <TsTable
            table={table}
            handleCellClick={showPhoneNumber}
            notFoundProps={{
              title: "No transfer phone number yet!",
              description: (
                <span>
                  Your transfer phone number will be displayed here once <br /> you begin making calls in the app.
                </span>
              ),
              Icon: NoCallLogsFound,
            }}
          />
        )}
      </Box>
      <TransferNumberViewDrawer currentNumber={currentPhoneNumber} open={!!params.id} />
    </>
  );
}
