import Button from "@/common/components/button";
import { Box, Typography } from "@mui/material";

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
  const year = date.getFullYear();
  const suffix = getOrdinalSuffix(day);
  return `${month} ${day}${suffix}, ${year}`;
};

export const ItemButtonCard = ({
  name,
  dateTime,
  buttonText,
  datePreText,
  nextPaymentAmount,
  nextPaymentDateTime,
  buttonAction,
}: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "74px",
        padding: "16px",
        borderRadius: "8px",
        border: "1px #E7EBED solid",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "10px",
        display: "inline-flex",
      }}
    >
      <Box sx={{ alignSelf: "stretch", justifyContent: "space-between", alignItems: "center", display: "inline-flex" }}>
        <Box sx={{ justifyContent: "flex-start", alignItems: "center", gap: "12px", display: "flex" }}>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "2px",
              display: "inline-flex",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: "700",
                lineHeight: "20px",
              }}
            >
              {name}
            </Typography>
            <Typography variant="body2">
              {datePreText} on {formatDate(new Date(dateTime))}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ justifyContent: "flex-start", alignItems: "center", gap: "12px", display: "flex" }}>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "2px",
              display: "inline-flex",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: "700",
                lineHeight: "20px",
              }}
            >
              Next Payment
            </Typography>
            <Typography variant="body2">
              ${nextPaymentAmount} on {formatDate(new Date(nextPaymentDateTime))}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="secondary"
          onClick={buttonAction}
          sx={(theme: any) => ({
            padding: "5px 40px",
            border: `2px ${theme.palette.success[700]} solid`,
          })}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default ItemButtonCard;
