import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import PhoneInput from "@/common/components/phone-input";
import { phoneValidationErrorMessage, phoneValidationRegex } from "@/common/constants/phone-validation";
import { setCanUpdateUserInfo, setDraft, setPrevImg } from "@/redux/reducers/settings/user";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

const schema = Yup.object({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
  phone: Yup.string().matches(phoneValidationRegex, phoneValidationErrorMessage).nullable(),
  imgUrl: Yup.string().nullable(),
  media: Yup.mixed().nullable(),
  prevImg: Yup.mixed().nullable(),
}).required();

type UserFormType = Yup.InferType<typeof schema>;

export default function BasicInformation() {
  const user = useAppSelector(({ settings }) => settings.user.user);
  const dispatch = useAppDispatch();

  const { control, reset, formState, watch, setValue, trigger } = useForm<UserFormType>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: user || {},
  });

  const data = watch();

  useEffect(() => {
    reset(user || {});
    trigger();
  }, [user, reset]);

  useEffect(() => {
    dispatch(setCanUpdateUserInfo(formState.isValid));
    dispatch(setDraft({ ...data, prevImg: data.media }));
    dispatch(setPrevImg(data.media));
  }, [data, dispatch, formState.isDirty, formState.isValid]);

  const handleFileSelect = (file: File) => {
    setValue("media", file, { shouldDirty: true });
  };

  return (
    <Box
      sx={{
        display: "grid",
        gap: "32px",
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="First Name" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Last Name" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Email" aria-required />
            <Input {...field} error={fieldState.error?.message} disabled />
          </FormControl>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Phone" />
            <PhoneInput {...field} error={fieldState?.error?.message} />
          </FormControl>
        )}
      />
    </Box>
  );
}
