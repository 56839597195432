import Button from "@/common/components/button";
import LinearProgressBar from "@/common/components/LinearProgressBar";
import { TitleAndSubtitleBox } from "@/pages/settings/items";
import { createCustomerPortalUrl } from "@/redux/reducers/settings/billing";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
  const year = date.getFullYear();
  const suffix = getOrdinalSuffix(day);
  return `${month} ${day}${suffix}, ${year}`;
};

type BillingInfoProps = {
  showNextPayment?: boolean;
};

export default function BillingInfo({ showNextPayment }: BillingInfoProps) {
  const theme = useTheme();
  const [progressValueSms, setProgressValueSms] = useState(0);
  const [progressValueMinutes, setProgressValueMinutes] = useState(0);
  const billingInfo = useAppSelector(({ settings }) => settings.billing.info);
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  useEffect(() => {
    if (billingInfo) {
      setProgressValueSms(
        (((billingInfo.textsTotal || 0) - (billingInfo.textsRemaining || 0)) / (billingInfo.textsTotal || 1)) * 100,
      );
      setProgressValueMinutes(
        (((billingInfo.minutesTotal || 0) - (billingInfo.minutesRemaining || 0)) / (billingInfo.minutesTotal || 1)) *
          100,
      );
    }
  }, [billingInfo]);

  const handleManageBilling = () => {
    if (user) {
      dispatch(createCustomerPortalUrl())
        .unwrap()
        .then(({ url }) => {
          if (url) {
            window.location.replace(url);
          }
        });
    }
  };

  return (
    <>
      {billingInfo && (
        <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", width: "100%" }}>
          <TitleAndSubtitleBox
            title="Active Plan"
            subtitle="Modify your active plan and manage your billing information"
          />
          <Box
            sx={{
              width: "100%",
              padding: "16px 24px",
              borderRadius: "8px",
              border: "1px #E7EBED solid",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                alignSelf: "stretch",
                justifyContent: "space-between",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <Box sx={{ justifyContent: "flex-start", alignItems: "center", gap: "12px", display: "flex" }}>
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "2px",
                    display: "inline-flex",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                  >
                    {billingInfo.tierName}
                  </Typography>
                  <Typography variant="body2">
                    Activated on {formatDate(new Date(billingInfo.accountActivationDate || ""))}
                  </Typography>
                </Box>
              </Box>
              {showNextPayment && (
                <Box sx={{ justifyContent: "flex-start", alignItems: "center", gap: "12px", display: "flex" }}>
                  <Box
                    sx={{
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: "2px",
                      display: "inline-flex",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "700",
                        lineHeight: "20px",
                      }}
                    >
                      Next Payment
                    </Typography>
                    <Typography variant="body2">
                      {billingInfo.nextPaymentValue} on {formatDate(new Date(billingInfo.nextPaymentDate || ""))}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Button
                variant="secondary"
                onClick={handleManageBilling}
                sx={{
                  padding: "5px 40px",
                  border: `2px ${theme.palette.success.main} solid`,
                }}
              >
                Manage Billing
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Divider />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "48px",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", marginBottom: "8px", width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {billingInfo && billingInfo.textsTotal && billingInfo.textsRemaining && (
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      SMS - {(billingInfo?.textsTotal - billingInfo.textsRemaining).toLocaleString()} out of{" "}
                      {billingInfo.textsTotal.toLocaleString()}
                    </Typography>
                  )}
                </Box>
                <LinearProgressBar value={progressValueSms} />
              </Box>

              <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", marginBottom: "8px", width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {billingInfo && billingInfo.minutesTotal && billingInfo.minutesRemaining && (
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      Minutes - {(billingInfo.minutesTotal - billingInfo.minutesRemaining).toLocaleString()} out of{" "}
                      {billingInfo.minutesTotal.toLocaleString()}
                    </Typography>
                  )}
                </Box>
                <LinearProgressBar value={progressValueMinutes} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
