import {
  KnContactImportJobLeadSourceEnum,
  KnDocumentListResponseKnFirestoreImportJobResults,
  KnFirestoreImportJobImportJobTypeEnum,
} from "@/services/generated";
import * as Yup from "yup";

export type ColumnMappingStrategyItem = {
  object: KnFirestoreImportJobImportJobTypeEnum;
  sample: string;
  originalFileColumnName: string;
  mappedFirestoreFieldName?: string;
  overwriteExistingValueIfPresent?: boolean;
};

export type DataImportSchema = {
  importType?: KnFirestoreImportJobImportJobTypeEnum;
  // TODO: Replace it with real KnRequestSchema
  file?: any;
  id?: string;
  importJobName?: string;
  leadSource?: KnContactImportJobLeadSourceEnum;
  columnMappingStrategyList?: ColumnMappingStrategyItem[];
};

export const dataImportSchema: Yup.ObjectSchema<DataImportSchema> = Yup.object({
  importType: Yup.string<KnFirestoreImportJobImportJobTypeEnum>()
    .oneOf(Object.values(KnFirestoreImportJobImportJobTypeEnum))
    .when("$step", {
      is: (step: number) => step >= 0,
      then: (schema) => schema.required(),
    }),

  id: Yup.string().when("$step", {
    is: (step: number) => step >= 2,
    then: (schema) => schema.required(),
  }),
  importJobName: Yup.string().when("$step", {
    is: (step: number) => step >= 1,
    then: (schema) => schema.required(),
  }),
  leadSource: Yup.mixed<KnContactImportJobLeadSourceEnum>().when(["$step", "importType"], {
    is: (step: number, importType: KnFirestoreImportJobImportJobTypeEnum) =>
      step >= 1 && importType === KnFirestoreImportJobImportJobTypeEnum.Contact,
    then: (schema) => schema.required(),
  }),
  file: Yup.mixed().when("$step", {
    is: (step: number) => step >= 1,
    then: (schema) => schema.required(),
  }),
  columnMappingStrategyList: Yup.array<KnDocumentListResponseKnFirestoreImportJobResults>(
    Yup.object({
      object: Yup.mixed<KnFirestoreImportJobImportJobTypeEnum>().required(),
      sample: Yup.string().required(),
      mappedFirestoreFieldName: Yup.string(),
      originalFileColumnName: Yup.string().required(),
      overwriteExistingValueIfPresent: Yup.boolean(),
    }),
  ).when("$step", {
    is: (step: number) => step >= 2,
    then: (schema) => schema.required(),
  }),
}).required();

export type DataImportFormFields = Yup.InferType<typeof dataImportSchema>;
