import { createSlice } from "@reduxjs/toolkit";
import { AiPersonaResponsivenessEnum } from "@/services/generated";
import { persistAiPersona } from "@/redux/reducers/ai-learning/ai-persona/persist";
import { getAiPersona } from "@/redux/reducers/ai-learning/ai-persona/get";

type State = {
  id: string | null;
  aiAgentName: string | null;
  engagementAndResponsiveness: AiPersonaResponsivenessEnum | null;
  laidBackOrAssertive: number;
  friendlyOrProfessional: number;
  shortOrVerbose: number;
  humorousOrSerious: number;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  id: null,
  aiAgentName: null,
  engagementAndResponsiveness: null,
  laidBackOrAssertive: 0,
  friendlyOrProfessional: 0,
  shortOrVerbose: 0,
  humorousOrSerious: 0,
  loading: true,
  error: null,
};

const aiPersonaSlice = createSlice({
  name: "aiPersona",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAiPersona.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.id = payload.data.id || "";
          state.aiAgentName = payload.data.agent_name || "";
          state.engagementAndResponsiveness = payload.data.responsiveness || AiPersonaResponsivenessEnum.Immediate;
          state.laidBackOrAssertive = payload.data.laid_back_or_assertive || 0;
          state.friendlyOrProfessional = payload.data.friendly_or_professional || 0;
          state.shortOrVerbose = payload.data.short_or_verbose || 0;
          state.humorousOrSerious = payload.data.humorous_or_serious || 0;
        }
        state.loading = false;
      })
      .addCase(persistAiPersona.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.id = payload.data.id || "";
          state.aiAgentName = payload.data.agent_name || "";
          state.engagementAndResponsiveness = payload.data.responsiveness || AiPersonaResponsivenessEnum.Immediate;
          state.laidBackOrAssertive = payload.data.laid_back_or_assertive || 0;
          state.friendlyOrProfessional = payload.data.friendly_or_professional || 0;
          state.shortOrVerbose = payload.data.short_or_verbose || 0;
          state.humorousOrSerious = payload.data.humorous_or_serious || 0;
        }
      });
  },
  reducers: {},
});

export default aiPersonaSlice.reducer;
