import { Box, Typography } from "@mui/material";
import Button from "../button";
import { SequenceResponseStatusEnum } from "@/services/generated";

interface SequenceWarningBoxProps {
  sequenceName?: string;
  sequenceStatus?: SequenceResponseStatusEnum;
  handleActionButton: () => void;
  handleCancelButton: () => void;
}

const SequenceWarningBox = ({
  sequenceName,
  sequenceStatus,
  handleActionButton,
  handleCancelButton,
}: SequenceWarningBoxProps) => {
  const getActionButtonText = () => {
    switch (sequenceStatus) {
      case SequenceResponseStatusEnum.PausedInTeamLevel:
        return "Go To Company Settings";
      case SequenceResponseStatusEnum.PausedInTemplateLevel:
        return "Go To Sequence Builder";
      default:
        return "Go To Sequence Builder";
    }
  };

  const getLevelDescriptionText = () => {
    switch (sequenceStatus) {
      case SequenceResponseStatusEnum.PausedInTeamLevel:
        return "cannot be resumed. You need to connect a Twilio account in order to resume sequences for your team.";
      case SequenceResponseStatusEnum.PausedInTemplateLevel:
        return "is currently being paused at the Sequence Template Level. You can resume it in Sequence Builder.";
      default:
        return "is currently being paused at the Sequence Template Level. You can resume it in Sequence Builder.";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 2,
        borderRadius: "8px",
        border: "1px solid #e7ebed",
        background: "#f8f9fa",
      }}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: 1.56,
          color: "#1a2135",
          letterSpacing: "0.2px",
        }}
      >
        Unable To Resume Sequence
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          color: "#1a2135",
        }}
      >
        The sequence <b>{sequenceName}</b> {getLevelDescriptionText()}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: 2 }}>
        <Button size="sm" variant="secondary" onClick={handleCancelButton}>
          Cancel
        </Button>
        <Button size="sm" onClick={handleActionButton}>
          {getActionButtonText()}
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceWarningBox;
