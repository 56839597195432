import DragAndDrop from "@/common/components/drag-and-drop/DragAndDrop";
import { ReactComponent as FileIcon } from "@/common/assets/svg/data-import/file.svg";
import Input from "@/common/components/input/Input";
import { FormControl, useTheme } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import FormLabel from "@/common/components/form-label/FormLabel";
import { uploadCompanyCsv } from "@/redux/reducers/dataImport/company/slices/import";
import { uploadProductCsv } from "@/redux/reducers/dataImport/product/slices/import";
import { uploadProspectCsv } from "@/redux/reducers/dataImport/prospect/slices/import";
import { useAppDispatch } from "@/redux/store";
import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import Box from "@mui/material/Box";
import { DataImportFormFields, DataImportSchema } from "../types";
import { DataImportExampleContactsCSVFile, DataImportExampleProductCSVFile } from "@/common/constants/data-import";

export default function UploadStep() {
  const methods = useFormContext<DataImportSchema>();

  const theme = useTheme();
  const { importType } = useWatch<DataImportFormFields>();
  const dispatch = useAppDispatch();

  const exampleCSVFileLink =
    importType === KnFirestoreImportJobImportJobTypeEnum.Contact
      ? DataImportExampleContactsCSVFile
      : DataImportExampleProductCSVFile;

  const handleUploadFile = async (file: File) => {
    let response = null;
    switch (importType) {
      case KnFirestoreImportJobImportJobTypeEnum.Company:
        response = await dispatch(uploadCompanyCsv(file)).unwrap();
        break;
      case KnFirestoreImportJobImportJobTypeEnum.Contact:
        response = await dispatch(uploadProspectCsv(file)).unwrap();
        break;
      case KnFirestoreImportJobImportJobTypeEnum.Product:
        response = await dispatch(uploadProductCsv(file)).unwrap();
        break;
      default:
    }

    if (response?.id) {
      methods.setValue("id", response.id);
      methods.setValue("file", file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 700,
        margin: "0 auto",
      }}
    >
      <Controller
        name="importJobName"
        control={methods.control}
        render={({ field, fieldState }) => (
          <FormControl sx={{ width: "100%" }}>
            <FormLabel aria-required label="Import Name" />
            <Input
              {...field}
              type="text"
              placeholder="Add file name here"
              onChange={(e) => field.onChange(e.target.value)}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />

      {importType === KnFirestoreImportJobImportJobTypeEnum.Contact && (
        <Controller
          name="leadSource"
          control={methods.control}
          render={({ field, fieldState }) => (
            <FormControl sx={{ width: "100%" }}>
              <FormLabel aria-required label="Lead Source" />
              <Input
                {...field}
                placeholder="Form Submission"
                onChange={(e) => field.onChange(e.target.value)}
                error={fieldState?.error?.message}
              />
            </FormControl>
          )}
        />
      )}

      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
        <DragAndDrop accept={{ "text/csv": [".csv"] }} file={methods.watch("file")} onSetFile={handleUploadFile} />
        {exampleCSVFileLink.length > 0 ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FileIcon />
            <a
              href={exampleCSVFileLink}
              download
              style={{
                color: theme.palette.commonColors.informative,
                fontSize: "14px",
              }}
            >
              Download Example CSV File
            </a>
          </Box>
        ) : undefined}
      </Box>
    </Box>
  );
}
