import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";

export const getIsConnectedTwilio = createAsyncThunk("aiVoice/getIsConnectedTwilio", async () => {
  try {
    const response = await Services.AiVoice.isConnectedTwilio();
    return response;
  } catch (error: any) {
    return error;
  }
});
