import ServerPagination, { ServerPaginationProps } from "@/common/components/pagination/ServerPagination";
import routes from "@/common/constants/routes";
import { Box, SxProps, Theme, useTheme } from "@mui/material";
import { Table, flexRender } from "@tanstack/react-table";
import { useLocation } from "react-router-dom";
import EmptyState from "../empty-state/EmptyState";

import { TableColumnEnum } from "@/common/constants/table-column";
import React from "react";

interface HasId {
  id: string | number;
}

type customPaginationProps = ServerPaginationProps<HasId>["custom"];

interface TableProps<T extends HasId> {
  table: Table<T>;
  handleCellClick?: (id: string | number, row?: T) => void;
  notFoundProps?: {
    Icon: React.ElementType;
    title: string;
    description?: string | React.ReactNode;
  };
  bottomDisplay?: (id: string | number) => React.ReactNode;
  showPagination?: boolean;
  customPaginationProps?: customPaginationProps;
  outerSx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  headStyles?: React.CSSProperties;
}

export default function TsTable<T extends HasId>({
  showPagination = true,
  outerSx,
  innerSx,
  headStyles,
  ...props
}: TableProps<T>) {
  const location = useLocation();
  const { table, handleCellClick, notFoundProps, bottomDisplay } = props;

  const theme = useTheme();
  const rows = table.getRowModel().rows;

  if (rows.length === 0 && notFoundProps) {
    return notFoundProps ? <EmptyState {...notFoundProps} /> : null;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...outerSx }}>
      <Box sx={{ ...innerSx }}>
        <table
          style={{
            width: "100%",
            border: `1px solid ${theme.palette.commonColors.light}`,
            borderRadius: "8px",
          }}
        >
          <thead
            style={{
              backgroundColor: "#f5f5f5",
              color: "#000",
              fontWeight: "bold",
              ...headStyles,
            }}
          >
            {table.getHeaderGroups().map((headerGroup, headerIndex) => (
              <tr key={headerGroup.id + headerIndex}>
                {headerGroup.headers.map((header, index) => {
                  return (
                    <th
                      key={header.id + index}
                      style={{
                        padding: "12px 16px",
                        background: "#F0F0F0",
                        textTransform: "uppercase",
                        fontWeight: 400,
                        fontSize: "14px",
                        borderTopLeftRadius: index === 0 ? "8px" : "0",
                        borderTopRightRadius: index === headerGroup.headers.length - 1 ? "8px" : "0",
                      }}
                      align="left"
                    >
                      {header.id === TableColumnEnum.Checkbox
                        ? null
                        : header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <>
                <tr key={row.id + rowIndex}>
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <td
                      onClick={() => {
                        if (location.pathname === routes.triggers.path && cell.column.id === TableColumnEnum.Status)
                          return;
                        if (cell.column.id === TableColumnEnum.Actions) return;
                        if (cell.column.id === TableColumnEnum.Checkbox) return;
                        if (!handleCellClick) return;
                        handleCellClick(row.original.id, row.original);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: "12px 16px",
                        fontWeight: 400,
                        fontSize: "14px",
                        background: row.index % 2 === 0 ? "#fff" : "#FBFBFB",
                        borderBottomLeftRadius: cellIndex === 0 ? "8px" : "0",
                        borderBottomRightRadius: cellIndex === row.getVisibleCells().length - 1 ? "8px" : "0",
                        height: "64px",
                      }}
                      key={cell.id + cellIndex}
                      align="left"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>

                {bottomDisplay ? (
                  <tr
                    style={{
                      background: row.index % 2 === 0 ? "#fff" : "#FBFBFB",
                    }}
                  >
                    {bottomDisplay(row.original.id)}
                  </tr>
                ) : undefined}
              </>
            ))}
          </tbody>
        </table>
      </Box>

      {showPagination && <ServerPagination tableLib={table} custom={props.customPaginationProps} />}
    </Box>
  );
}
