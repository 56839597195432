import SalesAiFull from "@/common/assets/svg/salesaifull.svg";
import Button from "@/common/components/button";
import Stepper from "@/common/components/stepper/Stepper";
import routes from "@/common/constants/routes";
import { getTeam, getTeamUsers } from "@/redux/reducers/settings/team/get";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/16/solid";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { ArrowRightOutline } from "heroicons-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import BillingSettings from "./steps/billing";
import CompanySettings from "./steps/company";
import TermsOfServicePage from "./steps/terms-of-service";
import { getBillingInfo } from "@/redux/reducers/settings/billing";
import { createTeam } from "@/redux/reducers/settings/team";
import { Team } from "@/types/admin";

const ONBOARDING_STEPS = [
  { idx: 0, label: "Terms of Service", route: routes.onboard.termsOfService.path, hideNavigationButtons: true },
  { idx: 1, label: "Company Settings", route: routes.onboard.team.path },
  { idx: 2, label: "Billing", route: routes.onboard.billing.path },
];

export default function Onboarding() {
  const { logout } = useAuth0();
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStepIdx, setActiveStepIdx] = useState<number>(0);
  const location = useLocation();
  const activeStep = ONBOARDING_STEPS[activeStepIdx];
  const nextStep = ONBOARDING_STEPS[activeStepIdx + 1];
  const dispatch = useAppDispatch();
  const team = useAppSelector(({ settings }) => settings.team.team) as Team | null;

  const draftTeam = useAppSelector(({ settings }) => settings.team.draftTeam);

  const nextButtonLabel = useMemo(() => {
    if (activeStepIdx === ONBOARDING_STEPS.length - 1) return "Get Started";
    if (activeStepIdx === 1 && draftTeam && !team) return "Create Company";
    return "Next";
  }, [activeStepIdx, team, draftTeam]);

  useEffect(() => {
    const routeIndex = ONBOARDING_STEPS.findIndex((step) => step.route === location.pathname);

    if (!team && routes.onboard.billing.path === location.pathname) {
      setActiveStepIdx(1);
    } else {
      setActiveStepIdx(routeIndex >= 0 ? routeIndex : 0);
    }
  }, [location, team, activeStepIdx]);

  const goToNextStep = async () => {
    if (activeStepIdx === 1 && draftTeam && !team) {
      dispatch(createTeam(draftTeam));
      return;
    }

    navigate(nextStep?.route || routes.root.path);
  };

  useEffect(() => {
    dispatch(getTeam());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeamUsers());
  }, [dispatch]);

  useEffect(() => {
    if (team?.id) {
      dispatch(getBillingInfo(team.id));
    }
  }, [team, dispatch]);

  const handleLogout = () => {
    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.bg.default,
          height: "100vh",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          padding: "70px 80px",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "36px",
              }}
            >
              Welcome
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Complete the following steps to start using SalesAi
            </Typography>
          </Box>
          <Box>
            <img src={SalesAiFull} alt="SalesAI Logo" width="54.047px" height="54.232px" />
          </Box>
        </Box>
        <Box
          sx={{
            background: "white",
            borderRadius: "8px",
            width: "100%",
            display: "flex",
            padding: "42px 100px 32px 100px",
            flexDirection: "column",
            alignItems: "center",
            gap: "32px",
            alignSelf: "stretch",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <Stepper steps={ONBOARDING_STEPS} activeStep={activeStepIdx} />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "32px",
            }}
          >
            {activeStepIdx === 0 && <TermsOfServicePage onAccept={goToNextStep} />}
            {activeStepIdx === 1 && <CompanySettings />}
            {activeStepIdx === 2 && <BillingSettings />}

            {!activeStep?.hideNavigationButtons && (
              <Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                    marginTop: "20px",
                    width: "100%",
                    alignItems: "end",
                  }}
                >
                  <Button
                    leftIcon={<ArrowRightOutline size={16} />}
                    variant="primary"
                    type="button"
                    disabled={!team?.id && (draftTeam?.name === "" || draftTeam === null)}
                    onClick={goToNextStep}
                  >
                    {nextButtonLabel}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            background: "white",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <Button size="sm" variant="secondary" onClick={handleLogout} rightIcon={<ArrowRightStartOnRectangleIcon />}>
            Log Out
          </Button>
        </Box>
      </Box>
    </>
  );
}
