import Button from "@/common/components/button/Button";
import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Input from "@/common/components/input/Input";
import FormLabel from "@/common/components/form-label/FormLabel";
import { SmsFormType } from "@/pages/inbox/tabs/SMS/SmsTab";
import { KnTeamUserRole } from "@/services/generated";
import { useMemo } from "react";
import { useAppSelector } from "@/redux/store";
import { Loading } from "@kennected/components";
import { OptionType } from "@/common/components/autocomplete/GenericAutocomplete";

const VALUE_MEETING = "suggest_meeting_times";

const VALUE_CUSTOM = "custom";

const genAiType = [
  {
    label: "Suggest Meeting Times",
    value: VALUE_MEETING,
  },
  {
    label: "Custom",
    value: VALUE_CUSTOM,
  },
];

export default function AiForm({
  users,
  onClose,
  onGenerate,
}: {
  users: KnTeamUserRole[];
  onClose: () => void;
  onGenerate: (e: Event) => void;
}) {
  const isLoadingGenAi = useAppSelector(({ inbox }) => inbox.getInboxData.isLoadingGenAi);
  const methods = useFormContext<SmsFormType>();
  const { watch } = methods;
  const type = watch("type");

  const userOptions = useMemo(() => {
    return users.map((u) => {
      return {
        label: `${u.firstName} ${u.lastName}`,
        value: u.user_id,
      };
    });
  }, [users]);

  if (isLoadingGenAi) {
    // TODO: Can organize this much better but let's wait for Fernando on UX.
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "8px 16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          background: "#F2F4F8",
          animation: "fadeIn 0.3s",
        }}
      >
        <Loading />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "8px 16px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        background: "#F2F4F8",
        animation: "fadeIn 0.3s",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <XMarkIcon
          onClick={() => onClose()}
          style={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
      </Box>
      <FormControl>
        <Controller
          name="type"
          control={methods.control}
          render={({ field, fieldState }) => {
            return (
              <>
                <FormLabel label="What’s the purpose of this message?" aria-required infoIcon />
                <Dropdown
                  isSearchable
                  options={genAiType}
                  value={field.value?.value}
                  onChange={(value) => field.onChange(value)}
                />
              </>
            );
          }}
        />
      </FormControl>

      {type?.value === VALUE_MEETING && (
        <FormControl>
          <Controller
            name="userIds"
            control={methods.control}
            render={({ field, fieldState }) => {
              return (
                <>
                  <FormLabel label="Booking With" aria-required infoIcon />
                  <Dropdown
                    {...field}
                    isSearchable
                    label="Team Members"
                    options={userOptions}
                    isMulti={true}
                    menuPlacement="auto"
                    onChange={(option) => {
                      field.onChange(option.map((item: OptionType<string>) => item.value));
                    }}
                  />
                </>
              );
            }}
          />
        </FormControl>
      )}

      {type?.value === VALUE_CUSTOM && (
        <FormControl>
          <Controller
            name="prompt"
            control={methods.control}
            render={({ field, fieldState }) => {
              return (
                <>
                  <FormLabel
                    label="In a few words describe what you would like to communicate"
                    aria-required
                    infoIcon
                  />
                  <Input {...field} multiline={true} placeholder="Description" error={fieldState.error?.message} />
                </>
              );
            }}
          />
        </FormControl>
      )}

      <Button
        fullWidth
        size="sm"
        variant="secondary"
        onClick={onGenerate}
        leftIcon={<SparklesIcon />}
        sx={{ width: "fit-content" }}
      >
        Generate Message
      </Button>
    </Box>
  );
}
