import SearchBar from "@/common/components/searchbar/Searchbar";
import { Box } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ContactsColumns } from "./table/columns";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { usePagination } from "@/common/components/pagination/usePagination";
import ContactsTable from "./table";
import { useEffect, useMemo, useRef, useState } from "react";
import { SequenceContacts, SequenceContactsFiltersInput } from "@/types/sequence";
import { getContactsBySequence, setContactsBySequenceFilters } from "@/redux/reducers/sequences/slices/listSequences";
import { debounce } from "lodash";
import { batch } from "react-redux";

interface ContactsProps {
  sequenceId: string | undefined;
}

export function Contacts({ sequenceId }: ContactsProps) {
  const dispatch = useAppDispatch();

  const { contacts, totalContacts } = useAppSelector((selector) => selector.sequences.listSequences);
  const { onPaginationChange, pagination } = usePagination();

  const [contactName, setContactName] = useState<string>("");
  const [filters, setFilters] = useState<SequenceContactsFiltersInput>({});

  const table = useReactTable<SequenceContacts>({
    data: contacts || [],
    columns: ContactsColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalContacts,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  const setDebounced = useMemo(
    () =>
      debounce((value) => {
        table.setPageIndex(0);
        setFilters({ name: value });
      }, 500),
    [],
  );

  useEffect(() => {
    if (sequenceId) {
      batch(() => {
        dispatch(
          setContactsBySequenceFilters({
            ...filters,
            pageNumber: pagination.pageIndex,
            pageSize: pagination.pageSize,
          }),
        );
        dispatch(getContactsBySequence(sequenceId));
      });
    }
  }, [dispatch, sequenceId, filters, pagination.pageIndex, pagination.pageSize]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <SearchBar
        value={contactName}
        onChange={(event) => {
          setDebounced(event.target.value);
          setContactName(event.target.value);
        }}
        placeholder="Search for a contact"
        sx={{ width: "100%", maxWidth: "284px" }}
      />
      <ContactsTable table={table} filters={filters} />
    </Box>
  );
}
