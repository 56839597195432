import toast from "@/lib/toast";
import { CallLogFiltersInput } from "@/pages/ai-voice/call-logs";
import { CALL_FLOW_OUTCOME_OPTIONS } from "@/pages/ai-voice/constants";
import { getCallSummary } from "@/redux/reducers/ai-voice/getCallSummary";
import Services from "@/services";
import { CallLog, KnBqLookupResponseKnCallMessage, KnFilter, KnFilterOptionOperatorEnum } from "@/services/generated";
import { downloadFileFromBlobResponse } from "@/utils/files";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFilterFormattedDate } from "../inbox/slices/listMessages";
import { sendCall } from "./send-call";

export interface RowCallLog extends CallLog {
  id: string;
}

type State = {
  call: CallLog | null;
  callSummary: KnBqLookupResponseKnCallMessage | null;
  callLogs: RowCallLog[] | undefined;
  loading: boolean;
  loadingCall: boolean;
  abortController: AbortController | null;
  loadingDetails: boolean;
  error: string | null;
  sendCallPending?: boolean;
  totalCount: number;
};

const initialState: State = {
  call: null,
  callSummary: null,
  callLogs: [],
  totalCount: 0,
  loading: true,
  loadingCall: false,
  loadingDetails: false,
  abortController: null,
  error: null,
  sendCallPending: false,
};

export const getCallById = createAsyncThunk(
  "aiVoice/getCallById",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      return await Services.AiVoice.getCall(id);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const lookupCalls = createAsyncThunk(
  "aiVoice/listCalls",
  async (filters: CallLogFiltersInput, { rejectWithValue, dispatch }) => {
    try {
      const abortController = new AbortController();
      const { signal } = abortController;
      dispatch(requestStart(abortController));

      const selectedFilters: Array<keyof Omit<CallLogFiltersInput, "page" | "pageSize">> = [
        "callOutcome",
        "callDirection",
        "agent",
        "text",
      ];

      const knFilters: KnFilter[] = [];

      selectedFilters.map((filter) => {
        if (filters[filter]) {
          const values = [];

          if (filter === "callOutcome") {
            Object.keys(CALL_FLOW_OUTCOME_OPTIONS).forEach((key) => {
              if (key.includes(filters[filter] as string)) {
                values.push(key);
              }
            });
          } else {
            values.push(filters[filter] as string);
          }

          knFilters.push({
            property: filter.includes("agent") ? "agentPhoneNumber" : filter,
            options: [
              {
                operator: KnFilterOptionOperatorEnum.Equal,
                values,
              },
            ],
          });
        }
      });

      if (filters.callEndedAtEnd && filters.callEndedAtStart) {
        const start = filters.callEndedAtStart;
        const end = filters.callEndedAtEnd;

        knFilters.push({
          property: "callEndedAt",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Between,
              values: [getFilterFormattedDate(start), getFilterFormattedDate(end)],
            },
          ],
        });
      }

      const response = await Services.AiVoice.lookupCalls(
        {
          filters: knFilters,
          page: filters.page,
          pageSize: filters.pageSize,
        },
        { signal },
      );

      if (!response.data || response.status !== 200) {
        return { ...response, data: { ...response.data, results: [] } };
      }

      const rows: RowCallLog[] =
        response.data.results?.map((l) => {
          return {
            id: l.call_id!,
            ...l,
          };
        }) || [];
      return { ...response, data: { ...response.data, results: rows } };
    } catch (error) {
      if (error.name === "CanceledError") {
        return rejectWithValue(error.name);
      }

      toast.error("Something went wrong!");
      return rejectWithValue(error.message);
    }
  },
);

export const exportCalls = createAsyncThunk(
  "aiVoice/exportCalls",
  async (filters: CallLogFiltersInput, { rejectWithValue, dispatch }) => {
    try {
      const selectedFilters: Array<keyof Omit<CallLogFiltersInput, "page" | "pageSize">> = [
        "callOutcome",
        "callDirection",
        "agent",
        "text",
      ];
      const knFilters: KnFilter[] = [];
      selectedFilters.map((filter) => {
        if (filters[filter]) {
          knFilters.push({
            property: filter,
            options: [
              {
                operator: KnFilterOptionOperatorEnum.Equal,
                values: [filters[filter] as string],
              },
            ],
          });
        }
      });

      if (filters.callEndedAtEnd && filters.callEndedAtStart) {
        const start = filters.callEndedAtStart;
        const end = filters.callEndedAtEnd;

        knFilters.push({
          property: "callEndedAt",
          options: [
            {
              operator: KnFilterOptionOperatorEnum.Between,
              values: [getFilterFormattedDate(start), getFilterFormattedDate(end)],
            },
          ],
        });
      }

      const response = await Services.AiVoice.exportCallLogs(
        {
          filters: knFilters,
          page: filters.page,
          pageSize: filters.pageSize,
        },
        { responseType: "blob" },
      );

      downloadFileFromBlobResponse(response, "CallLogs.xlsx");
      return;
    } catch (error) {
      toast.error("Something went wrong!");
      return rejectWithValue(error.message);
    }
  },
);

const listCalls = createSlice({
  name: "listCalls",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCallById.pending, (state) => {
        state.loadingCall = true;
      })
      .addCase(getCallById.fulfilled, (state, { payload }) => {
        state.call = payload?.data ?? null;
        state.loadingCall = false;
      })
      .addCase(getCallById.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(lookupCalls.pending, (state) => {
        state.loading = true;
      })
      .addCase(lookupCalls.fulfilled, (state, { payload }) => {
        state.callLogs = payload?.data.results;
        state.totalCount = payload?.data.total ?? 0;
        state.loading = false;
        state.abortController = null;
      })
      .addCase(lookupCalls.rejected, (state, { payload }) => {
        if (payload === "CanceledError") return;
        state.loading = false;
        state.abortController = null;
      })
      .addCase(exportCalls.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportCalls.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(exportCalls.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCallSummary.pending, (state, { payload }) => {
        state.loadingDetails = true;
      })
      .addCase(getCallSummary.fulfilled, (state, { payload }) => {
        state.callSummary = payload.data;
        state.loadingDetails = false;
        state.loading = false;
      })
      .addCase(getCallSummary.rejected, (state, { payload }) => {
        state.loadingDetails = false;
      })
      .addCase(sendCall.pending, (state) => {
        state.sendCallPending = true;
      })
      .addCase(sendCall.fulfilled, (state) => {
        state.sendCallPending = false;
      })
      .addCase(sendCall.rejected, (state) => {
        state.sendCallPending = false;
      });
  },
  reducers: {
    requestStart: (state, action: PayloadAction<AbortController>) => {
      // Abort previous request if it exists
      if (state.abortController) {
        state.abortController.abort();
      }
      state.loading = true;
      state.error = null;
      state.abortController = action.payload;
    },
  },
});

export const { requestStart } = listCalls.actions;

export default listCalls.reducer;
