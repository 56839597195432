import { AiAgentPhoneNumberListResponse, KnAiAgentCallDirectionEnum } from "@/services/generated";

export type PhoneNumberOption = {
  label: string | undefined;
  value: string | undefined;
  isDisabled: boolean | undefined;
  subtext: string | false | undefined;
};

export type PhoneNumberLists = {
  phoneNumberDropdownOptions: PhoneNumberOption[];
  availablePhoneList: PhoneNumberOption[];
  inUsePhoneList: PhoneNumberOption[];
};

export const SLIDER_MARKS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

export function getFormatedPhoneNumberList(
  callDirection: KnAiAgentCallDirectionEnum,
  phones?: AiAgentPhoneNumberListResponse,
): PhoneNumberOption[] {
  if (!phones?.list) return [];

  const phoneNumberList: PhoneNumberOption[] = phones.list
    .filter((phone) => !!phone?.number) //remove null phones
    .map((phone) => {
      const isInbound = callDirection === KnAiAgentCallDirectionEnum.Inbound;
      const isDisabled = isInbound && phone?.is_used_inbound;
      return {
        label: phone?.number,
        value: phone?.number,
        isDisabled: isDisabled,
        subtext: isDisabled && "In Use",
      };
    });

  return phoneNumberList;
}

export function getSortedPhoneNumberDropdownOptions(
  phoneNumberList: PhoneNumberOption[],
  isPhoneSortOrderDesc: boolean,
) {
  const inUsePhoneList: PhoneNumberOption[] = [];
  const availablePhoneList: PhoneNumberOption[] = [];

  phoneNumberList.forEach((phone) => {
    if (phone.isDisabled) {
      inUsePhoneList.push(phone);
    } else availablePhoneList.push(phone);
  });

  return [
    ...sortPhoneNumbers(availablePhoneList, isPhoneSortOrderDesc),
    ...sortPhoneNumbers(inUsePhoneList, isPhoneSortOrderDesc),
  ];
}

export function sortPhoneNumbers(phoneNumberList: PhoneNumberOption[], isPhoneSortOrderDesc: boolean) {
  return phoneNumberList.sort((phoneNumber1, phoneNumber2) => {
    if (isPhoneSortOrderDesc) return +phoneNumber2.value! - +phoneNumber1.value!;

    return +phoneNumber1.value! - +phoneNumber2.value!;
  });
}
