import StatusChip from "@/common/components/status-chip";
import { SequenceResponse, SequenceResponseStatusEnum } from "@/services/generated";
import { Box } from "@mui/material";
import { ProspectSequenceActionButton } from "./ActionButton";
import { TitleAndSubtitle } from "../title-and-subtitle";
import { dateFormatStr } from "@/common/functions/dateFormat";
import { useState } from "react";
import { useNavigate } from "react-router";
import routes from "@/common/constants/routes";
import SequenceWarningBox from "./SequenceWarningBox";
import { useAppDispatch } from "@/redux/store";
import { closeViewDrawer } from "@/redux/reducers/prospects/slices/listProspects";

interface ProspectInfoSequenceProps {
  sequence: SequenceResponse;
  isInbox?: boolean;
}

const ProspectInfoSequence = ({ sequence, isInbox }: ProspectInfoSequenceProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const canResumeSequence = sequence.status === SequenceResponseStatusEnum.PausedInProspectLevel;

  const [showSequencesWarning, setShowSequencesWarning] = useState(false);

  const handleSequenceWarning = () => {
    setShowSequencesWarning(true);
  };

  const handleNavigate = (sequenceStatus: SequenceResponseStatusEnum | undefined) => {
    dispatch(closeViewDrawer());
    sequenceStatus === SequenceResponseStatusEnum.PausedInTemplateLevel
      ? navigate(routes.sequence.path)
      : navigate(routes.settings.path);
  };

  const handleCloseWarningMessage = () => {
    setShowSequencesWarning(false);
  };

  return (
    <Box
      sx={{
        gap: "16px",
        flexDirection: "column",
        display: "flex",
        paddingLeft: "4px",
      }}
    >
      <Box
        style={{
          alignSelf: "stretch",
          justifyContent: "space-between",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <StatusChip status={sequence.status || SequenceResponseStatusEnum.InProgress} type="sequence" />
        {sequence.id && sequence.status !== SequenceResponseStatusEnum.Canceled && (
          <ProspectSequenceActionButton
            sequenceId={sequence.id}
            status={sequence.status}
            canResume={canResumeSequence}
            handleSequenceWarning={handleSequenceWarning}
          />
        )}
      </Box>

      {showSequencesWarning ? (
        <SequenceWarningBox
          sequenceName={sequence.name}
          sequenceStatus={sequence.status}
          handleActionButton={() => handleNavigate(sequence.status)}
          handleCancelButton={() => handleCloseWarningMessage()}
        />
      ) : undefined}

      <TitleAndSubtitle title="Sequence Name" subtitle={sequence.name} customWidth={isInbox ? null : "80%"} />

      {sequence.triggeredAt && (
        <TitleAndSubtitle title="Date Enrolled" subtitle={dateFormatStr(sequence.triggeredAt)} />
      )}

      <TitleAndSubtitle
        title="Progress of Sequence"
        subtitle={`${sequence.countStepsCompleted} out of ${sequence.countSteps}`}
      />
    </Box>
  );
};

export default ProspectInfoSequence;
