import ProspectsAutocomplete from "@/common/components/autocomplete/ProspectsAutocomplete";
import Button from "@/common/components/button";
import FormLabel from "@/common/components/form-label/FormLabel";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import DrawerLayout from "@/layouts/DrawerLayout";
import { ActionButtons } from "@/pages/inbox/send-new/ActionButtons";
import {
  closeSendNewMessageDrawer,
  openSendNewMessageDrawer,
  openViewDrawer,
} from "@/redux/reducers/inbox/slices/getInboxData";
import { getProspects, setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function SendNewMessage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const createSmsPending = useAppSelector(({ inbox }) => inbox.getInboxData.createSmsPending);
  const sendNewMessageDrawerIsOpen = useAppSelector(({ inbox }) => inbox.getInboxData.sendNewMessageDrawerIsOpen);
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);

  const onSubmit = () => {
    if (!currentProspect) {
      return;
    }

    dispatch(openViewDrawer());
    dispatch(closeSendNewMessageDrawer());
  };

  useEffect(() => {
    dispatch(getProspects());
  }, [dispatch]);

  const MessageComposer = () => {
    return (
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Box>
          <FormLabel label="Select Contact" aria-required />
          <ProspectsAutocomplete />
        </Box>
        <ActionButtons sendText="Select" onSubmit={onSubmit} />
      </Box>
    );
  };

  return (
    <>
      {createSmsPending && <FullLoadingOverlay loading={true} overlay={true} />}
      <Button
        variant="primary"
        onClick={() => {
          dispatch(openSendNewMessageDrawer());
          dispatch(setCurrentProspect(null));
        }}
      >
        {t("inbox.newMessage")}
      </Button>
      <Drawer open={sendNewMessageDrawerIsOpen} onClose={() => dispatch(closeSendNewMessageDrawer())} anchor="right">
        <DrawerLayout
          title="Send New Message"
          testid="newProspectForm"
          closeDrawer={() => dispatch(closeSendNewMessageDrawer())}
        >
          <Box style={{ height: "100%" }}>
            <MessageComposer />
          </Box>
        </DrawerLayout>
      </Drawer>
    </>
  );
}
