import SalesAi from "@/common/assets/svg/salesai.svg";
import SalesAiFull from "@/common/assets/svg/salesaifull.svg";
import { useRoles } from "@/common/components/roles/useRoles";
import { drawerWidth, drawerWidthClosed } from "@/common/constants/drawer";
import { DashboardMenuItem, getDashboardMenuItems } from "@/common/constants/menuItems";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useMemo } from "react";
import SidebarItem from "./SidebarItem";

export default function SidebarDrawer({ setOpen, open }: { setOpen: (open: boolean) => void; open: boolean }) {
  const handleToggle = () => setOpen(!open);
  const { isAdministrator } = useRoles();

  const showDashboardMenuItems: DashboardMenuItem[] = useMemo(() => {
    const routes = getDashboardMenuItems(isAdministrator);

    return routes
      .filter((i) => !i.hide)
      .map((i) => {
        return {
          ...i,
          sublinks: i.sublinks?.filter((l) => !l.hide),
        };
      });
  }, [isAdministrator]);

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.midnight.p,
        color: theme.palette.commonColors.light,
        width: open ? drawerWidth : drawerWidthClosed,
        padding: "20px 16px",
        transition: "all 0.2s ease-in-out",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      })}
    >
      <div>
        <Box sx={{ paddingLeft: "4px" }}>
          {open ? <img src={SalesAi} alt="SalesAI Logo" /> : <img src={SalesAiFull} alt="SalesAI Logo" />}
        </Box>

        <Box width="100%" onClick={handleToggle} sx={{ marginTop: "32px" }}>
          <IconButton>
            <KeyboardDoubleArrowRightIcon
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.2s ease-in-out",
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          {showDashboardMenuItems.map((item, index, array) => {
            if (index < array.length - 3)
              return <SidebarItem key={item.label} item={item} handleToggle={handleToggle} sidebarOpen={open} />;
          })}
        </Box>
      </div>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
        <Divider sx={{ background: "#54658d", marginBottom: 1 }} />
        {showDashboardMenuItems.map((item, index, array) => {
          if (index >= array.length - 3)
            return <SidebarItem key={item.label} item={item} handleToggle={handleToggle} sidebarOpen={open} />;
        })}
      </Box>
    </Box>
  );
}
