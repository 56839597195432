import { TriggerActionType, TriggerEventType } from "@/pages/triggers/type";
import Services from "@/services";
import { KnFilter2, KnLookupRequest2, KnLookupResponseTrigger, ListByEventTypeEventEnum } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createTrigger } from "./createTrigger";
import { Trigger } from "./types";
import { updateTrigger } from "./updateTrigger";

type State = {
  triggers?: Trigger[];
  currentTrigger: Trigger | null;
  loading: boolean;
  error: string | null;
  loadingTriggers: boolean;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  triggerActions: string[];
  triggerActionsPending: boolean;
  createTriggerPending?: boolean;
  updateTriggerPending?: boolean;

  loadingTriggerSummary: boolean;
  errorTriggerSummary: number | null;
  triggerSummary: number | null | undefined;
};

const initialState: State = {
  triggers: [],
  currentTrigger: null,
  loading: false,
  error: null,
  loadingTriggers: true,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 10,
  triggerActions: [],
  triggerActionsPending: false,
  createTriggerPending: false,
  updateTriggerPending: false,

  loadingTriggerSummary: false,
  errorTriggerSummary: null,
  triggerSummary: null,
};

type Filters = {
  createdBy?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: KnLookupRequest2["sortDirection"];
  name?: string;
  isActive?: boolean;
  dealId?: string;
  eventType?: TriggerEventType;
  actionType?: TriggerActionType;
};

export const setCurrentTrigger = createAsyncThunk("triggers/get", async (id: string) => {
  try {
    const response = await Services.Triggers.getTrigger(id);

    return response;
  } catch (error) {
    return error;
  }
});

export const handleDelete = createAsyncThunk("triggers/delete", async (id: string) => {
  try {
    await Services.Triggers.deleteTrigger(id);
    return id;
  } catch (error) {
    return error;
  }
});

export const getTriggers = createAsyncThunk(
  "triggers/getTriggers",
  async (filters: Filters): Promise<KnLookupResponseTrigger> => {
    try {
      const filtersArray: KnFilter2[] = [];

      if (filters?.name) {
        filtersArray.push({
          property: "name",
          options: [
            {
              operator: "equal",
              values: [filters.name],
            },
          ],
        });
      }

      if (filters?.eventType) {
        filtersArray.push({
          property: "eventType",
          options: [
            {
              operator: "equal",
              values: [filters.eventType],
            },
          ],
        });
      }

      if (filters?.actionType) {
        filtersArray.push({
          property: "actionType",
          options: [
            {
              operator: "equal",
              values: [filters.actionType],
            },
          ],
        });
      }

      if (filters?.isActive !== undefined) {
        filtersArray.push({
          property: "active",
          options: [
            {
              operator: "equal",
              values: [filters.isActive.toString()],
            },
          ],
        });
      }
      const request = {
        page: filters.page,
        pageSize: filters.pageSize,
        sortBy: filters?.sortBy,
        sortDirection: filters?.sortDirection,
        filters: filtersArray,
      };

      const response = await Services.Triggers.listTrigger(request);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getActionsByEventType = createAsyncThunk(
  "triggers/getActionsByEventType",
  async (eventType: ListByEventTypeEventEnum) => {
    try {
      const response = await Services.TriggerActions.listByEventType(eventType);
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const getTriggerSummary = createAsyncThunk(
  "triggers/getTriggerSummary",
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Triggers.getTeamTriggerSummary(teamId);

      return response.data.activated;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const listTriggersSlice = createSlice({
  name: "listTriggers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getActionsByEventType.pending, (state) => {
        state.triggerActions = [];
        state.triggerActionsPending = true;
      })
      .addCase(getActionsByEventType.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.triggerActions = payload.data;
          state.triggerActionsPending = false;
        }
      })
      .addCase(getActionsByEventType.rejected, (state) => {
        state.triggerActions = [];
        state.triggerActionsPending = false;
      })
      .addCase(getTriggers.fulfilled, (state, { payload }) => {
        if (payload?.results) {
          state.loadingTriggers = false;
          state.totalCount = payload.total || 0;
          // state.pageNumber = payload.data.pageNumber || 0;
          // state.pageSize = payload.data.pageSize || 0;
          state.triggers = payload.results || [];
        }
      })
      .addCase(getTriggers.rejected, (state, { error }) => {
        // state.error = error.message;
        state.loadingTriggers = false;
        state.triggers = [];
      })

      .addCase(createTrigger.pending, (state, { payload }) => {
        state.createTriggerPending = true;
      })
      .addCase(createTrigger.fulfilled, (state, { payload }) => {
        if (payload) {
          state.createTriggerPending = false;
          state.triggers = [payload, ...(state.triggers ?? [])];
        }
      })

      .addCase(updateTrigger.pending, (state, { payload }) => {
        state.updateTriggerPending = true;
      })
      .addCase(updateTrigger.fulfilled, (state, { payload }) => {
        state.updateTriggerPending = false;
        if (payload) {
          state.triggers = state.triggers?.map((triggers) => (triggers.id === payload.id ? payload : triggers)) as any;
        }
      })

      .addCase(handleDelete.fulfilled, (state, { payload }) => {
        state.triggers = state.triggers?.filter((task) => task.id !== payload);
      })

      .addCase(setCurrentTrigger.fulfilled, (state, action) => {
        if (action.payload) {
          state.currentTrigger = action.payload.data;
        }
      });

    builder.addCase(getTriggerSummary.pending, (state, { payload }) => {
      state.loadingTriggerSummary = true;
      state.errorTriggerSummary = null;
    });
    builder.addCase(getTriggerSummary.fulfilled, (state, { payload }) => {
      state.loadingTriggerSummary = false;
      state.triggerSummary = payload;
    });

    builder.addCase(getTriggerSummary.rejected, (state, { payload }) => {
      state.loadingTriggerSummary = false;
    });
  },
  reducers: {},
});

export const {} = listTriggersSlice.actions;

export default listTriggersSlice.reducer;
