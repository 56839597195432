import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";

const objectMapFields: { [key in KnFirestoreImportJobImportJobTypeEnum]: string[] } = {
  [KnFirestoreImportJobImportJobTypeEnum.Company]: [
    "address",
    "address.city",
    "address.country",
    "address.postalCode",
    "address.state",
    "budgetAds",
    "budgetAdsCurrency",
    "budgetSolution",
    "budgetSolutionCurrency",
    "domain",
    "email",
    "headcount",
    "industry",
    "leadSource",
    "linkedinUrl",
    "name",
    "phoneNumber",
    "revenue",
  ],
  [KnFirestoreImportJobImportJobTypeEnum.Contact]: [
    "address",
    "address.city",
    "address.country",
    "address.postalCode",
    "address.state",
    "consentLevel",
    "decisionTimeframe",
    "declaredConsentOrigin",
    "department",
    "email",
    "firstName",
    "jobTitle",
    "lastName",
    "leadSource",
    "leadType",
    "linkedinUrl",
    "painPoints",
    "phoneNumber",
    "seniorityLevel",
  ],
  [KnFirestoreImportJobImportJobTypeEnum.Product]: [
    "description",
    "features",
    "months",
    "name",
    "price",
    "status",
    "valueProp",
  ],
};

export default objectMapFields;
