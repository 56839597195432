import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { createColumnHelper } from "@tanstack/react-table";
import TableActionButton from "./ActionButton";
import { TransferNumberDto } from "@/services/generated";

const columnHelper = createColumnHelper<TransferNumberDto & { id: string }>();

export const TransferPhoneNumberColumns = [
  columnHelper.accessor("name", {
    header: () => <TableHeader>Name</TableHeader>,
    cell: (info) => {
      const name = info.getValue();
      return <TableCell>{name}</TableCell>;
    },
  }),
  columnHelper.accessor("number", {
    header: () => <TableHeader>Phone Number</TableHeader>,
    cell: (info) => {
      const number = info.getValue();
      return <TableCell>{number}</TableCell>;
    },
  }),
  columnHelper.display({
    id: "actions",
    header: () => <TableHeader> Actions</TableHeader>,
    cell: (props) => (!props.row.original.is_user ? <TableActionButton params={props} /> : ""),
  }),
];
