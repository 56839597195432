import Switch from "@/common/components/switch/Switch";
import TableCell from "@/common/components/table-cell";
import TableDate from "@/common/components/table-date";
import TableHeader from "@/common/components/table-header";
import TableSortBy from "@/common/components/table-sort-by";
import createCheckboxCol from "@/common/functions/createCheckboxCol";
import { success } from "@/lib/toast";
import { ActionsType } from "@/pages/triggers/edit/steps/action";
import { updateTrigger } from "@/redux/reducers/triggers/slices/updateTrigger";
import { useAppDispatch } from "@/redux/store";
import { ListByEventTypeEventEnum, Trigger } from "@/services/generated";
import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback } from "react";
import { TriggersFilterProps } from "../filters/TriggersFilters";
import TableActionButton from "./ActionButton";

const columnHelper = createColumnHelper<Trigger>();

const mapEventType = (eventType: string) => {
  switch (eventType) {
    case ListByEventTypeEventEnum.DealFlowStateChange:
      return "Deal State Change";
    case ListByEventTypeEventEnum.DealAssigneeChange:
      return "Deal Assignee Change";
    case ListByEventTypeEventEnum.MeetingStateChange:
      return "Meeting State Change";
    case ListByEventTypeEventEnum.ProspectEngagement:
      return "Contact Engagement";
    case ListByEventTypeEventEnum.NewContact:
      return "New Contact";
    default:
      return "";
  }
};

const mapActionType = (actionType: string) => {
  switch (actionType) {
    case ActionsType.CREATE_TASK:
      return "Create Task";
    case ActionsType.ENROLL_SEQUENCE:
      return "Enroll Sequence";
    case ActionsType.SET_DEAL_FLOW_STAGE:
      return "Set Deal Flow Stage";
    case ActionsType.CANCEL_SEQUENCE:
      return "Cancel Sequence";
    case ActionsType.CREATE_DEAL:
      return "Create Deal";
    case ActionsType.SEND_EMAIL:
      return "Send Email";
    case ActionsType.SEND_SMS:
      return "Send SMS";
    default:
      return "";
  }
};

export const TriggerColumns = ({ filters, setFilters }: TriggersFilterProps) => {
  const dispatch = useAppDispatch();

  const toggleTrigger = useCallback(
    ({ id, data }: { id: string; data: Trigger }) => {
      dispatch(updateTrigger({ id, data })).then(() => {
        success("Trigger updated successfully");
      });
    },
    [dispatch],
  );

  const updateSort = (sortBy: string) => {
    switch (sortBy) {
      case "executionCount":
        if (filters.sortBy === "executionCount") {
          if (filters?.sortDirection === "desc") {
            setFilters((previousFilters) => ({ ...previousFilters, sortBy: "executionCount", sortDirection: "asc" }));

            return;
          }

          setFilters((previousFilters) => ({ ...previousFilters, sortBy: undefined, sortDirection: undefined }));

          return;
        }

        setFilters((previousFilters) => ({ ...previousFilters, sortBy: "executionCount", sortDirection: "desc" }));
        return;
      case "updatedAt":
        if (filters.sortBy === "updatedAt") {
          if (filters?.sortDirection === "desc") {
            setFilters((previousFilters) => ({ ...previousFilters, sortBy: "updatedAt", sortDirection: "asc" }));

            return;
          }

          setFilters((previousFilters) => ({ ...previousFilters, sortBy: undefined, sortDirection: undefined }));

          return;
        }

        setFilters((previousFilters) => ({ ...previousFilters, sortBy: "updatedAt", sortDirection: "desc" }));
        return;
    }
  };

  return [
    createCheckboxCol(columnHelper),

    columnHelper.accessor("name", {
      header: () => <TableHeader>Trigger Name</TableHeader>,
      cell: (field) => {
        return <TableCell>{field.getValue()}</TableCell>;
      },
    }),

    columnHelper.accessor("event", {
      header: () => <TableHeader>Event</TableHeader>,
      cell: ({ row }: any) => {
        return <TableCell>{mapEventType(row.original.event.eventType)}</TableCell>;
      },
    }),

    columnHelper.accessor("action", {
      header: () => <TableHeader>Action</TableHeader>,
      cell: ({ row }: any) => {
        return <TableCell>{mapActionType(row.original.action.actionType)}</TableCell>;
      },
    }),

    columnHelper.accessor("status", {
      header: () => <TableHeader>Status</TableHeader>,
      cell: ({ row }: any) => {
        return (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Switch
              value={row.original.active}
              onChange={(e, checked) => {
                toggleTrigger({ id: row.original.id, data: { ...row.original, isActive: checked } });
              }}
            />
            <TableCell>{row.original.active ? "Active" : "Inactive"}</TableCell>
          </Box>
        );
      },
    }),

    columnHelper.accessor("triggered", {
      header: () => (
        <TableHeader
          sx={{ maxWidth: "113px", marginX: "auto" }}
          rightIcon={
            <TableSortBy
              sx={{ padding: "0px", width: "fit", minWidth: "0px" }}
              sortDirection={filters.sortBy === "executionCount" ? filters?.sortDirection : undefined}
              onClick={() => updateSort("executionCount")}
            />
          }
        >
          Triggered
        </TableHeader>
      ),
      cell: ({ row }) => {
        return <TableCell sx={{ textAlign: "center" }}>{row.original.executionCount || 0}</TableCell>;
      },
    }),

    columnHelper.accessor("lastEdited", {
      header: () => (
        <TableHeader
          sx={{ maxWidth: "123px" }}
          rightIcon={
            <TableSortBy
              sx={{ padding: "0px", width: "fit", minWidth: "0px" }}
              sortDirection={filters.sortBy === "updatedAt" ? filters?.sortDirection : undefined}
              onClick={() => updateSort("updatedAt")}
            />
          }
        >
          Last Edited
        </TableHeader>
      ),
      cell: ({ row }) => {
        return <TableDate date={row.original.updatedAt} />;
      },
    }),

    columnHelper.display({
      id: "actions",
      header: () => <TableHeader sx={{ display: "flex", justifyContent: "center" }}>Actions</TableHeader>,
      cell: (props) => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TableActionButton params={props} />
        </Box>
      ),
    }),
  ];
};
