import { Loading } from "@kennected/components";
import { Box } from "@mui/material";

interface MetricSummaryCardProps {
  loading: boolean;
  width?: string | number | undefined;
  label: string;
  value: number | string | undefined;
}

export default function MetricSummaryCard({ loading, width, label, value }: MetricSummaryCardProps) {
  return (
    <Box
      sx={{
        padding: "8px 16px",
        border: "solid 1px #e7ebed",
        borderRadius: "8px",
        fontSize: "16px",
        color: "#1a2135",
        display: "flex",
        flexDirection: "column",
        fontWeight: "normal",
        gap: "8px",
        height: "76px",
        width: width,
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <span style={{ fontWeight: "600" }}>{label}</span>
          <span style={{ fontWeight: "400" }}>{value}</span>
        </>
      )}
    </Box>
  );
}
