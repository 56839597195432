import { ReactComponent as Product } from "@/common/assets/svg/data-import/product.svg";
import { ReactComponent as Prospects } from "@/common/assets/svg/data-import/prospects.svg";
import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import { Box } from "@mui/material";
import ImportCard from "../ImportCard";

export type ProspectStep = {
  type: KnFirestoreImportJobImportJobTypeEnum;
  image: JSX.Element;
  title: string;
  description: string;
};

const steps: ProspectStep[] = [
  {
    type: KnFirestoreImportJobImportJobTypeEnum.Contact,
    image: <Prospects />,
    title: "Contacts",
    description: "The individuals you collaborate with, often referred to as leads or customers.",
  },
  {
    type: KnFirestoreImportJobImportJobTypeEnum.Product,
    image: <Product />,
    title: "Product",
    description: "The goods or services associated with a company, often referred to as products.",
  },
];

export const ImportTypeStep = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        justifyContent: "center",
      }}
    >
      {steps.map((step) => (
        <ImportCard key={step.type} {...step} />
      ))}
    </Box>
  );
};
