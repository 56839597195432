import { useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/store";
import { getIsConnectedTwilio } from "@/redux/reducers/ai-voice/getIsConnectedTwilio";
import { batch } from "react-redux";
import { searchWebhook } from "@/redux/reducers/settings/company/crm/webhook/search";
import { v4 as uuidv4 } from "uuid";
import { TeamIntegrationTableDataType } from "@/pages/admin/teams/view/team-integration-table";

enum IntegrationCategory {
  Communications = "Communications",
  InboundLeads = "Inbound Leads",
}

const useGetIntegrations = (): TeamIntegrationTableDataType[] => {
  const dispatch = useAppDispatch();
  const [isConnectedTwilio, setIsConnectedTwilio] = useState(false);
  const [hasWebhooks, setHasWebhooks] = useState(false);
  const [integrations, setIntegrations] = useState<TeamIntegrationTableDataType[]>([]);
  useEffect(() => {
    batch(async () => {
      dispatch(searchWebhook({ pageNumber: 0, pageSize: 10 })).then((res: any) => {
        setHasWebhooks(res.payload.data?.results.length > 0);
      });
      dispatch(getIsConnectedTwilio())
        .then((res) => {
          setIsConnectedTwilio(res.payload.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }).finally(() => {
      setIntegrations([
        {
          id: uuidv4(),
          name: "Twilio",
          category: IntegrationCategory.Communications,
          status: isConnectedTwilio,
        },
        {
          id: uuidv4(),
          name: "Webhooks",
          category: IntegrationCategory.InboundLeads,
          status: hasWebhooks,
        },
      ]);
    });
  }, [dispatch, isConnectedTwilio, hasWebhooks]);

  return integrations;
};

export default useGetIntegrations;
