import { Box, SxProps, Theme } from "@kennected/components";
import { ImgHTMLAttributes, ReactNode } from "react";

export const logoUrls = {
  LOGO_GMAIL: "logo-gmail.png",
  LOGO_GOOGLE_CALENDAR: "logo-google-calendar.png",
  LOGO_OUTLOOK: "logo-outlook.png",
  LOGO_HUBSPOT: "logo-hubspot.png",
  LOGO_FACEBOOK: "logo-facebook.png",
};

export enum LogoSize {
  Default = 26,
  XSmall = 20,
  Small = 32,
  Medium = 48,
  Large = 64,
}

type LogoProps = ImgHTMLAttributes<HTMLImageElement> & {
  size?: number;
};

export const Logo = ({ size, ...props }: LogoProps) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img
    style={{
      maxWidth: size ?? LogoSize.Default,
      maxHeight: size ?? LogoSize.Default,
      borderRadius: 4,
      ...props.style,
    }}
    {...props}
  />
);

type PresetLogoProps = Omit<LogoProps, "src" | "alt">;

Logo.Gmail = (props: PresetLogoProps) => <Logo alt="Gmail" src={logoUrls.LOGO_GMAIL} {...props} />;
Logo.GoogleCalendar = (props: PresetLogoProps) => <Logo alt="Google" src={logoUrls.LOGO_GOOGLE_CALENDAR} {...props} />;
Logo.Outlook = (props: PresetLogoProps) => <Logo alt="Outlook" src={logoUrls.LOGO_OUTLOOK} {...props} />;
Logo.Hubspot = (props: PresetLogoProps) => <Logo alt="Hubspot" src={logoUrls.LOGO_HUBSPOT} {...props} />;
Logo.Facebook = (props: PresetLogoProps) => <Logo alt="Facebook" src={logoUrls.LOGO_FACEBOOK} {...props} />;

type LogoIconProps = {
  size?: LogoSize;
  icon: ReactNode;
  sx?: SxProps<Theme>;
};

// border: withBorder ? `1px dotted ${theme.palette.grey[700]}` : undefined,
Logo.Icon = ({ size, sx, icon }: LogoIconProps) => (
  <Box
    sx={[
      (theme) => ({
        color: theme.palette.grey[900],
        width: size ?? LogoSize.Default,
        height: size ?? LogoSize.Default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {icon}
  </Box>
);
