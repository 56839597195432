import { route } from "static-route-paths";

const routes = route({
  root: route(),

  onboard: route("onboard", {
    termsOfService: route("terms-of-service"),
    team: route("team"),
    billing: route("billing"),
  }),

  account: route("account", {
    enableApi: route("enable-api"),
    oauthCallback: route("oauth-callback", {
      application: route(":application"),
    }),
  }),
  oauth: route("", {
    callback: route("/account/oauth-callback/:application"),
  }),

  tasks: route("tasks"),

  triggers: route("triggers", {
    table: route(""),
    create: route("create"),
    update: route("update/:triggerId"),
  }),

  // TODO update
  prospects: route("contacts"),

  leadFlow: route("lead-flow", {
    dashboard: route(""),
    setup: route("setup"),
  }),

  sequence: route("sequence-builder", {
    table: route(""),
    create: route("new"),
    update: route("edit/:id"),
  }),

  inbox: route("inbox"),

  dataImport: route("data-import", {
    table: route(""),
    import: route("import"),
  }),

  aiVoice: route("ai-voice", {
    callLogs: route("call-logs", {
      id: route(":id"),
    }),
    agents: route("", {
      table: route(""),
      create: route("create"),
      edit: route("edit/:id"),
    }),
  }),

  settings: route("settings"),

  integration: route("integration"),

  admin: route("admin"),
});

export default routes;
