import { createSlice } from "@reduxjs/toolkit";
import { getCompanyDetails } from "@/redux/reducers/ai-learning/company-details/get";
import { persistCompanyDetails } from "@/redux/reducers/ai-learning/company-details/persist";
import { AiCompanyDetailsCompanyHeadcountEnum } from "@/services/generated";

type State = {
  id: string | null;
  companyName: string | null;
  companyHeadcount: AiCompanyDetailsCompanyHeadcountEnum | null;
  companyValueProposition: string | null;
  linkToFaq: string | null;
  linkToKnowledgeBase: string | null;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  id: null,
  companyName: null,
  companyHeadcount: null,
  companyValueProposition: null,
  linkToFaq: null,
  linkToKnowledgeBase: null,
  loading: true,
  error: null,
};

const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.id = payload.data.id || "";
          state.companyName = payload.data.company_name || "";
          state.companyHeadcount = payload.data.company_headcount || null;
          state.companyValueProposition = payload.data.company_value_proposition || "";
          state.linkToFaq = payload.data.link_to_faq || "";
          state.linkToKnowledgeBase = payload.data.link_to_knowledge_base || "";
        }
        state.loading = false;
      })
      .addCase(persistCompanyDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.id = payload.data.id || "";
          state.companyName = payload.data.company_name || "";
          state.companyHeadcount = payload.data.company_headcount || null;
          state.companyValueProposition = payload.data.company_value_proposition || "";
          state.linkToFaq = payload.data.link_to_faq || "";
          state.linkToKnowledgeBase = payload.data.link_to_knowledge_base || "";
        }
      });
  },
  // Dispatch by Component
  reducers: {},
});

export default companyDetailsSlice.reducer;
