import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function EmptyState({
  Icon,
  title,
  description,
}: {
  Icon: React.ElementType;
  title: string;
  description?: string | React.ReactNode;
}) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        padding: "92px 24px 24px 24px",
      })}
    >
      <Box
        sx={{
          maxWidth: "542px",
          width: "100%",
          margin: "0 auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          border: `1px solid #9FA9C0`,
          borderRadius: "8px",
          padding: "32px 16px",
        }}
      >
        <Box
          sx={{
            background: "#F2F4F8",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "24px",
            padding: "16px",
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          <Icon width={64} height={64} stroke="#374667" />
        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#1A2135",
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{
              color: "#1A2135",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
