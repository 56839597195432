import { Typography, useTheme } from "@mui/material";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export type PhoneInputProps = {
  value?: string | undefined | null;
  country?: string;
  error?: string | undefined | null;
  onChange?: (phone: string) => void;
  disabled?: boolean;
};

export default function PhoneInput({ value, country = "us", error, onChange, disabled }: PhoneInputProps) {
  const theme = useTheme();

  return (
    <>
      <ReactPhoneInput
        value={value}
        country={country}
        disabled={disabled}
        onChange={(phone) => {
          const phoneValue = phone.startsWith("+") ? phone : `+${phone}`;
          onChange?.(phone ? phoneValue : "");
        }}
        placeholder="+1 (000) 000-0000"
        containerStyle={{
          width: "100%",
          height: "44px",
        }}
        buttonStyle={{
          borderColor: error ? theme.palette.border.danger : theme.palette.commonColors.light,
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
        inputStyle={{
          height: "44px",
          width: "100%",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.2px",
          padding: "12px 16px",
          paddingLeft: "50px",
          borderRadius: "8px",
          background: theme.palette.commonColors.white,
          borderColor: error ? theme.palette.border.danger : theme.palette.commonColors.light,
        }}
        inputClass="custom-phone-input"
      />
      {error && (
        <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
          {error || "This field is required"}
        </Typography>
      )}
      <style>{`
        .custom-phone-input {
          color: #6b747b;
        }

        .custom-phone-input::placeholder {
          color: ${theme.palette.txt.placeholder};
        }
      `}</style>
    </>
  );
}
