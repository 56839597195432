import routes from "@/common/constants/routes";
import StatusBadge from "../../sequence-status";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { SequenceTemplateVersionResponse } from "@/services/generated";
import { useNavigate } from "react-router";
import { useAppDispatch } from "@/redux/store";
import { SequenceTemplate } from "@/types/sequence";

interface VersionsProps {
  versions: SequenceTemplateVersionResponse[];
  sequence: SequenceTemplate;
}

export function Versions({ versions = [], sequence }: VersionsProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (id: string) => {
    navigate(routes.sequence.update.with({ id: id }));
  };

  return (
    <TableContainer sx={{ mt: 2, border: "1px solid #E7EBED", borderRadius: "8px", overflow: "hidden" }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.palette.tableRow.heading }}>
          <TableRow sx={{ textTransform: "uppercase" }}>
            <TableCell sx={{ fontWeight: 600, padding: "0.61rem 16px", width: "100%" }}>Version</TableCell>
            <TableCell sx={{ fontWeight: 600, padding: "0.61rem 16px", minWidth: "92px" }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versions.map((version) => (
            <TableRow
              key={`versions-${version.id}`}
              onClick={() => handleNavigate(version.id!)}
              sx={{
                cursor: "pointer",
                "&:nth-of-type(odd)": {
                  backgroundColor: "#FFFFFF",
                },
                "&:nth-of-type(even)": {
                  backgroundColor: "#FBFBFB",
                },
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              }}
            >
              <TableCell sx={{ padding: "0.61rem 16px", width: "100%" }}>
                {sequence.name} - Version {version.version}
              </TableCell>
              <TableCell sx={{ padding: "0.61rem 16px", minWidth: "92px" }}>
                {version.status && <StatusBadge status={version.status} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
