import AttachmentInput from "@/common/components/attachment/Attachment";
import Avatar from "@/common/components/avatar";
import Button from "@/common/components/button";
import { DatePicker } from "@/common/components/date-picker/DatePicker";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { TimePickerInput } from "@/common/components/time-picker";
import debounce from "@/common/functions";
import { labelAscComparator } from "@/common/functions/option";
import { teamUserOptionLabel } from "@/common/functions/teamUserOptionLabel";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Deal } from "@/redux/reducers/dealFlow/slices/types";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { getProspects, getProspectsByLastName } from "@/redux/reducers/prospects/slices/listProspects";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { deleteTask } from "@/redux/reducers/tasks/slices/listTask";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TaskRequestTypeEnum } from "@/services/generated";
import { ErrorMessage } from "@hookform/error-message";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { TrashOutline } from "heroicons-react";
import { SyntheticEvent, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TaskFormProps = {
  toggleDrawer: () => void;
  onSubmit: any;
  drawerTitle: string;
  actionButtonLabel?: string;
  isLoading?: boolean;
};

const errorMessageStyle = {
  color: "red",
  fontSize: "12px",
  margin: "0px",
  marginLeft: "8px",
};

export default function TaskForm(props: TaskFormProps) {
  const { toggleDrawer, onSubmit, drawerTitle, isLoading, actionButtonLabel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const teamUsersLoading = useAppSelector(({ team }) => team.listUsers.loading);
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const currentTask = useAppSelector(({ tasks }) => tasks.listTask.currentTask);
  const { deleteConfirm } = useConfirmModal();
  const methods = useFormContext();
  const { prospects, loading } = useAppSelector(({ prospects }) => prospects.listProspects);
  const contacts = prospects;

  const { control, handleSubmit, watch, clearErrors } = methods;

  useEffect(() => {
    if (teamUsers === undefined && !teamUsersLoading) {
      dispatch(getTeamUsers());
    }
  }, [dispatch, teamUsers, teamUsersLoading]);

  useEffect(() => {
    if (prospects === undefined) {
      dispatch(getProspects());
    }
  }, [prospects, dispatch]);

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList());
    }
  }, [dispatch, deals]);

  const handleInputChangeContact = useMemo(
    () =>
      debounce((value) => {
        if (value.length < 1) return;
        dispatch(getProspectsByLastName({ lastName: value }));
      }, 500),
    [dispatch],
  );

  const contactOptions = useMemo(
    () =>
      (contacts || []).map((contact) => ({
        icon: () => <Avatar src={""} size="small" />,
        value: contact.id!,
        label: contact.firstName! + " " + contact.lastName!,
      })),
    [contacts],
  );

  const assigneeOptions = useMemo(() => {
    return (teamUsers || [])
      .map((u) => {
        return {
          label: teamUserOptionLabel(u),
          value: u.user_id!,
        };
      })
      .sort(labelAscComparator);
  }, [teamUsers]);

  const handleDeleteTask = (taskId: string | undefined) => {
    if (!taskId) return;
    dispatch(deleteTask(taskId));
  };

  return (
    <form
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        clearErrors();
        handleSubmit(onSubmit)();
      }}
    >
      <DrawerLayout title={drawerTitle} closeDrawer={toggleDrawer} status={watch("status")}>
        <>
          {!currentTask ? (
            <LoadingSpinner loading={true} />
          ) : (
            <Box>
              <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <FormLabel aria-required label="Task Type" />
                        <Dropdown
                          label="Task Type"
                          options={TaskTypeSelectOptions.filter((o) => o.show)}
                          value={field.value}
                          onChange={(option) => field.onChange(option.value)}
                          error={fieldState.error?.message}
                        />
                      </>
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="assignee"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <FormLabel aria-required label="Assignee" />
                          <Dropdown
                            label="Assignee"
                            value={field.value}
                            ref={field.ref}
                            options={assigneeOptions}
                            onChange={(option) => field.onChange(option.value)}
                            error={fieldState.error?.message}
                          />
                        </>
                      );
                    }}
                  />
                </FormControl>
              </Stack>
              {watch("type") === TaskRequestTypeEnum.Other && (
                <FormControl sx={{ width: "50%" }}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormLabel aria-required label="Please Specify" />
                        <Input type="text" placeholder="Description" {...field} />
                        <ErrorMessage
                          errors={methods.formState.errors}
                          name="description"
                          as="p"
                          style={errorMessageStyle}
                        />
                      </>
                    )}
                  />
                </FormControl>
              )}
              <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="prospect"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormLabel aria-required label="Contact" />
                        <Dropdown
                          label="Contact"
                          isSearchable
                          onInputChange={handleInputChangeContact}
                          isPending={loading}
                          options={contactOptions}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="deal"
                    control={control}
                    render={({ field }) => {
                      const options = deals?.map((deal: Deal) => ({
                        label: deal.name!,
                        value: deal.id!,
                      }));
                      return (
                        <>
                          <FormLabel aria-required label="Deal" />
                          <Dropdown
                            label="Deal"
                            options={options || []}
                            {...field}
                            onChange={(value) => {
                              field.onChange(value.value);
                            }}
                          />
                          <ErrorMessage
                            errors={methods.formState.errors}
                            name="deal"
                            as="p"
                            style={errorMessageStyle}
                          />
                        </>
                      );
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="dueDate"
                    control={control}
                    render={({ field }) => {
                      return (
                        <>
                          <FormLabel aria-required label="Due Date" />

                          <DatePicker {...field} />

                          <ErrorMessage
                            errors={methods.formState.errors}
                            name="dueDate"
                            as="p"
                            style={errorMessageStyle}
                          />
                        </>
                      );
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%" }}>
                  <FormLabel aria-required label="Due Time" />
                  <Controller
                    name="dueTime"
                    control={control}
                    render={({ field }) => {
                      return <TimePickerInput {...field} dueDate={methods.watch("dueDate")} />;
                    }}
                  />
                  <ErrorMessage errors={methods.formState.errors} name="dueTime" as="p" style={errorMessageStyle} />
                </FormControl>
              </Stack>
              <FormControl sx={{ width: "50%" }}>
                <Controller
                  name="attachments"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel label="Attachments" />
                      <AttachmentInput {...field} />
                      <ErrorMessage
                        errors={methods.formState.errors}
                        name="attachments"
                        as="p"
                        style={errorMessageStyle}
                      />
                    </>
                  )}
                />
              </FormControl>
              <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <FormControl style={{ width: "50%" }}>{/* missing attachment input component */}</FormControl>
              </Stack>
            </Box>
          )}

          <DrawerButtonsLayout>
            <Button
              type="button"
              onClick={() =>
                deleteConfirm({
                  textDiv: (
                    <>
                      <Typography variant="body1">Are you sure you want to delete this task?</Typography>
                      <Typography variant="body1">This action cannot be undone.</Typography>
                    </>
                  ),
                })
                  .then(() => {
                    handleDeleteTask(currentTask?.id);
                  })
                  .catch(() => {
                    console.log("cancelled");
                  })
              }
              variant="secondary"
              sx={{
                color: "red",
                borderColor: "red",

                "&:focus": {
                  color: "red",
                  borderColor: "red",
                },
                "&:hover": {
                  color: "red",
                  borderColor: "red",
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                },
              }}
            >
              <TrashOutline size={18} />
            </Button>

            <Button data-testid="closeTaskDrawer" variant="secondary" fullWidth onClick={toggleDrawer}>
              {t("tasks.cancel")}
            </Button>

            <Button disabled={isLoading} loading={isLoading} type="submit" variant="primary" fullWidth>
              {actionButtonLabel}
            </Button>
          </DrawerButtonsLayout>
        </>
      </DrawerLayout>
    </form>
  );
}
