import {
  KnAiAgentBookingModeEnum,
  KnAiAgentCallFlowEnum,
  KnAiAgentCallToAction2Enum,
  KnAiAgentCallToActionEnum,
  KnAiAgentRequestBookingModeEnum,
  KnAiAgentRequestLanguageEnum,
} from "@/services/generated";

export const VOICE_LANGUAGE_OPTIONS: {
  value: KnAiAgentRequestLanguageEnum;
  tag: string;
  label: string;
}[] = [
  { value: KnAiAgentRequestLanguageEnum.EnUs, tag: "ENGLISH", label: "English" },
  { value: KnAiAgentRequestLanguageEnum.EnAu, tag: "ENGLISH_AUSTRALIA", label: "English (Australia)" },
  { value: KnAiAgentRequestLanguageEnum.EnIn, tag: "ENGLISH_INDIA", label: "English (India)" },
  { value: KnAiAgentRequestLanguageEnum.EnGb, tag: "ENGLISH_UK", label: "English (UK)" },
  { value: KnAiAgentRequestLanguageEnum.Fr, tag: "FRENCH", label: "French" },
  { value: KnAiAgentRequestLanguageEnum.FrCa, tag: "FRENCH_CANADA", label: "French (Canada)" },
  { value: KnAiAgentRequestLanguageEnum.Nl, tag: "DUTCH", label: "Dutch" },
  { value: KnAiAgentRequestLanguageEnum.It, tag: "ITALIAN", label: "Italian" },
  { value: KnAiAgentRequestLanguageEnum.Zh, tag: "CHINESE_MANDARIN", label: "Chinese (Mandarin)" },
  { value: KnAiAgentRequestLanguageEnum.Pt, tag: "PORTUGUESE", label: "Portuguese" },
  { value: KnAiAgentRequestLanguageEnum.PtBr, tag: "PORTUGUESE_BRAZIL", label: "Portuguese (Brazil)" },
  { value: KnAiAgentRequestLanguageEnum.Es, tag: "SPANISH", label: "Spanish" },
  { value: KnAiAgentRequestLanguageEnum.Es419, tag: "SPANISH_LATIN_AMERICA", label: "Spanish (Latin America)" },
  { value: KnAiAgentRequestLanguageEnum.Hi, tag: "HINDI", label: "Hindi" },
  { value: KnAiAgentRequestLanguageEnum.HiLatn, tag: "HINDI_LATIN_SCRIPT", label: "Hindi (Latin Script)" },
];

export const DEFAULT_TRANSFER_PHRASE =
  "Great! I can connect you with a live representative who can answer any other questions you have. Should I go ahead and transfer you?";

export const CALL_FLOW_REQUIRE_HOOK_STATEMENT: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
  KnAiAgentCallFlowEnum.BookAMeeting,
  KnAiAgentCallFlowEnum.QualifyAndLiveTransfer,
  KnAiAgentCallFlowEnum.AnswerFaqs,
  KnAiAgentCallFlowEnum.ConfirmAMeeting,
];

export const CALL_FLOW_REQUIRE_KNOWLEDGE_BASE: KnAiAgentCallFlowEnum[] = [KnAiAgentCallFlowEnum.AnswerFaqs];

export const CALL_FLOW_REQUIRE_PROMPT: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.PromptAndQualify,
  KnAiAgentCallFlowEnum.Prompt,
  KnAiAgentCallFlowEnum.Script,
  KnAiAgentCallFlowEnum.PromptAndQualifyV1,
  KnAiAgentCallFlowEnum.PromptV1,
  KnAiAgentCallFlowEnum.ScriptV1,
  KnAiAgentCallFlowEnum.DiscoveryTransfer,
];

export const CALL_FLOW_REQUIRE_PROMPT_QUALIFY: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.PromptAndQualify,
  KnAiAgentCallFlowEnum.Prompt,
  KnAiAgentCallFlowEnum.Script,
  KnAiAgentCallFlowEnum.PromptAndQualifyV1,
  KnAiAgentCallFlowEnum.PromptV1,
  KnAiAgentCallFlowEnum.ScriptV1,
  KnAiAgentCallFlowEnum.DiscoveryTransfer,
  KnAiAgentCallFlowEnum.Sales,
];

export const CALL_FLOW_REQUIRE_CALL_TO_ACTION: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.AnswerFaqs,
  KnAiAgentCallFlowEnum.PromptAndQualify,
  KnAiAgentCallFlowEnum.Prompt,
  KnAiAgentCallFlowEnum.Script,
  KnAiAgentCallFlowEnum.PromptAndQualifyV1,
  KnAiAgentCallFlowEnum.PromptV1,
  KnAiAgentCallFlowEnum.ScriptV1,
  KnAiAgentCallFlowEnum.Sales,
  KnAiAgentCallFlowEnum.DiscoveryTransfer,
];

// TODO: Depracte dev only call flows
export const CALL_FLOW_REQUIRE_BOOKING_MODE: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
  KnAiAgentCallFlowEnum.BookAMeeting,
];

export const CALL_FLOW_REQUIRE_MEETING_SETTINGS: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
  KnAiAgentCallFlowEnum.BookAMeeting,
];

export const CALL_FLOW_REQUIRE_BOOKING_WITH: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
  KnAiAgentCallFlowEnum.BookAMeeting,
];

export const CALL_FLOW_REQUIRE_TRANSFER: KnAiAgentCallFlowEnum[] = [KnAiAgentCallFlowEnum.QualifyAndLiveTransfer];

export const bookingModeValues: KnAiAgentRequestBookingModeEnum[] = [
  KnAiAgentRequestBookingModeEnum.RoundRobin,
  KnAiAgentRequestBookingModeEnum.OneToOne,
];

export const showAndReqPrompt = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_PROMPT.includes(callFlow);
};

export const showAndReqPromptQualify = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_PROMPT_QUALIFY.includes(callFlow);
};

export const showAndReqHookStatement = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_HOOK_STATEMENT.includes(callFlow);
};

export const reqKnowledgeBase = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_KNOWLEDGE_BASE.includes(callFlow);
};

export const showAndReqCallToAction = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_CALL_TO_ACTION.includes(callFlow);
};

export const showAndReqBookingMode = (
  callFlow: KnAiAgentCallFlowEnum,
  callToAction?: KnAiAgentCallToActionEnum,
  callToAciton2?: KnAiAgentCallToAction2Enum,
) => {
  return (
    CALL_FLOW_REQUIRE_BOOKING_MODE.includes(callFlow) ||
    [callToAction, callToAciton2].includes(KnAiAgentCallToActionEnum.BookMeeting)
  );
};

export const showAndReqMeetingSettings = (
  callFlow: KnAiAgentCallFlowEnum,
  callToAction?: KnAiAgentCallToActionEnum,
  callToAction2: KnAiAgentCallToAction2Enum[] = [],
) => {
  return (
    CALL_FLOW_REQUIRE_MEETING_SETTINGS.includes(callFlow) ||
    [callToAction, ...callToAction2].includes(KnAiAgentCallToActionEnum.BookMeeting)
  );
};

export const showAndReqBookingWith = (
  callFlow: KnAiAgentCallFlowEnum,
  callToAction?: KnAiAgentCallToActionEnum,
  callToAction2?: KnAiAgentCallToAction2Enum,
  bookingMode?: KnAiAgentBookingModeEnum,
) => {
  return (
    (CALL_FLOW_REQUIRE_BOOKING_WITH.includes(callFlow) ||
      [callToAction, callToAction2].includes(KnAiAgentCallToActionEnum.BookMeeting)) &&
    bookingMode === KnAiAgentBookingModeEnum.OneToOne
  );
};

export const showAndReqTransfer = (
  callFlow: KnAiAgentCallFlowEnum,
  callToAction?: KnAiAgentCallToActionEnum,
  callToAction2?: KnAiAgentCallToAction2Enum,
) => {
  return (
    CALL_FLOW_REQUIRE_TRANSFER.includes(callFlow) ||
    [callToAction, callToAction2].includes(KnAiAgentCallToActionEnum.LiveTransfer)
  );
};
