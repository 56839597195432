import React from "react";
import { SortAscending, SortDescending } from "heroicons-react";
import { Bars4Icon } from "@heroicons/react/24/solid";
import { Button, SxProps, Theme } from "@mui/material";

interface TableSortByProps {
  sortDirection?: string;
  onClick: () => void;
  sx?: SxProps<Theme>;
}

const TableSortBy: React.FC<TableSortByProps> = ({ sortDirection, onClick, sx }) => (
  <Button variant="text" onClick={onClick} sx={{ ...sx }}>
    {sortDirection === "asc" ? (
      <SortAscending width={24} color="gray" />
    ) : sortDirection === "desc" && sortDirection ? (
      <SortDescending width={24} color="gray" />
    ) : (
      <Bars4Icon width={24} color="gray" />
    )}
  </Button>
);

export default TableSortBy;
