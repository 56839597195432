import toast from "@/lib/toast";
import Services from "@/services";
import { KnCreateContactImportJobRequest, KnProcessContactImportJobRequest } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
};

const initialState: State = {
  loading: false,
};

export const createProspectJob = createAsyncThunk(
  "prospect/import/createProspectJob",
  async (body: KnCreateContactImportJobRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportContact.createContactImportJob(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const uploadProspectCsv = createAsyncThunk(
  "prospect/import/uploadProspectCsv",
  async (file: File, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportContact.uploadContactCsv(file);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const processProspectJob = createAsyncThunk(
  "prospect/import/processProspectJob",
  async (body: KnProcessContactImportJobRequest & { leadSource?: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportContact.processContactImportJob(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  },
);

export const deleteProspectJob = createAsyncThunk(
  "prospect/import/deleteProspectJob",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.DataImportContact.deleteContactImportJob(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  },
);

const prospectImportSlice = createSlice({
  name: "prospect/import",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createProspectJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProspectJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createProspectJob.rejected, (state) => {
        state.loading = false;
        toast.error("Failed to create contact job");
      })
      .addCase(deleteProspectJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProspectJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteProspectJob.rejected, (state) => {
        state.loading = false;
        toast.error("Failed to cancel contact job");
      })
      .addCase(uploadProspectCsv.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadProspectCsv.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadProspectCsv.rejected, (state) => {
        state.loading = false;
        toast.error("Failed to upload CSV file");
      })

      .addCase(processProspectJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(processProspectJob.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(processProspectJob.rejected, (state) => {
        state.loading = false;
        toast.error("Failed to process contact job");
      });
  },
  reducers: {},
});

export default prospectImportSlice.reducer;
