import * as React from "react";
import { useAppDispatch } from "@/redux/store";
import MenuOptions from "@/common/components/menu-options/MenuOptions";

import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";
import routes from "@/common/constants/routes";
import { deleteTransferNumber } from "@/redux/reducers/ai-voice/listTransferPhoneNumber";
import toast from "@/lib/toast";
import { CellContext } from "@tanstack/react-table";
import { TransferNumberDto } from "@/services/generated";

enum TransferNumberActionsEnum {
  Edit = "Edit",
  Delete = "Delete",
}

const options: { label: string; value: TransferNumberActionsEnum; icon: React.ReactElement }[] = [
  {
    label: "Edit Transfer Number",
    value: TransferNumberActionsEnum.Edit,
    icon: <PencilSquareIcon width={16} />,
  },
  {
    label: "Delete Transfer Number",
    value: TransferNumberActionsEnum.Delete,
    icon: <TrashIcon width={16} />,
  },
];

type TableActionButtonProps = {
  params: CellContext<
    TransferNumberDto & {
      id: string;
    },
    unknown
  >;
};

export default function TableActionButton({ params }: TableActionButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        const value = option.value as TransferNumberActionsEnum;
        if (value === TransferNumberActionsEnum.Edit) {
          // navigate(routes.aiVoice.transferNumberLibrary.id.with({ id: params.row.original.id }));
          handleClose();
        } else if (value === TransferNumberActionsEnum.Delete) {
          if (params.row.original.is_user) {
            toast.warning("Unable to delete transfer number due to being used by another agent.");
          } else {
            dispatch(deleteTransferNumber(params.row.original.id))
              .unwrap()
              .then(() => {
                toast.success("Transfer Number deleted successfully.");
              })
              .catch(() => {
                toast.error("Something went wrong!");
              });
          }

          handleClose();
        }
      }}
    />
  );
}
