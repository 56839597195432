import Dropdown from "@/common/components/dropdown";
import LinearProgressBar from "@/common/components/LinearProgressBar";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { usePagination } from "@/common/components/pagination/usePagination";
import Searchbar from "@/common/components/searchbar/Searchbar";
import TsTable from "@/common/components/ts-table";
import { Roles } from "@/common/constants/roles";
import toast from "@/lib/toast";
import { getTeamMinutes, getTeamUsers } from "@/redux/reducers/admin/teams/slices/getTeams";
import { resetSelectedUser, sendResetUserPassword } from "@/redux/reducers/admin/users/slices/reset-passoword";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TeamUser } from "@/types/admin";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TeamUsersColumns } from "./users-table/columns";
import ViewRowBottomDisplay from "./ViewRowBottomDisplay";

import { useRoles } from "@/common/components/roles/useRoles";
import { CheckCircleOutline, ExclamationCircle, X } from "heroicons-react";

import MetricSummaryCard from "@/common/components/metric-summary-card";
import TimeProgressCard from "@/common/components/time-progress-card";
import { dateFormatShort } from "@/common/functions/dateFormat";
import { getVoiceSummary } from "@/redux/reducers/ai-voice/summary";
import { getProspectSummary } from "@/redux/reducers/prospects/slices/listProspects";
import { getActiveSequenceEnrollments } from "@/redux/reducers/sequences/slices/listSequences";
import { getSMSMinutesSummary } from "@/redux/reducers/settings/billing";
import { getTeamSummary } from "@/redux/reducers/settings/team";
import { getTriggerSummary } from "@/redux/reducers/triggers/slices/listTriggers";
import TeamIntegrationTable from "./team-integration-table";
import { teamIntegrationHeader, teamIntegrationWrapper } from "./TeamInfoStyles";

interface TeamInfoProps {
  teamId: string;
}

export default function TeamInfo({ teamId }: TeamInfoProps) {
  const { selectedTeamUsers, loadingUsers, totalUsers, teamTotalMinutes, teamMinutesRemaining, loadingMinutes } =
    useAppSelector((state) => state.teams.getTeams);

  const { selectedRowId, selectedUserName, selectedUserEmail } = useAppSelector(
    (state) => state.teams.resetUserPassword,
  );

  const { voiceSummary, loadingVoiceSummary } = useAppSelector((state) => state.aiVoice.summary);

  const { infoSMSMinutes, loadingSMSMinutes } = useAppSelector(({ settings }) => settings.billing);

  const { SequenceEnrollments, loadingSequenceEnrollments } = useAppSelector(
    ({ sequences }) => sequences.listSequences,
  );

  const { prospectSummary, loadingProspectSummary } = useAppSelector(({ prospects }) => prospects.listProspects);

  const { triggerSummary, loadingTriggerSummary } = useAppSelector(({ trigger }) => trigger);

  const { teamSummary, loadingTeamSummary } = useAppSelector(({ settings }) => settings.team);

  const { userRoles } = useRoles();

  const { onPaginationChange, pagination } = usePagination();
  const [nameFilter, setNameFilter] = useState("");
  const [debouncedNameFilter, setDebouncedNameFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  const dispatch = useAppDispatch();

  const setDebounced = useMemo(
    () =>
      debounce((value) => {
        setDebouncedNameFilter(value);
      }, 500),
    [],
  );

  useEffect(() => {
    dispatch(
      getTeamUsers({
        teamId,
        filters: {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          filters: [
            {
              options: [{ operator: "equal", values: [debouncedNameFilter] }],
              property: "name",
            },
            ...(roleFilter
              ? [
                  {
                    options: [
                      {
                        values: [roleFilter],
                      },
                    ],
                    property: "role",
                  },
                ]
              : []),
          ],
        },
      }),
    );
  }, [debouncedNameFilter, dispatch, pagination.pageIndex, pagination.pageSize, roleFilter, teamId]);

  useEffect(() => {
    dispatch(getTeamMinutes(teamId));
    dispatch(getSMSMinutesSummary(teamId));
    dispatch(getVoiceSummary(teamId));
    dispatch(getActiveSequenceEnrollments(teamId));
    dispatch(getProspectSummary(teamId));
    dispatch(getTriggerSummary(teamId));
    dispatch(getTeamSummary(teamId));
  }, [dispatch, teamId]);

  const handleConfirmResetUserPassword = async () => {
    dispatch(sendResetUserPassword(selectedUserEmail)).then((result) => {
      const messageSentSuccessfully = result.meta.requestStatus === "fulfilled";
      if (messageSentSuccessfully) {
        toast.success(
          <span>
            We’ve sent a link to user <strong>{selectedUserName}</strong> ({selectedUserEmail}) to reset their password.
          </span>,
          {
            icon: (
              <CheckCircleOutline
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            ),
            closeButton: () => (
              <X
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            ),
            style: {
              backgroundColor: "#b0e9c2",
              display: "flex",
              alignItems: "center",
              minHeight: "40px",
            },
            className: "custom-toast-position",
          },
        );
      } else {
        toast.error(
          <span>
            Failed to send a password reset link to user <strong>{selectedUserName}</strong> ({selectedUserEmail}).
            Please try again later.
          </span>,
          {
            icon: (
              <ExclamationCircle
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            ),
            closeButton: () => (
              <X
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            ),
            style: {
              color: "#721c24",
              backgroundColor: "#f8d7da",
              display: "flex",
              alignItems: "center",
              minHeight: "40px",
              width: "644px",
            },
            className: "custom-toast-position",
          },
        );
      }
    });
    dispatch(resetSelectedUser());
  };

  const handleCancelResetUserPassword = () => {
    dispatch(resetSelectedUser());
  };

  const columns = useMemo(() => TeamUsersColumns, []);
  const [selectedTeamUsersStateful, setSelectedTeamUsersStateful] = useState<any>([]);

  useEffect(() => {
    setSelectedTeamUsersStateful(selectedTeamUsers);
  }, [selectedTeamUsers]);

  const table = useReactTable<TeamUser>({
    data: selectedTeamUsersStateful,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: totalUsers,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  const MetricSummaryList = useMemo(
    () => [
      {
        label: "Agents",
        value: voiceSummary ? voiceSummary.totalAgents : 0,
        width: "162px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Calls Transferred",
        value: voiceSummary ? voiceSummary.totalCallsTransferred : 0,
        width: "194px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Calls Made",
        value: voiceSummary ? voiceSummary.totalCallsMade : 0,
        width: "167px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Inbound Calls",
        value: voiceSummary ? voiceSummary.totalInboundCalls : 0,
        width: "164px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Contacts",
        value: prospectSummary || 0,
        width: "207px",
        loading: loadingProspectSummary,
      },
      {
        label: "Triggers Activated",
        value: triggerSummary || 0,
        width: "189px",
        loading: loadingTriggerSummary,
      },
      {
        label: "Calls Answered",
        value: voiceSummary ? voiceSummary.totalCallsAnswered : 0,
        width: "162px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Average Call Duration",
        value: `${voiceSummary ? voiceSummary.avgCallDuration : 0} Minutes`,
        width: "194px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Meetings Booked",
        value: voiceSummary ? voiceSummary.totalMeetingsBooked : 0,
        width: "167px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Outbound Calls",
        value: voiceSummary ? voiceSummary.totalOutboundCalls : 0,
        width: "164px",
        loading: loadingVoiceSummary,
      },
      {
        label: "Sequence Enrollments",
        value: SequenceEnrollments || 0,
        width: "207px",
        loading: loadingSequenceEnrollments,
      },
      {
        label: "Last Login Date",
        value: teamSummary?.userLastLogin ? dateFormatShort(teamSummary.userLastLogin) : 0,
        width: "189px",
        loading: loadingTeamSummary,
      },
    ],
    [
      voiceSummary,
      loadingVoiceSummary,
      prospectSummary,
      loadingProspectSummary,
      triggerSummary,
      loadingTriggerSummary,
      SequenceEnrollments,
      loadingSequenceEnrollments,
      teamSummary,
      loadingTeamSummary,
    ],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "600" }}>
        Summary
      </Typography>

      <Stack direction="row" justifyItems="flex-start" justifyContent="space-between">
        <Stack direction="row" justifyItems="center" gap="16px">
          <TimeProgressCard
            loading={loadingMinutes}
            total={teamTotalMinutes}
            remaining={teamMinutesRemaining}
            flag="Minutes"
          />

          <TimeProgressCard
            loading={loadingSMSMinutes}
            total={infoSMSMinutes?.smsTotal}
            remaining={infoSMSMinutes?.smsRemaining}
            flag="SMS"
          />
        </Stack>

        <Stack direction="row" justifyItems="center" gap="16px" flexWrap="wrap">
          <Box sx={{ padding: "4px 8px", height: "fit-content", borderRadius: "8px", backgroundColor: "#f2f4f8" }}>
            <span
              style={{
                fontSize: "16px",
                color: "#1a2135",
              }}
            >
              Admins {teamSummary ? teamSummary.admin : 0}
            </span>
          </Box>
          <Box sx={{ padding: "4px 8px", height: "fit-content", borderRadius: "8px", backgroundColor: "#f2f4f8" }}>
            <span
              style={{
                fontSize: "16px",
                color: "#1a2135",
              }}
            >
              Sales Managers {teamSummary ? teamSummary.salesManager : 0}
            </span>
          </Box>
          <Box sx={{ padding: "4px 8px", height: "fit-content", borderRadius: "8px", backgroundColor: "#f2f4f8" }}>
            <span
              style={{
                fontSize: "16px",
                color: "#1a2135",
              }}
            >
              Sales Reps {teamSummary ? teamSummary.salesRepresentative : 0}
            </span>
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        {MetricSummaryList.map((data, idx) => (
          <Grid item>
            <MetricSummaryCard
              key={idx}
              loading={data.loading}
              label={data.label}
              value={data.value}
              width={data.width}
            />
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ maxWidth: "284px", width: "100%" }}>
          <Searchbar
            value={nameFilter || ""}
            onChange={(event) => {
              setDebounced(event.target.value);
              setNameFilter(event.target.value);
            }}
            placeholder="Search for a user"
          />
        </Box>
        <Box sx={{ maxWidth: "204px", width: "100%" }}>
          <Dropdown
            placeholder="Role"
            options={userRoles.map((role) => ({
              label: role.roleName,
              value: role.roleCode,
            }))}
            onChange={(option) => {
              setRoleFilter(option.value);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <FullLoadingOverlay loading={loadingUsers} overlay overlayDark={false} />
        {selectedTeamUsers && (
          <TsTable
            table={table}
            notFoundProps={{
              title:
                selectedTeamUsers.length === 0 && (nameFilter || roleFilter)
                  ? "No users found that match your criteria"
                  : "No users found",
              description:
                selectedTeamUsers.length === 0 && (nameFilter || roleFilter) ? (
                  <span>Try a different search parameter.</span>
                ) : (
                  <span>No users found.</span>
                ),
              Icon: SquaresPlusIcon,
            }}
            bottomDisplay={(id) =>
              ViewRowBottomDisplay({
                id: id,
                rowSelectedId: selectedRowId,
                handleCancel: handleCancelResetUserPassword,
                handleConfirm: handleConfirmResetUserPassword,
              })
            }
          />
        )}
      </Box>
      <Box sx={teamIntegrationWrapper}>
        <Typography variant="body2" sx={teamIntegrationHeader}>
          Team Integrations
        </Typography>
        <TeamIntegrationTable />
      </Box>
    </Box>
  );
}
