import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import PhoneInput from "@/common/components/phone-input";
import { phoneValidationErrorMessage, phoneValidationRegex } from "@/common/constants/phone-validation";
import { labelAscComparator } from "@/common/functions/option";
import { teamUserOptionLabel } from "@/common/functions/teamUserOptionLabel";
import DrawerButtonsLayout from "@/layouts/DrawerButtonsLayout";
import DrawerLayout from "@/layouts/DrawerLayout";
import {
  ProspectAddressOptions,
  ProspectJobTitleOptions,
  ProspectLeadTypeOptions,
} from "@/redux/reducers/prospects/slices/helpers";
import {
  closeEditDrawer,
  createProspect,
  setCurrentProspect,
  updateProspect,
} from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnContactRequest, LeadSourceTypeEnum } from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const schema = Yup.object({
  firstName: Yup.string().label("First Name").required(),
  lastName: Yup.string().label("Last Name").required(),
  email: Yup.string(), //.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email"),
  phoneNumber: Yup.string()
    .matches(phoneValidationRegex, phoneValidationErrorMessage)
    .required("Phone Number is required"),
  companyId: Yup.string(),
  jobTitle: Yup.string(),
  address: Yup.object({
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    postal_code: Yup.string(),
  }),
  ownerUserId: Yup.string(),
  leadType: Yup.string(),
});

export type ContactForm = Yup.InferType<typeof schema>;

interface EditProspectFormProps {
  onSubmitCallBack: () => void;
}

export default function EditProspectForm({ onSubmitCallBack }: EditProspectFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const companies = useAppSelector(({ companies }) => companies.listCompanies.companies);
  const editDrawerIsOpen = useAppSelector(({ prospects }) => prospects.listProspects.editDrawerIsOpen);

  const { control, handleSubmit, reset } = useForm<ContactForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      firstName: currentProspect?.firstName,
      lastName: currentProspect?.lastName,
      email: currentProspect?.email,
      phoneNumber: currentProspect?.phoneNumber,
      companyId: currentProspect?.companyId || "",
      jobTitle: currentProspect?.jobTitle,
      address: {
        city: currentProspect?.address?.city || "",
        state: currentProspect?.address?.state || "",
        country: currentProspect?.address?.country || "",
        postal_code: currentProspect?.address?.postal_code || "",
      },
      ownerUserId: currentProspect?.ownerUserId,
      leadType: currentProspect?.leadType || "",
    });
  }, [reset, currentProspect]);

  const assigneeOptions = useMemo(() => {
    return (teamUsers || [])
      .map((u) => {
        return {
          label: teamUserOptionLabel(u),
          value: u.user_id!,
        };
      })
      .sort(labelAscComparator);
  }, [teamUsers]);

  const companyOptions = useMemo(() => {
    return (
      companies?.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      }) || []
    );
  }, [companies]);

  const onSubmit: SubmitHandler<ContactForm> = (data) => {
    if (!data) return;

    const prospect: KnContactRequest = {
      // TODO reorder
      id: currentProspect?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      companyId: data.companyId,
      jobTitle: data.jobTitle,
      address: data.address,
      ownerUserId: data.ownerUserId,
      leadType: data.leadType,
      // SYSTEM
      leadSource: LeadSourceTypeEnum.Ui,
    };

    if (currentProspect?.id) {
      dispatch(updateProspect(prospect)).then(() => {
        onSubmitCallBack();
      });
    } else {
      dispatch(createProspect(prospect)).then(() => {
        onSubmitCallBack();
      });
    }
    closeDrawer();
  };

  const onError: SubmitErrorHandler<ContactForm> = (errors) => {
    console.log(errors);
  };

  const closeDrawer = () => {
    dispatch(setCurrentProspect(null));
    dispatch(closeEditDrawer());
  };

  useEffect(() => {
    if (!editDrawerIsOpen) {
      dispatch(setCurrentProspect(null));
    }
  }, [dispatch, editDrawerIsOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <DrawerLayout
        customWidth="500px"
        title={currentProspect?.id ? t("prospects.editProspectTitle") : t("prospects.createProspectTitle")}
        testid="newProspectForm"
        closeDrawer={closeDrawer}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", margin: "0px 5px" }}>
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel aria-required label="First Name" />
                  <Input {...field} type="text" placeholder="John" error={fieldState?.error?.message} />
                </FormControl>
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel aria-required label="Last Name" />
                  <Input {...field} type="text" placeholder="Doe" error={fieldState?.error?.message} />
                </FormControl>
              )}
            />
          </Box>

          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Email" />
                <Input {...field} type="text" placeholder="johndoe@email.com" error={fieldState?.error?.message} />
              </FormControl>
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Phone" aria-required />
                <PhoneInput {...field} error={fieldState?.error?.message} />
              </FormControl>
            )}
          />

          <Controller
            name="jobTitle"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Job Title" />
                <Dropdown
                  isSearchable
                  label="Job Title"
                  options={ProspectJobTitleOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Location" />
                <Dropdown
                  isSearchable
                  label="Location"
                  options={ProspectAddressOptions}
                  value={field.value as any}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="ownerUserId"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Assignee" />
                <Dropdown
                  isSearchable
                  label="Assignee"
                  options={assigneeOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="leadType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel label="Lead Type" />
                <Dropdown
                  isSearchable
                  label="Lead Type"
                  options={ProspectLeadTypeOptions}
                  value={field.value}
                  onChange={(option) => field.onChange(option.value)}
                />
              </FormControl>
            )}
          />
        </Box>
        <DrawerButtonsLayout>
          <Button
            data-testid="closeTaskDrawer"
            variant="secondary"
            onClick={closeDrawer}
            type="button"
            sx={{ width: "100%" }}
          >
            {t("tasks.cancel")}
          </Button>

          <Button type="submit" variant="primary" sx={{ width: "100%" }}>
            Save
          </Button>
        </DrawerButtonsLayout>
      </DrawerLayout>
    </form>
  );
}
