import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import Switch from "@/common/components/switch";
import config from "@/common/constants/config";
import toast from "@/lib/toast";
import { processFaqUrl } from "@/redux/reducers/ai-voice/upsertAgent";
import { getTeamAiVoiceSettings } from "@/redux/reducers/settings/team-ai-voice/get";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnAiAgentCallDirectionEnum, KnAiAgentCallFlowEnum } from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "@kennected/components";
import { Box, IconButton, Stack, Tab, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AiAgentForm,
  reqKnowledgeBase,
  showAndReqCallToAction,
  showAndReqMeetingSettings,
  showAndReqPrompt,
  showAndReqPromptQualify,
} from "..";
import { CALL_FLOW_TYPE_OPTIONS } from "../../constants";
import CallToActionBuilder from "./call-to-actions/call-to-action-builder";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import ViewAll from "@/common/components/view-all/ViewAll";

const MEETING_NOTICE_TIME_OPTIONS = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 2 * 60 },
  { label: "3 hours", value: 3 * 60 },
  { label: "4 hours", value: 4 * 60 },
  { label: "5 hours", value: 5 * 60 },
  { label: "6 hours", value: 6 * 60 },
  { label: "12 hours", value: 12 * 60 },
  { label: "1 day", value: 24 * 60 },
  { label: "2 days", value: 24 * 60 * 2 },
  { label: "3 days", value: 24 * 60 * 3 },
  { label: "7 days", value: 24 * 60 * 7 },
  { label: "14 days", value: 24 * 60 * 14 },
  { label: "21 days", value: 24 * 60 * 21 },
];

const MEETING_BUFFER_TIME_OPTIONS = [
  { label: "No Buffer Time", value: 0 },
  { label: "15 min", value: 15 },
  { label: "30 min", value: 30 },
  { label: "45 min", value: 45 },
  { label: "1 hour", value: 60 },
  { label: "1 hour 15 min", value: 75 },
  { label: "1 hour 30 min", value: 90 },
  { label: "1 hour 45 min", value: 105 },
  { label: "2 hours", value: 120 },
];

const MEETING_TIMEZONE_OPTIONS = [
  { label: "UTC -12:00", value: "Etc/GMT+12" },
  { label: "UTC -11:00", value: "Etc/GMT+11" },
  { label: "UTC -10:00 (Hawaii)", value: "Pacific/Honolulu" },
  { label: "UTC -09:00 (Alaska)", value: "America/Anchorage" },
  { label: "UTC -08:00 (Pacific Time)", value: "America/Los_Angeles" },
  { label: "UTC -07:00 (Mountain Time)", value: "America/Denver" },
  { label: "UTC -06:00 (Central Time)", value: "America/Chicago" },
  { label: "UTC -05:00 (Eastern Time)", value: "America/New_York" },
  { label: "UTC -04:00 (Atlantic Time)", value: "America/Halifax" },
  {
    label: "UTC -03:00 (Argentina, Brazil)",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "UTC -02:00 (South Georgia)", value: "Atlantic/South_Georgia" },
  { label: "UTC -01:00 (Azores)", value: "Atlantic/Azores" },
  { label: "UTC 00:00 (GMT)", value: "Etc/UTC" },
  { label: "UTC +01:00 (Central Europe)", value: "Europe/Berlin" },
  { label: "UTC +02:00 (Eastern Europe)", value: "Europe/Athens" },
  { label: "UTC +03:00 (Moscow)", value: "Europe/Moscow" },
  { label: "UTC +04:00 (Dubai)", value: "Asia/Dubai" },
  { label: "UTC +05:00 (Pakistan)", value: "Asia/Karachi" },
  { label: "UTC +06:00 (Bangladesh)", value: "Asia/Dhaka" },
  { label: "UTC +07:00 (Thailand)", value: "Asia/Bangkok" },
  { label: "UTC +08:00 (China, Singapore)", value: "Asia/Shanghai" },
  { label: "UTC +09:00 (Japan, Korea)", value: "Asia/Tokyo" },
  { label: "UTC +10:00 (Sydney)", value: "Australia/Sydney" },
  { label: "UTC +11:00 (Solomon Islands)", value: "Pacific/Guadalcanal" },
  { label: "UTC +12:00 (New Zealand)", value: "Pacific/Auckland" },
];

const MEETING_TIME_OPTIONS: { label: string; value: string }[] = [
  { label: "12 AM", value: "00:00:00" },
  { label: "1 AM", value: "01:00:00" },
  { label: "2 AM", value: "02:00:00" },
  { label: "3 AM", value: "03:00:00" },
  { label: "4 AM", value: "04:00:00" },
  { label: "5 AM", value: "05:00:00" },
  { label: "6 AM", value: "06:00:00" },
  { label: "7 AM", value: "07:00:00" },
  { label: "8 AM", value: "08:00:00" },
  { label: "9 AM", value: "09:00:00" },
  { label: "10 AM", value: "10:00:00" },
  { label: "11 AM", value: "11:00:00" },
  { label: "12 PM", value: "12:00:00" },
  { label: "1 PM", value: "13:00:00" },
  { label: "2 PM", value: "14:00:00" },
  { label: "3 PM", value: "15:00:00" },
  { label: "4 PM", value: "16:00:00" },
  { label: "5 PM", value: "17:00:00" },
  { label: "6 PM", value: "18:00:00" },
  { label: "7 PM", value: "19:00:00" },
  { label: "8 PM", value: "20:00:00" },
  { label: "9 PM", value: "21:00:00" },
  { label: "10 PM", value: "22:00:00" },
  { label: "11 PM", value: "23:00:00" },
];

const MEETING_DAY_OPTIONS = [
  {
    label: "Every day",
    value: "EVERYDAY",
  },
  {
    label: "Mon - Fri",
    value: "MONDAY_TO_FRIDAY",
  },
  { label: "Sunday", value: "SUNDAY" },
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY" },
  { label: "Thursday", value: "THURSDAY" },
  { label: "Friday", value: "FRIDAY" },
  { label: "Saturday", value: "SATURDAY" },
];

const TEAM_ID_TIER_ONE_SUPPORT = [
  "3de68cb7-29ff-473f-804f-5992a0c70efe", // STG/PROD White Label
  "78a68b3d-5132-4a42-b454-4ce0e7f3c614", // STG/PROD White Label
  "66016138-7764-45e6-8d86-16f432eca58d", // DEV Internal
];

const TEAM_ID_DISCOVERY_TRANSFER = [
  "3de68cb7-29ff-473f-804f-5992a0c70efe", // STG/PROD White Label
  "78a68b3d-5132-4a42-b454-4ce0e7f3c614", // STG/PROD White Label
  "66016138-7764-45e6-8d86-16f432eca58d", // DEV Internal
];

const MAX_LENGTH_HOOK = 1000;
const MAX_LENGTH_PROMPT = 10000;

export default function CallFlowStep() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const theme = useTheme();
  const { control, watch, getValues, setValue, formState } = useFormContext<AiAgentForm>();
  const dispatch = useAppDispatch();
  const isLoadingFaq = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingFaq);
  const faqStatus = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.faqStatus);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const teamUsersLoading = useAppSelector(({ team }) => team.listUsers.loading);
  const [callToActionToggle, setCallToActionToggle] = useState(false);

  const isOutbound = getValues("call_direction") == KnAiAgentCallDirectionEnum.Outbound;
  const callFlow = watch("call_flow") || KnAiAgentCallFlowEnum.QualifyAndBookAMeeting;
  const callToAction = watch("call_to_action");
  const isCallFlowDiscoveryTransfer = watch("call_flow") === KnAiAgentCallFlowEnum.DiscoveryTransfer;
  const callToAction2 = watch("call_to_action_2");
  const [tabValue, setTabValue] = useState("general");
  const businessHoursFieldArray = useFieldArray({
    control,
    name: "business_hours",
  });

  const callFlowOptions = useMemo(() => {
    const options = [];
    if (config.flagAiVoiceFlowPromptAndQualify) {
      options.push({
        value: KnAiAgentCallFlowEnum.PromptAndQualify,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.PromptAndQualify].label,
      });
    }
    if (config.flagAiVoiceFlowPrompt) {
      options.push({
        value: KnAiAgentCallFlowEnum.Prompt,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.Prompt].label,
      });
    }
    if (config.flagAiVoiceFlowScript) {
      options.push({
        value: KnAiAgentCallFlowEnum.Script,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.Script].label,
      });
    }
    if (config.flagAiVoiceFlowFaq) {
      options.push({
        value: KnAiAgentCallFlowEnum.AnswerFaqs,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.AnswerFaqs].label,
      });
    }
    if (config.flagAiVoiceFlowQualifyAndBook) {
      options.push({
        value: KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.QualifyAndBookAMeeting].label,
      });
    }
    if (config.flagAiVoiceFlowQualifyAndLiveTransfer) {
      options.push({
        value: KnAiAgentCallFlowEnum.QualifyAndLiveTransfer,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.QualifyAndLiveTransfer].label,
      });
    }
    if (config.flagAiVoiceFlowBook) {
      options.push({
        value: KnAiAgentCallFlowEnum.BookAMeeting,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.BookAMeeting].label,
      });
    }
    if (config.flagAiVoiceFlowConfirm) {
      options.push({
        value: KnAiAgentCallFlowEnum.ConfirmAMeeting,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.ConfirmAMeeting].label,
      });
    }
    if (TEAM_ID_TIER_ONE_SUPPORT.includes(user?.team_id)) {
      options.push({
        value: KnAiAgentCallFlowEnum.TierOneSupport,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.TierOneSupport].label,
      });
    }

    if (config.flagAiVoiceFlowPromptAndQualifyV1) {
      options.push({
        value: KnAiAgentCallFlowEnum.PromptAndQualifyV1,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.PromptAndQualifyV1].label,
      });
    }
    if (config.flagAiVoiceFlowPromptV1) {
      options.push({
        value: KnAiAgentCallFlowEnum.PromptV1,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.PromptV1].label,
      });
    }
    if (config.flagAiVoiceFlowScriptV1) {
      options.push({
        value: KnAiAgentCallFlowEnum.ScriptV1,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.ScriptV1].label,
      });
    }
    if (config.flagAiVoiceFlowSales && !isOutbound) {
      options.push({
        value: KnAiAgentCallFlowEnum.Sales,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.Sales].label,
      });
    }

    if (
      config.flagAiVoiceFlowDiscoveryTransfer &&
      (TEAM_ID_DISCOVERY_TRANSFER.includes(user?.team_id) || config.isDEV)
    ) {
      options.push({
        value: KnAiAgentCallFlowEnum.DiscoveryTransfer,
        label: CALL_FLOW_TYPE_OPTIONS[KnAiAgentCallFlowEnum.DiscoveryTransfer].label,
      });
    }

    return options;
  }, [user, isOutbound]);

  useEffect(() => {
    dispatch(getTeamAiVoiceSettings());
  }, [dispatch]);

  useEffect(() => {
    if (teamUsers === undefined && !teamUsersLoading) {
      dispatch(getTeamUsers());
    }
  }, [dispatch, teamUsers, teamUsersLoading]);

  const isValidUrl = (urlString: string): boolean => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$", // validate fragment locator
      "i",
    );
    return urlPattern.test(urlString);
  };

  const debouncedProcessFaqUrl = useMemo(
    () => debounce((url: string) => dispatch(processFaqUrl(url)), 1000),
    [dispatch],
  );

  const handleFaqUrl = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const faqUrl = event.target.value;
      setValue("knowledge_base_url", faqUrl as never);
      if (!isValidUrl(faqUrl)) return;
      debouncedProcessFaqUrl(faqUrl);
    },
    [debouncedProcessFaqUrl],
  );

  useEffect(() => {
    if ((callToAction && callToAction !== "NONE") || isCallFlowDiscoveryTransfer) {
      setCallToActionToggle(true);
    }
  }, [callToAction, isCallFlowDiscoveryTransfer]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const meetingSettingsErrors = useMemo(() => {
    return (
      formState.errors.buffer_time ||
      formState.errors.meeting_description ||
      formState.errors.meeting_duration ||
      formState.errors.meeting_title ||
      formState.errors.notice_time ||
      formState.errors.time_zone
    );
  }, [
    formState.errors.buffer_time,
    formState.errors.meeting_description,
    formState.errors.meeting_duration,
    formState.errors.meeting_title,
    formState.errors.notice_time,
    formState.errors.time_zone,
  ]);

  return (
    <TabContext value={tabValue}>
      <TabList onChange={handleTabChange} indicatorColor="primary">
        <Tab label="General" value="general" sx={{ textTransform: "capitalize" }} />
        {showAndReqMeetingSettings(callFlow, callToAction, callToAction2) && (
          <Tab
            label="Meeting Settings"
            value="meetingSettings"
            sx={{
              textTransform: "capitalize",
              ...(meetingSettingsErrors && {
                border: "1px solid red",
              }),
            }}
          />
        )}
      </TabList>
      <TabPanel value="general">
        <Box
          sx={{
            display: "grid",
            gap: "32px",
          }}
        >
          <Controller
            name="call_flow"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label={t("callFlow.callFlow")} aria-required />
                <Dropdown
                  options={callFlowOptions}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
                {field.value && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      color: theme.palette.commonColors.text,
                      bgcolor: theme.palette.midnight[20],
                      overflow: "hidden",
                      p: 1,
                      borderRadius: 2,
                    }}
                  >
                    {CALL_FLOW_TYPE_OPTIONS[field.value].description}
                  </Typography>
                )}
              </FormControl>
            )}
          />

          {showAndReqPrompt(callFlow) && (
            <Controller
              name="call_flow_prompt"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl>
                  <FormLabel label={"Prompt"} aria-required />
                  <Input
                    {...field}
                    multiline
                    rows={6}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_LENGTH_PROMPT) {
                        field.onChange(e);
                      } else {
                        toast.error("Prompt cannot exceed 10,000 characters", {
                          style: {
                            width: "350px",
                          },
                        });
                      }
                    }}
                    error={fieldState.error?.message}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-18px",
                      right: "10px",
                      color:
                        field.value?.length === MAX_LENGTH_PROMPT
                          ? theme.palette.error.main
                          : theme.palette.text.secondary,
                    }}
                  >
                    <Typography variant="caption">
                      {`${field.value?.length || 0}/${MAX_LENGTH_PROMPT}`} characters used
                    </Typography>
                  </Box>
                </FormControl>
              )}
            />
          )}

          {showAndReqPromptQualify(callFlow) && (
            <Controller
              name="call_flow_prompt_qualify"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl>
                  <FormLabel
                    label={
                      callFlow === KnAiAgentCallFlowEnum.PromptAndQualify
                        ? "Required Qualifiers"
                        : "Objections/Question Talk Tracks"
                    }
                    aria-required
                  />
                  <Input
                    {...field}
                    multiline
                    rows={6}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_LENGTH_PROMPT) {
                        field.onChange(e);
                      } else {
                        toast.error("Prompt cannot exceed 10,000 characters", {
                          style: {
                            width: "350px",
                          },
                        });
                      }
                    }}
                    error={fieldState.error?.message}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-18px",
                      right: "10px",
                      color:
                        field.value?.length === MAX_LENGTH_PROMPT
                          ? theme.palette.error.main
                          : theme.palette.text.secondary,
                    }}
                  >
                    <Typography variant="caption">
                      {`${field.value?.length || 0}/${MAX_LENGTH_PROMPT}`} characters used
                    </Typography>
                  </Box>
                </FormControl>
              )}
            />
          )}

          {/* {showAndReqHookStatement(callFlow) && (
            <Controller
              name="hook_statement"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl>
                  <FormLabel label={t("callFlow.hookStatement")} />
                  <Input
                    placeholder={t("callFlow.hookStatement")}
                    {...field}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_LENGTH_HOOK) {
                        field.onChange(e);
                      } else {
                        toast.error("Prompt cannot exceed 1,000 characters", {
                          style: {
                            width: "350px",
                          },
                        });
                      }
                    }}
                    error={fieldState.error?.message}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-18px",
                      right: "10px",
                      color:
                        field.value?.length === MAX_LENGTH_PROMPT
                          ? theme.palette.error.main
                          : theme.palette.text.secondary,
                    }}
                  >
                    <Typography variant="caption">
                      {`${field.value?.length || 0}/${MAX_LENGTH_HOOK}`} characters used
                    </Typography>
                  </Box>
                </FormControl>
              )}
            />
          )} */}

          <Box sx={{ display: "flex", gap: 3 }}>
            <Controller
              name="knowledge_base_url"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel label="Knowledge Base URL" aria-required={reqKnowledgeBase(callFlow)} />
                  <Input
                    value={field.value}
                    onChange={handleFaqUrl}
                    error={faqStatus != "success" ? faqStatus : null}
                    success={faqStatus == "success" ? "Successfully uploaded!" : null}
                  />
                </FormControl>
              )}
            />
            {isLoadingFaq && <Loading />}
          </Box>

          {showAndReqCallToAction(callFlow) && (
            <>
              {!isCallFlowDiscoveryTransfer && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Switch
                    value={callToActionToggle}
                    onChange={(_, checked) => {
                      setCallToActionToggle(!!checked);

                      if (!checked) {
                        // TODO: Clear fields related to CTAs
                        setValue("call_to_action", "NONE" as never);
                        setValue("call_to_action_2", [] as never);
                      }
                    }}
                  />
                  <Typography>Call to Action</Typography>
                </Box>
              )}

              {callToActionToggle && (
                <>
                  <CallToActionBuilder isCallFlowDiscoveryTransfer={isCallFlowDiscoveryTransfer} />
                </>
              )}
            </>
          )}

          <Box sx={{ display: "flex", gap: 3 }}>
            <Controller
              name="catch_webhook"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel label={t("callFlow.thirdPartyWebhook")} />
                  <Input
                    placeholder="https://www.webhook-url-address.com"
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                  />
                </FormControl>
              )}
            />

            {isOutbound && (
              <Controller
                name="double_call"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl sx={{ width: "120px" }}>
                    <FormLabel label={t("callFlow.doubleCall")} />
                    <Switch
                      error={fieldState.error?.message}
                      value={!!field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  </FormControl>
                )}
              />
            )}
          </Box>
        </Box>
      </TabPanel>
      {showAndReqMeetingSettings(callFlow, callToAction, callToAction2) && (
        <TabPanel value="meetingSettings">
          <Box
            sx={{
              display: "grid",
              gap: "32px",
            }}
          >
            <Typography fontWeight={500} variant="subtitle1">
              Time And Availability
            </Typography>
            <Controller
              name="meeting_title"
              render={({ field, fieldState }) => (
                <FormControl>
                  <FormLabel
                    label="Meeting Title"
                    aria-required
                    infoIcon
                    infoText="This is the name of the calendar event sent to your contact. For example, 'SalesAi Demo'."
                  />
                  <Input error={fieldState.error?.message} {...field} placeholder="Meeting Title" />
                </FormControl>
              )}
            />
            <Controller
              name="time_zone"
              render={({ field, fieldState }) => (
                <FormControl>
                  <FormLabel label="Timezone" aria-required infoIcon />
                  <Dropdown
                    error={fieldState.error?.message}
                    isSearchable
                    options={MEETING_TIMEZONE_OPTIONS}
                    value={field.value}
                    onChange={(option) => field.onChange(option.value)}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="meeting_description"
              render={({ field, fieldState }) => (
                <FormControl>
                  <FormLabel
                    label="Description"
                    aria-required
                    infoIcon
                    infoText="This will appear in the invite description."
                  />
                  <Input error={fieldState.error?.message} {...field} multiline rows={2} />
                </FormControl>
              )}
            />

            <Box sx={{ display: "flex", gap: 2 }}>
              <Controller
                name="meeting_duration"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <FormLabel label="Duration" aria-required />
                    <Dropdown
                      error={fieldState.error?.message}
                      onChange={(option) => field.onChange(option.value)}
                      value={field.value}
                      options={[
                        { label: "15 min", value: 15 },
                        { label: "30 min", value: 30 },
                        { label: "45 min", value: 45 },
                        { label: "1 hour", value: 60 },
                        { label: "1 hour 15 min", value: 75 },
                        { label: "1 hour 30 min", value: 90 },
                        { label: "1 hour 45 min", value: 105 },
                        { label: "2 hours", value: 120 },
                      ]}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="notice_time"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <FormLabel
                      label="Notice Time"
                      aria-required
                      infoIcon
                      infoText="Minimum amount of time before a meeting can be booked."
                    />
                    <Dropdown
                      error={fieldState.error?.message}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                      options={MEETING_NOTICE_TIME_OPTIONS}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="buffer_time"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <FormLabel
                      label="Buffer Time"
                      aria-required
                      infoIcon
                      infoText="Padding around your meetings you can't be booked for."
                    />
                    <Dropdown
                      error={fieldState.error?.message}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                      options={MEETING_BUFFER_TIME_OPTIONS}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Stack spacing={2}>
              {businessHoursFieldArray.fields.map((timeField, index) => (
                <Box key={timeField.id} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <FormControl fullWidth>
                    <FormLabel label="Day" aria-required />
                    <Controller
                      control={control}
                      name={`business_hours.${index}.day_of_week`}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          onChange={(option) => field.onChange(option.value)}
                          options={MEETING_DAY_OPTIONS}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel label="Start Time" aria-required />
                    <Controller
                      control={control}
                      name={`business_hours.${index}.start_time`}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          error={fieldState.error?.message}
                          value={field.value as any}
                          onChange={(option) => field.onChange(option.value)}
                          options={MEETING_TIME_OPTIONS}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel label="End Time" aria-required />
                    <Controller
                      control={control}
                      name={`business_hours.${index}.end_time`}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          error={fieldState.error?.message}
                          value={field.value as any}
                          onChange={(option) => field.onChange(option.value)}
                          options={MEETING_TIME_OPTIONS}
                        />
                      )}
                    />
                  </FormControl>
                  <IconButton
                    onClick={() => businessHoursFieldArray.remove(index)}
                    sx={{ display: "flex", alignSelf: "end", mb: 0.3 }}
                  >
                    <TrashIcon width={24} height={24} color={theme.palette.error.main} />
                  </IconButton>
                </Box>
              ))}
              <ViewAll
                sx={{ mt: 2 }}
                onClick={() => businessHoursFieldArray.append({})}
                leftIcon={<PlusIcon height={16} color="#fff" />}
              >
                Add Hours
              </ViewAll>
            </Stack>
          </Box>
        </TabPanel>
      )}
    </TabContext>
  );
}
