import { ReactComponent as NoCallLogsFound } from "@/common/assets/svg/ai-voice/no-call-logs-found.svg";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import PageHeader from "@/common/components/page-header/PageHeader";
import { usePagination } from "@/common/components/pagination/usePagination";
import TsTable from "@/common/components/ts-table";
import routes from "@/common/constants/routes";
import { CallLogColumns } from "@/pages/ai-voice/call-logs/columns";
import CallLogFilters from "@/pages/ai-voice/call-logs/filters";
import { exportCalls, lookupCalls } from "@/redux/reducers/ai-voice/listCalls";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { CallLogCallOutcomeEnum } from "@/services/generated";
import { Box } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CallSummarySidePanel from "src/pages/ai-voice/call-logs/call-summary";

export type CallLogFiltersInput = {
  text?: string;
  agent?: string[];
  callOutcome?: CallLogCallOutcomeEnum;
  callDirection?: "INBOUND" | "OUTBOUND";
  callEndedAtStart?: Date;
  callEndedAtEnd?: Date;
  page?: number;
  pageSize?: number;
};

export default function CallLogsPage() {
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;

  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<CallLogFiltersInput>({});
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [openSummary, setOpenSummary] = useState<boolean>(false);

  const { onPaginationChange, pagination } = usePagination();
  const callLogs = useAppSelector((selector) => selector.aiVoice.listCalls.callLogs);
  const totalCount = useAppSelector((selector) => selector.aiVoice.listCalls.totalCount);
  const loading = useAppSelector((selector) => selector.aiVoice.listCalls.loading);

  const table = useReactTable({
    data: callLogs || [],
    pageCount: totalCount,
    columns: CallLogColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  useEffect(() => {
    dispatch(
      lookupCalls({
        ...filters,
        page: isFiltering ? 0 : pagination.pageIndex,
        pageSize: pagination.pageSize,
      }),
    );

    if (isFiltering) {
      setIsFiltering(false);
    }
  }, [dispatch, pagination.pageIndex, pagination.pageSize, filters]);

  useEffect(() => {
    setOpenSummary(!!id);
  }, [id]);

  const showCallSummary = (callId: string) => {
    setOpenSummary(true);
    navigate(routes.aiVoice.callLogs.id.with({ id: callId }));
  };

  const hideCallSummary = () => {
    setOpenSummary(false);
    navigate(routes.aiVoice.callLogs.path);
  };

  const onExportCalls = () => {
    dispatch(
      exportCalls({
        ...filters,
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
      }),
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <PageHeader
          title="Call Logs"
          filters={
            <CallLogFilters
              onExportCalls={onExportCalls}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              setIsFiltering={setIsFiltering}
              table={table}
            />
          }
        />

        {loading ? (
          <LoadingSpinner />
        ) : (
          <TsTable
            table={table}
            handleCellClick={showCallSummary}
            notFoundProps={{
              title: "No call logs yet!",
              description: (
                <span>
                  Your call logs will be displayed here once <br /> you begin making calls in the app.
                </span>
              ),
              Icon: NoCallLogsFound,
            }}
          />
        )}
      </Box>
      <CallSummarySidePanel open={openSummary} handleClose={hideCallSummary} />
    </>
  );
}
