import Button from "@/common/components/button";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import Stepper from "@/common/components/stepper/Stepper";
import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import AgentStep from "@/pages/ai-voice/ai-agent-edit/steps/agent-step/agent-step";
import AgentSummaryStep from "@/pages/ai-voice/ai-agent-edit/steps/agent-summary-step";
import CallFlowStep from "@/pages/ai-voice/ai-agent-edit/steps/call-flow-step";
import { lookupAgents } from "@/redux/reducers/ai-voice/listAgents";
import { createAgent, getAgent, updateAgent } from "@/redux/reducers/ai-voice/upsertAgent";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  KnAiAgentCallToActionEnum,
  KnAiAgentRequest,
  KnAiAgentBookingModeEnum,
  KnMeetingOptionsBusinessHours,
} from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { ArrowLeftOutline, ArrowRightOutline } from "heroicons-react";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import AgentTypeStep from "./steps/agent-type-step";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import moment from "moment";
import { AI_AGENT_DEFAULT, AiAgentForm, schema } from "./ai-agent-schema";

function checkTimeOverlap(h1: KnMeetingOptionsBusinessHours, h2: KnMeetingOptionsBusinessHours) {
  const time1Start = moment(h1.start_time, "HH:mm:ss");
  const time1End = moment(h1.end_time, "HH:mm:ss");
  const time2Start = moment(h2.start_time, "HH:mm:ss");
  const time2End = moment(h2.end_time, "HH:mm:ss");

  if (time1End.isBefore(time1Start)) time1End.add(1, "days");
  if (time2End.isBefore(time2Start)) time2End.add(1, "days");

  return time1End.isSameOrAfter(time2Start) && time1Start.isBefore(time2End);
}

const BUSINESS_DAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];

function findOverlappingBusinessHours(hours: KnMeetingOptionsBusinessHours[]): number[] {
  const overlappingIndexes = new Set<number>();

  hours.forEach((h1, h1Index) => {
    hours.forEach((h2, h2Index) => {
      if (h1Index >= h2Index || !h1.start_time || !h1.end_time || !h2.start_time || !h2.end_time) {
        return;
      }

      const isSameDay = h1.day_of_week === h2.day_of_week;
      const isBusinessDaysOverlap =
        (h1.day_of_week === "MONDAY_TO_FRIDAY" && BUSINESS_DAYS.includes(h2.day_of_week!)) ||
        (h2.day_of_week === "MONDAY_TO_FRIDAY" && BUSINESS_DAYS.includes(h1.day_of_week!));
      const isEverydayOverlap = h1.day_of_week === "EVERYDAY" || h2.day_of_week === "EVERYDAY";

      if (isSameDay || isBusinessDaysOverlap || isEverydayOverlap) {
        if (h1.start_time === h2.start_time && h1.end_time === h2.end_time) {
          overlappingIndexes.add(h1Index).add(h2Index);
        } else if (checkTimeOverlap(h1, h2)) {
          overlappingIndexes.add(h1Index).add(h2Index);
        }
      }
    });
  });

  return Array.from(overlappingIndexes);
}

export default function AiAgentEdit() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const loading = useAppSelector((selector) => selector.aiVoice.upsertAgent.loading);
  const faqId = useAppSelector((selector) => selector.aiVoice.upsertAgent.faqId);

  const [activeStep, setActiveStep] = useState<number>(0);

  const form = useForm<AiAgentForm>({
    resolver: yupResolver(schema),
    context: { step: activeStep },
    defaultValues: AI_AGENT_DEFAULT,
  });
  const { reset, handleSubmit, setError } = form;
  useEffect(() => {
    if (!params?.id) return;

    dispatch(getAgent(params.id))
      .unwrap()
      .then((agent) => {
        reset({
          call_direction: agent.call_direction,
          agent_label: agent.agent_label || "",
          language: agent.language,
          agent_phone_number: agent.phone_number || "",
          voice_id: agent.voice_id || "",
          interrupt: agent.interrupt,
          creativity: agent.creativity,
          call_flow: agent.call_flow,
          call_flow_prompt: agent.call_flow_prompt || "",
          call_flow_prompt_qualify: agent.call_flow_prompt_qualify || "",
          // hook_statement: agent.hook_statement || "",
          booking_mode: agent.booking_mode || KnAiAgentBookingModeEnum.OneToOne,
          booking_with: agent.booking_with || undefined,
          call_to_action: agent.call_to_action || KnAiAgentCallToActionEnum.None,
          call_to_action_2: agent.call_to_action_2 || [],
          transfer: agent.transfer || undefined,
          knowledge_base_url: agent.knowledge_base_url || "",
          catch_webhook: agent.catch_webhook || "",
          double_call: agent.double_call,
          transfer_phrase: agent.transfer_phrase,
          meeting_title: agent.meeting_title || "",
          meeting_description: agent.meeting_description || "",
          meeting_duration: agent.meeting_duration || undefined,
          notice_time: agent.notice_time || undefined,
          buffer_time: agent.buffer_time || undefined,
          business_hours: agent.business_hours || [],
          time_zone: agent.time_zone || "",
        });
      });
  }, [dispatch, params, reset]);

  // TODO Copy unique id on Knowledge Base Load

  const goToPreviousStep = () => {
    if (activeStep === 0) return;
    setActiveStep((step) => step - 1);
  };

  const onSubmit: SubmitHandler<AiAgentForm> = (data) => {
    if (activeStep === 3) {
      const request: KnAiAgentRequest = { ...data, knowledge_base_faq_id: faqId };
      if (params.id) {
        dispatch(updateAgent({ id: params.id, data: request }))
          .unwrap()
          .then(() => {
            dispatch(lookupAgents({}));
            navigate(routes.aiVoice.agents.path);
            toast.success("Agent updated successfully!");
          })
          .catch((error) => {
            navigate(routes.aiVoice.agents.path);
            toast.error(error);
          });
      } else {
        dispatch(createAgent(request))
          .unwrap()
          .then(() => {
            dispatch(lookupAgents({}));
            navigate(routes.aiVoice.agents.path);
            toast.success("Agent created successfully!");
          })
          .catch(() => {
            toast.error("Something went wrong!");
          });
      }
    } else {
      if (data.business_hours?.length) {
        const overlaps = findOverlappingBusinessHours(data?.business_hours);
        if (overlaps.length) {
          toast.warning(
            <Typography>
              It looks like the availability you’ve entered overlaps with an existing time block.{" "}
              <strong>Please adjust the times to avoid conflicts.</strong>
            </Typography>,
          );
          overlaps.forEach((index) => {
            setError(`business_hours.${index}.end_time`, { message: "Overlap" });
            setError(`business_hours.${index}.start_time`, { message: "Overlap" });
          });
          return;
        }
      }
      if (data.call_to_action_2 && data.call_to_action_2.length > 0 && data.call_to_action === "NONE") {
        setError("call_to_action", {
          message: "Required",
        });

        return;
      }
      setActiveStep((step) => step + 1);
    }
  };

  const onError: SubmitErrorHandler<AiAgentForm> = (errors) => {
    toast.error("Please review errors in the form");
  };

  const handleStepChange = useCallback((step: number) => step <= activeStep && setActiveStep(step), [activeStep]);

  const onCancel = () => {
    navigate(routes.aiVoice.agents.path);
  };

  return (
    <>
      <FullLoadingOverlay loading={loading} overlay={true} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography fontSize="24px" fontWeight="bold">
          Create Agent
        </Typography>

        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
      <Stack
        direction="column"
        style={{
          background: "#fff",
          padding: "20px",
        }}
      >
        <Box>
          <Stepper
            onStepChange={handleStepChange}
            steps={[
              { idx: 0, label: "Agent Type" },
              { idx: 1, label: "Agent" },
              { idx: 2, label: "Call Flow" },
              { idx: 3, label: "Overview" },
            ]}
            activeStep={activeStep}
          />
        </Box>

        <FormProvider {...form}>
          <Stack
            direction="column"
            style={{
              background: "#fff",
              padding: "42px 24px",
              maxWidth: activeStep === 2 ? "694px" : "544px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            {activeStep === 0 && <AgentTypeStep />}
            {activeStep === 1 && <AgentStep />}
            {activeStep === 2 && <CallFlowStep />}
            {activeStep === 3 && <AgentSummaryStep />}
          </Stack>

          <Divider style={{ margin: "20px 0px" }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: activeStep === 0 ? "flex-end" : "space-between",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            {activeStep > 0 && (
              <Button
                leftIcon={<ArrowLeftOutline size={16} style={{ marginRight: "8px" }} />}
                variant="secondary"
                type="button"
                onClick={goToPreviousStep}
              >
                Previous
              </Button>
            )}
            {activeStep < 3 ? (
              <Button
                rightIcon={<ArrowRightOutline size={16} style={{ marginLeft: "8px" }} />}
                variant="primary"
                onClick={handleSubmit(onSubmit, onError)}
              >
                Next
              </Button>
            ) : (
              <Button disabled={loading} variant="primary" type="submit" onClick={handleSubmit(onSubmit, onError)}>
                {params.id ? "Update" : "Create"} Agent
                <ArrowRightOutline size={16} style={{ marginLeft: "8px" }} />
              </Button>
            )}
          </Box>
        </FormProvider>
      </Stack>
    </>
  );
}

export * from "./ai-agent-schema";
export * from "./utils";
