import Services from "@/services";
import { KnVoiceTeamSummary } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";

interface VoiceSummary {
  totalAgents: number;
  totalCallsAnswered: number;
  totalCallsTransferred: number;
  avgCallDuration: number;
  totalCallsMade: number;
  totalMeetingsBooked: number;
  totalInboundCalls: number;
  totalOutboundCalls: number;
}

type State = {
  loadingVoiceSummary: boolean;
  voiceSummary: VoiceSummary | null;
};

const initialState: State = {
  loadingVoiceSummary: true,
  voiceSummary: null,
};

const toCamelCase = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    acc[camelKey] = obj[key];
    return acc;
  }, {} as Record<string, any>);
};

export const getVoiceSummary = createAsyncThunk<
  AxiosResponse<KnVoiceTeamSummary, any>,
  string,
  { rejectValue: string }
>("settings/getSummary", async (teamId, { rejectWithValue }) => {
  try {
    const response = await Services.AiVoice.voiceTeamSummary(teamId);

    return response;
  } catch (error) {
    return rejectWithValue(axios.isAxiosError(error) && error.response ? error.response.data : error.message);
  }
});

const summary = createSlice({
  name: "listCalls",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getVoiceSummary.pending, (state) => {
        state.loadingVoiceSummary = true;
      })
      .addCase(getVoiceSummary.fulfilled, (state, { payload }) => {
        state.voiceSummary = toCamelCase(payload.data) as VoiceSummary;
        state.loadingVoiceSummary = false;
      })
      .addCase(getVoiceSummary.rejected, (state, { payload }) => {
        state.loadingVoiceSummary = false;
      });
  },
  reducers: {},
});

export default summary.reducer;
