import Drawer from "@mui/material/Drawer";
import { useAppDispatch } from "@/redux/store";
import DrawerLayout from "@/layouts/DrawerLayout";
import { Box } from "@mui/material";
import { useAppSelector } from "@/redux/store";
import { closeViewDrawer } from "@/redux/reducers/dataImport/slices/list";
import { KnFirestoreImportJob, KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import { LeadSourceContactInfo } from "./lead-source-contact-info";
import LeadSourceGeneralInfo from "./lead-source-info/LeadSourceGeneralInfo";

interface KnFirestoreImportJobWithId extends KnFirestoreImportJob {
  id: string;
}

export default function ViewLeadSourceDrawer() {
  const currentLeadSource = useAppSelector((state) => state.dataImport.list.currentLeadSource);

  const dispatch = useAppDispatch();
  const viewDrawerIsOpen = useAppSelector(({ dataImport }) => dataImport.list.viewDrawerIsOpen);
  const toggleDrawer = () => dispatch(closeViewDrawer({}));

  const jobType = currentLeadSource?.importJobType;
  const leadSourceId = (currentLeadSource as KnFirestoreImportJobWithId)?.id;

  return (
    <Drawer open={viewDrawerIsOpen} onClose={toggleDrawer} anchor="right">
      <DrawerLayout customWidth="591px" title="Data Import Details" testid="viewLeadSource" closeDrawer={toggleDrawer}>
        <Box>
          {jobType === KnFirestoreImportJobImportJobTypeEnum.Contact ? (
            <LeadSourceContactInfo leadSourceId={leadSourceId} />
          ) : (
            <LeadSourceGeneralInfo />
          )}
        </Box>
      </DrawerLayout>
    </Drawer>
  );
}
