import { TextEditor } from "@/common/components/text-editor/TextEditor";
import toast from "@/lib/toast";
import { createInboxSms } from "@/redux/reducers/inbox/slices/createSms";
import { genMsgCustom, genMsgSuggestMeetingTimes } from "@/redux/reducers/inbox/slices/generateMessage";
import { getSmsCurrentInbox } from "@/redux/reducers/inbox/slices/getCurrentInbox";
import { closeSendNewMessageDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import { getProspects } from "@/redux/reducers/prospects/slices/listProspects";
import { getTeamUsers } from "@/redux/reducers/settings/team/get";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { GenMsgCustomMessageTypeEnum, SmsDTOActionEnum } from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import ReactQuill from "react-quill-new";
import * as Yup from "yup";
import AiForm from "../../AiForm";
import { ActionButtons } from "../ActionButtons";
import SmsMessages from "../SmsMessages";

const schema = Yup.object({
  message: Yup.string().label("Message").required(),
  // TODO require on messageGen
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  userIds: Yup.array().of(Yup.string().required()),
  prompt: Yup.string().label("Prompt"),
  // Validate array of File
  attachments: Yup.array().of(Yup.mixed<File>().required()),
});

export type SmsFormType = Yup.InferType<typeof schema>;

const getMediaType = (file: File) => {
  const imageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp", "image/svg+xml"];
  const videoTypes = ["video/mp4", "video/webm", "video/ogg", "video/quicktime"];

  if (imageTypes.includes(file.type)) {
    return "image";
  } else if (videoTypes.includes(file.type)) {
    return "video";
  }

  return null;
};

interface AttachmentPreview {
  file: File;
  preview: string;
  type?: "video" | "image";
}

export const SmsTab = ({ newMessage = false }: { newMessage?: boolean }) => {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);
  const [isShowGenAi, setIsShowGenAi] = useState(false);
  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const [attachmentPreviews, setAttachmentPreviews] = useState<AttachmentPreview[]>([]);
  const quillRef = useRef<ReactQuill | null>(null);

  const quillRefCallBack = useCallback((quill: ReactQuill | null) => {
    if (quill) {
      quillRef.current = quill;
    }
  }, []);

  const users = useAppSelector(({ settings }) => settings.team.users);

  const methods = useForm<SmsFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      message: "",
    },
  });
  const { control, reset, watch } = methods;

  useEffect(() => {
    if (!prospects) {
      // TODO I think this should be prospects inbox?
      dispatch(getProspects());
    }
  }, [prospects]);

  useEffect(() => {
    if (currentProspect) {
      dispatch(getSmsCurrentInbox({ prospectId: currentProspect?.id }));
    }
  }, [currentProspect, dispatch]);

  useEffect(() => {
    if (!users?.length) {
      dispatch(getTeamUsers());
    }
  }, [dispatch]);

  const usersWithCal = useMemo(() => {
    return (users || []).filter((u) => u.calendarConnected);
  }, [users]);

  const generateMessage = async (e: Event) => {
    e.preventDefault();

    const values = methods.getValues();

    try {
      let response: any;

      if (values.type.value === "suggest_meeting_times") {
        response = await dispatch(
          genMsgSuggestMeetingTimes({
            message_type: GenMsgCustomMessageTypeEnum.Sms,
            meet_with_user_id_list: values.userIds || [],
          }),
        ).unwrap();
      } else {
        response = await dispatch(
          genMsgCustom({
            message_type: GenMsgCustomMessageTypeEnum.Sms,
            prompt: values.prompt || "",
          }),
        ).unwrap();
      }

      if (response.data.message) {
        setIsShowGenAi(false);

        methods.setValue("message", response.data.message);
      }
    } catch (error) {
      toast.error("Something went wront!");
    }
  };

  const onSubmit: SubmitHandler<SmsFormType> = (data) => {
    if (isShowGenAi) {
      return;
    }

    dispatch(
      createInboxSms({
        team_id: user?.team_id || 0,
        from_user_id: user?.sub || "000",
        prospect_id: currentProspect?.id || "",
        action: SmsDTOActionEnum.Send,
        sequence_id: "",
        body: data.message,
      }),
    ).then(() => {
      if (user?.sub) {
        // TODO fix
        // dispatch(pageMessageThreads(user?.sub));
      }
      if (!newMessage) {
        dispatch(getSmsCurrentInbox({ prospectId: currentProspect?.id, sortBy: "createdAt", sortDirection: "asc" }));
      }
      dispatch(closeSendNewMessageDrawer());

      reset();
    });
  };

  const onError: SubmitErrorHandler<Yup.InferType<typeof schema>> = (errors) => {
    toast.error("Something went wrong!");
  };

  const attachments = watch("attachments");

  useEffect(() => {
    if (!attachments || attachments.length === 0) {
      setAttachmentPreviews([]);
      return;
    }

    const processAttachments = async () => {
      const updatedAttachments = [...attachmentPreviews];

      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];

        const type = getMediaType(attachment);

        if (type) {
          const preview = URL.createObjectURL(attachment);

          updatedAttachments[i] = { file: attachment, preview, type };
        }
      }

      setAttachmentPreviews(updatedAttachments.filter((a) => a.preview));
    };

    processAttachments();

    // Cleanup function to revoke object URLs on unmount
    return () => {
      attachmentPreviews.forEach((attachment) => {
        if (attachment.preview) {
          URL.revokeObjectURL(attachment.preview);
        }
      });
    };
  }, [attachments]);

  const insertEmoji = (emoji: string) => {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);

    editor.insertText(range.index, emoji);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 164px)",
        justifyContent: "space-between",
      }}
    >
      {!newMessage ? <SmsMessages /> : <Box />}
      <FormProvider {...methods}>
        <form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              py: 2,
            }}
          >
            {attachmentPreviews.length > 0 && (
              <Grid container spacing={2} sx={{ mb: 3 }}>
                {attachmentPreviews.map((attachment, index) => {
                  const attachmentIndex = attachmentPreviews.findIndex((a) => a.preview === attachment.preview);

                  return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card>
                        {attachment.type === "image" ? (
                          <CardMedia
                            component="img"
                            height="140"
                            image={attachment.preview}
                            alt={`Attachment ${index + 1}`}
                          />
                        ) : (
                          <Box sx={{ position: "relative", height: 140 }}>
                            <video
                              src={attachment.preview}
                              controls
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            >
                              <track kind="captions" />
                            </video>
                          </Box>
                        )}
                        <CardContent sx={{ py: 1 }}>
                          <Typography variant="body2" noWrap>
                            {attachment.file.name}
                          </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "flex-end", p: 1 }}>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => {
                              // TODO: Remove attachment
                            }}
                          >
                            <TrashIcon />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            {!isShowGenAi ? (
              <FormControl sx={{ flexGrow: 1, width: "100%" }}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextEditor
                        {...field}
                        error={fieldState.error?.message}
                        enableAttachments
                        insertEmoji={insertEmoji}
                        quillRef={quillRefCallBack}
                      />
                    );
                  }}
                />
              </FormControl>
            ) : (
              <AiForm
                users={usersWithCal}
                onClose={() => setIsShowGenAi(false)}
                onGenerate={(e) => generateMessage(e)}
              />
            )}

            {!isShowGenAi && (
              <ActionButtons
                sendText="Send SMS"
                onSubmit={onSubmit}
                onError={onError}
                setIsGenerating={setIsShowGenAi}
              />
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
