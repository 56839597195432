import TableCell from "@/common/components/table-cell";
import TableDate from "@/common/components/table-date";
import TableHeader from "@/common/components/table-header";
import TableSortBy from "@/common/components/table-sort-by";
import { dateTimeFormat } from "@/common/functions/dateFormat";
import { DataImportJob } from "@/types/data-import";
import { createColumnHelper } from "@tanstack/react-table";
import { DataImportFiltersProps } from "../filters";
import TableActionButton from "./ActionButton";
import DataImportChip from "./chips";
import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import { Box } from "@mui/material";

const columnHelper = createColumnHelper<DataImportJob>();

export const DataImportColumns = ({ filters, setFilters }: DataImportFiltersProps) => {
  const updateSort = () => {
    if (filters?.sortDirection === "asc") {
      setFilters((previousFilters) => ({ ...previousFilters, sortDirection: "desc" }));

      return;
    }

    setFilters((previousFilters) => ({ ...previousFilters, sortDirection: "asc" }));
  };

  return [
    columnHelper.accessor("importJobName", {
      header: () => <TableHeader>Import Name</TableHeader>,
      cell: (info) => {
        return <TableCell>{info.getValue() || ""}</TableCell>;
      },
    }),

    columnHelper.accessor("importJobType", {
      header: () => <TableHeader>Object</TableHeader>,
      cell: (info) => <DataImportChip status={info.getValue() || ""} />,
    }),

    columnHelper.accessor("createdDocumentResultCount", {
      header: () => <TableHeader>New Records</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),

    columnHelper.accessor("updatedDocumentResultCount", {
      header: () => <TableHeader>Updated Records</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),

    columnHelper.accessor("errorDocumentResultCount", {
      header: () => <TableHeader>ERROR COUNT</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),

    columnHelper.accessor("createdAt", {
      header: () => (
        <TableHeader rightIcon={<TableSortBy sortDirection={filters?.sortDirection} onClick={updateSort} />}>
          Imported On
        </TableHeader>
      ),
      cell: (info) => {
        const seconds = info.getValue()?.seconds || 0;
        const nanos = info.getValue()?.nanos || 0;
        const formattedDate = dateTimeFormat(seconds, nanos);

        return <TableDate date={formattedDate} ignoreDateFormat={true} />;
      },
    }),

    columnHelper.display({
      id: "actions",
      header: () => <TableHeader sx={{ display: "flex", justifyContent: "center" }}>Actions</TableHeader>,
      cell: (info) => {
        const importJobType = info.row.original.importJobType;
        const disabled = importJobType === KnFirestoreImportJobImportJobTypeEnum.Product;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TableActionButton disabled={disabled} params={info} />
          </Box>
        );
      },
    }),
  ];
};
