import { ReactComponent as SquaresPlus } from "@/common/assets/svg/sidebar/squares-plus.svg";
import { ReactComponent as PencilSquare } from "@/common/assets/svg/tasks/edit.svg";
import { ReactComponent as Trash } from "@/common/assets/svg/tasks/trash.svg";
import MenuOptions from "@/common/components/menu-options/MenuOptions";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import {
  deleteProspect,
  openEditDrawer,
  setCurrentProspect,
  showSeqEnrollDialog,
  showSeqUnEnrollDialog,
} from "@/redux/reducers/prospects/slices/listProspects";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { useAppDispatch } from "@/redux/store";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Typography } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import * as React from "react";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

enum ProspectActionMenuOptionEnum {
  Edit = "edit",
  EnrollIntoSequence = "enroll_into_sequence",
  UnEnrollFromSequence = "un_enroll_from_sequence",
  Delete = "delete",
}

const options = [
  {
    label: "Edit Contact",
    value: ProspectActionMenuOptionEnum.Edit,
    icon: <PencilSquare style={iconStyles} />,
  },
  {
    label: "Enroll Into Sequence",
    value: ProspectActionMenuOptionEnum.EnrollIntoSequence,
    icon: <SquaresPlus style={iconStyles} />,
  },
  {
    label: "Un-enroll From Sequence",
    value: ProspectActionMenuOptionEnum.UnEnrollFromSequence,
    icon: <XCircleIcon style={iconStyles} />,
  },
  {
    label: "Delete Contact",
    value: ProspectActionMenuOptionEnum.Delete,
    icon: <Trash style={iconStyles} />,
  },
];

type ToggleActionButtonProps = {
  params: CellContext<Prospect, unknown>;
  canUnEnroll?: boolean;
};

export default function TableActionButton({ params, canUnEnroll = true }: ToggleActionButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { deleteConfirm } = useConfirmModal();

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        switch (option.value) {
          case ProspectActionMenuOptionEnum.UnEnrollFromSequence:
            dispatch(setCurrentProspect(params.row.original));
            dispatch(showSeqUnEnrollDialog());
            handleClose();
            break;

          case ProspectActionMenuOptionEnum.EnrollIntoSequence:
            params.row.toggleSelected(true);
            dispatch(showSeqEnrollDialog());
            handleClose();
            break;

          case ProspectActionMenuOptionEnum.Delete:
            deleteConfirm({
              textDiv: (
                <>
                  <Typography variant="body1">Are you sure you want to delete this contact?</Typography>
                  <Typography variant="body1">This action cannot be undone.</Typography>
                </>
              ),
            })
              .then(() => {
                dispatch(deleteProspect(params.row.original.id));
              })
              .catch(() => {
                return;
              });
            handleClose();
            break;

          default:
            dispatch(setCurrentProspect(params.row.original));
            dispatch(openEditDrawer());
            handleClose();
        }
      }}
    />
  );
}
