import { Box, Typography } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { ChevronLeft, ChevronRight } from "heroicons-react";
import Dropdown from "../dropdown/Dropdown";
interface HasId {
  id: string | number;
}

export interface ServerPaginationProps<T extends HasId> {
  tableLib: Table<T>;
  custom?: {
    hasPrev?: boolean;
    hasNext?: boolean;
    onNext?: () => void;
    onPrev?: () => void;
    onPageSizeChange?: (value: number) => void;
  };
}

export default function Pagination<T extends HasId>(props: ServerPaginationProps<T>) {
  const { tableLib, custom } = props;
  const totalCount = tableLib.getPageCount();
  const pageSize = tableLib.getState().pagination.pageSize;
  const pageCount = Math.ceil(totalCount / pageSize);
  const isLastPage = pageCount === tableLib.getState().pagination.pageIndex + 1;

  const handlePageSizeChange = (value: string) => {
    if (custom?.onPageSizeChange) custom.onPageSizeChange(parseInt(value, 10));
    tableLib.setPageSize(parseInt(value, 10));
  };

  const handlePageIndexChange = (value: any) => {
    tableLib.setPageIndex(parseInt(value, 10) - 1);
  };

  const canClickOnNextPage = (tableLib.getCanNextPage() && !isLastPage) || custom?.hasNext;
  const canClickOnPreviousPage = tableLib.getCanPreviousPage() || custom?.hasPrev;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
        background: "#fff",
        padding: "8px 16px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.default}`,
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography variant="body2">Display</Typography>

        <Dropdown
          label=""
          isClearable={false}
          options={[10, 25].map((pageSize) => ({
            label: pageSize.toString(),
            value: pageSize.toString(),
          }))}
          menuMinWidth="auto"
          onChange={({ value }) => handlePageSizeChange(value)}
          value={tableLib.getState().pagination.pageSize?.toString()}
        />
        {!custom && <Typography variant="body2">of {tableLib.getPageCount()} items</Typography>}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <ChevronLeft
          onClick={() => {
            if (!canClickOnPreviousPage) return;
            if (custom?.onPrev) return custom.onPrev();
            tableLib.previousPage();
          }}
          style={{
            cursor: canClickOnPreviousPage ? "pointer" : "not-allowed",
            fill: canClickOnPreviousPage ? "#1A2135" : "#D3D3D3",
          }}
        />
        {!custom && (
          <Dropdown
            label=""
            isClearable={false}
            options={Array.from({ length: pageCount }, (_, i) => i + 1).map((pageIndex) => ({
              label: pageIndex.toString(),
              value: pageIndex.toString(),
            }))}
            onChange={({ value }) => handlePageIndexChange(value)}
            value={
              (tableLib.getState().pagination.pageIndex + 1).toString() ||
              (tableLib.getState().pagination.pageIndex + 1).toString()
            }
          />
        )}

        <ChevronRight
          onClick={() => {
            if (!canClickOnNextPage) return;
            if (custom?.onNext) return custom.onNext();
            tableLib.nextPage();
          }}
          style={{
            cursor: canClickOnNextPage ? "pointer" : "not-allowed",
            fill: canClickOnNextPage ? "#1A2135" : "#D3D3D3",
          }}
        />
      </Box>
    </Box>
  );
}
