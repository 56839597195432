import { Loading } from "@kennected/components";
import { Box, Typography } from "@mui/material";
import LinearProgressBar from "../LinearProgressBar";

interface MinutesProgressCardProps {
  loading?: boolean;
  total: number | undefined;
  remaining: number | undefined;
  flag: string;
}

export default function TimeProgressCard({ loading, total, remaining, flag }: MinutesProgressCardProps) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexDirection: "column",
        width: "100%",
        minWidth: "280px",
        padding: "16px 24px",
        borderRadius: "8px",
        border: "1px #E7EBED solid",
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              <strong style={{ fontWeight: "600" }}>{flag}</strong> - {remaining ? remaining.toFixed(0) : 0} out of{" "}
              {total ? total.toFixed(0) : 0}
            </Typography>
          </Box>
          <LinearProgressBar value={remaining && total ? (remaining * 100) / total : 0} />
        </>
      )}
    </Box>
  );
}
