import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { labelAscComparator } from "@/common/functions/option";
import { teamUserOptionLabel } from "@/common/functions/teamUserOptionLabel";
import { useAppSelector } from "@/redux/store";
import { KnAiAgentBookingModeEnum } from "@/services/generated";
import { Box, FormControl } from "@mui/material";
import { useMemo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object().shape({
  booking_mode: yup.string().required("Booking Mode is required"),
  booking_with: yup.array().of(yup.string().required()).required("Booking With is required"),
});

export type AiAgentForm = yup.InferType<typeof schema>;

const BOOKING_MODE_OPTIONS = [
  {
    label: "Round Robin",
    value: KnAiAgentBookingModeEnum.RoundRobin,
  },
  {
    label: "First Available",
    value: "ONE_TO_ONE",
  },
];

type BookingWithOption = {
  label: string;
  value: string;
};

export default function BookMeetingForm() {
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const { control, watch, setValue } = useFormContext<AiAgentForm>();

  const bookingWith = watch("booking_with", []);
  const bookingMode = watch("booking_mode", "ONE_TO_ONE");

  const bookingWithOptions: BookingWithOption[] = useMemo(() => {
    return (teamUsers || [])
      .filter((u) => u.calendarConnected)
      .map((u) => ({
        label: teamUserOptionLabel(u),
        value: u.user_id!,
      }))
      .sort(labelAscComparator);
  }, [teamUsers]);

  useEffect(() => {
    if (bookingWith.length === 1) {
      setValue("booking_mode", "ONE_TO_ONE");
    }
  }, [bookingWith, setValue]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {}
        <FormControl>
          <Controller
            name="booking_with"
            control={control}
            render={({ field, fieldState }) => {
              const safeValue = Array.isArray(field.value) ? field.value.filter((id): id is string => !!id) : [];

              return (
                <>
                  <FormLabel label="Booking With" aria-required />
                  <Dropdown
                    isMulti
                    value={safeValue}
                    options={bookingWithOptions}
                    ref={field.ref}
                    onChange={(selected) => {
                      const newValue = (selected || [])
                        .map((option: BookingWithOption) => option.value)
                        .filter((val: any): val is string => !!val);

                      field.onChange(newValue);
                    }}
                    error={fieldState.error?.message}
                  />
                </>
              );
            }}
          />
        </FormControl>

        {}
        {bookingWith.length > 1 && (
          <FormControl>
            <Controller
              name="booking_mode"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel label="Booking Mode" aria-required />
                  <Dropdown
                    options={BOOKING_MODE_OPTIONS}
                    value={field.value}
                    onChange={(option) => field.onChange(option.value)}
                    error={fieldState.error?.message}
                  />
                </>
              )}
            />
          </FormControl>
        )}
      </Box>
    </Box>
  );
}
