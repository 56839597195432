import createCheckboxCol from "@/common/functions/createCheckboxCol";
import { SequenceTemplate } from "@/types/sequence";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../table-cell";
import TableHeader from "../table-header";

const columnHelper = createColumnHelper<SequenceTemplate>();

export const SequenceColumns = [
  createCheckboxCol(columnHelper),

  columnHelper.accessor("name", {
    header: () => <TableHeader>Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("prospectEnrolled", {
    header: () => <TableHeader>Contact Enrolled</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),
];
