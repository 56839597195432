import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";

export const Roles = {
  [KnTeamUserRoleUserRoleEnum.SalesManager]: {
    roleName: "Sales Manager",
    roleCode: KnTeamUserRoleUserRoleEnum.SalesManager,
    roleDescription: "Main SalesAi admin. Add user, set global settings.",
  },
  [KnTeamUserRoleUserRoleEnum.SalesRepresentative]: {
    roleName: "Sales Representative",
    roleCode: KnTeamUserRoleUserRoleEnum.SalesRepresentative,
    roleDescription: "Sales administrative user: Can edit personal settings",
  },
  [KnTeamUserRoleUserRoleEnum.Admin]: {
    roleName: "Account Administrator",
    roleCode: KnTeamUserRoleUserRoleEnum.Admin,
    roleDescription: "Account administrator: Can edit team settings",
  },
  [KnTeamUserRoleUserRoleEnum.Superadmin]: {
    roleName: "Super Administrator",
    roleCode: KnTeamUserRoleUserRoleEnum.Superadmin,
    roleDescription: "Account super administrator: Can modify account administrators",
  },
};

export const RolesMap = {
  [KnTeamUserRoleUserRoleEnum.SalesManager]: Roles[KnTeamUserRoleUserRoleEnum.SalesManager].roleName,
  [KnTeamUserRoleUserRoleEnum.SalesRepresentative]: Roles[KnTeamUserRoleUserRoleEnum.SalesRepresentative].roleName,
  [KnTeamUserRoleUserRoleEnum.Admin]: Roles[KnTeamUserRoleUserRoleEnum.Admin].roleName,
  [KnTeamUserRoleUserRoleEnum.Superadmin]: Roles[KnTeamUserRoleUserRoleEnum.Superadmin].roleName,
};
