import Dropdown from "@/common/components/dropdown";
import { searchWebhook } from "@/redux/reducers/settings/company/crm/webhook/search";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnContactLeadSourceEnum } from "@/types/enums";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Box, Typography } from "@mui/material";
import { CheckCircleOutline, ExclamationCircleOutline } from "heroicons-react";
import React, { useEffect, useState } from "react";
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from "react-hook-form";

import toast from "@/lib/toast";

const LEAD_SOURCE_OPTIONS = [
  {
    label: "Manual",
    value: KnContactLeadSourceEnum.Ui,
  },
  {
    label: "Webhook",
    value: KnContactLeadSourceEnum.InboundWebhook,
  },
];

export const ContactCreatedForm = () => {
  const methods = useFormContext();
  const { control, setValue } = methods;
  const { webhookList } = useAppSelector((state) => state.settings.webhookReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchAllWebhooks = async () => {
      const page = 0;
      let size = 10;
      while (true) {
        const response = await dispatch(searchWebhook({ pageNumber: page, pageSize: size })).unwrap();
        if (!response.data.hasNext) break;
        const total = response.data.total;
        size = total ? total : 10;
      }
    };

    fetchAllWebhooks();
  }, [dispatch]);

  const RenderOption = ({
    field,
    option,
    checked,
  }: {
    field: ControllerRenderProps<FieldValues, "event.leadSource">;
    option: { label: string; value: string | null };
    checked?: boolean;
  }) => {
    const handleSelectLeadSource = (leadSource: string | null) => {
      field.onChange(option.value);
      if (leadSource !== KnContactLeadSourceEnum.InboundWebhook) {
        setValue("webhookId", "");
      }
    };

    return (
      <label
        style={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "8px",
          padding: "10px",
          border: `${checked ? `2px solid #1C73C1` : "1px solid #E0E0E0"}`,
        }}
      >
        <Typography variant="body2">{option.label}</Typography>
        <input type="radio" checked={checked} onChange={() => handleSelectLeadSource(option.value)} />
      </label>
    );
  };

  const WebhookDropdown = () => {
    type LocalWebhookType = {
      label: string;
      value: string;
    };

    const [localWebhookList, setLocalWebhookList] = useState<LocalWebhookType[]>([]);

    const handleCopyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text);
      toast.success("Webhook URL Copied!", {
        icon: <CheckCircleOutline />,
        style: {
          backgroundColor: "#b4dfff",
          display: "flex",
          alignItems: "center",
          minHeight: "40px",
        },
      });
    };

    const handleGetWebhookId = (fullWebhook: string): string => {
      const webhookId = fullWebhook.split("/").pop() || "";
      return webhookId;
    };

    const handleDisplayCurrectSelectedWebhook = (webhookId: string): string => {
      if (webhookList && webhookList.length > 0) {
        const foundWebhook = webhookList.find((item) => handleGetWebhookId(item.url) === webhookId);
        return foundWebhook?.url ? foundWebhook?.url : "";
      }
      return "";
    };

    useEffect(() => {
      if (webhookList && webhookList.length > 0) {
        const updateWebhookList = webhookList.map((listItem) => ({
          label: listItem.name,
          value: handleGetWebhookId(listItem.url),
        }));
        setLocalWebhookList(updateWebhookList);
      }
    }, [webhookList]);

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Controller
          name="webhookId"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const value: string = field.value;
            const showWebhookURL = value && value.length > 0;

            return (
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                      <Typography variant="body2">Webhook Name</Typography>
                      <ExclamationCircleOutline
                        style={{
                          width: "15px",
                          height: "15px",
                          stroke: "#9fa9c0",
                        }}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ color: "#6b747b" }}>
                      Required
                    </Typography>
                  </Box>
                  <Dropdown
                    placeholder="Select a Webhook"
                    value={value}
                    options={localWebhookList}
                    menuMaxHeight="200px"
                    menuMaxWidth="100%"
                    onChange={(option) => field.onChange(option.value)}
                  />
                </Box>

                {showWebhookURL ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant="body2">{handleDisplayCurrectSelectedWebhook(field.value)}</Typography>
                    <ClipboardDocumentIcon
                      width="20"
                      height="20"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCopyToClipboard(handleDisplayCurrectSelectedWebhook(field.value))}
                    />
                  </Box>
                ) : undefined}
              </Box>
            );
          }}
        />
      </Box>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Controller
        name="event.leadSource"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "8px" }}>
            {LEAD_SOURCE_OPTIONS.map((option) => {
              const checked = field.value === option.value;
              const showWebhookDropdown = checked && field.value === KnContactLeadSourceEnum.InboundWebhook;

              return (
                <React.Fragment key={option.value}>
                  <RenderOption field={field} option={option} checked={checked} />
                  {showWebhookDropdown ? <WebhookDropdown /> : undefined}
                </React.Fragment>
              );
            })}
          </div>
        )}
      />
    </div>
  );
};
