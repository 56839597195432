import DealsHeader from "@/common/components/deals-card/DealsHeader";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import Tooltip from "@/common/components/tool-tip/Tooltip";
import { getLeadReports } from "@/redux/selectors";
import { useAppSelector } from "@/redux/store";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { flatMap, sum } from "lodash";
import React, { useMemo } from "react";

const SIZE = 96;
const GAP = 6;
const getStartOfDay = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

const Heatmap = () => {
  const theme = useTheme();
  const leadFlowReports = useAppSelector(getLeadReports);

  const heatmapData = useMemo(() => {
    if (!leadFlowReports) {
      return { counts: [[]], percentages: [[]] };
    }
    const today = getStartOfDay(new Date());

    const data = [0, 1, 2, 3, 4, 5, 6].map((daysAgo) => {
      const reports = leadFlowReports.filter((report) => {
        const updatedAt = getStartOfDay(new Date(report.updatedAt));
        const diffDays = Math.floor((today.getTime() - updatedAt.getTime()) / (1000 * 60 * 60 * 24));
        return diffDays === daysAgo;
      });

      const groups = Array(8).fill(0);

      reports.forEach((report) => {
        const ratio = report.total !== 0 ? report.valid / report.total : 0;
        const validCount = Math.min(Math.floor(ratio * 8), 7);
        groups[validCount] += 1;
      });

      return groups;
    });

    const highestCount = Math.max(...data.flat());

    const percentages = data.map((row) =>
      row.map((value) => {
        if (highestCount === 0) {
          return 0;
        } else {
          const percent = value / highestCount;
          if (percent === 0) {
            return 0;
          } else if (percent <= 0.5) {
            return 0.5;
          } else if (percent <= 0.6) {
            return 0.6;
          } else if (percent <= 0.7) {
            return 0.7;
          } else if (percent <= 0.8) {
            return 0.8;
          } else {
            return 1.0;
          }
        }
      }),
    );

    return { counts: data, percentages };
  }, [leadFlowReports]);

  const { counts, percentages } = heatmapData;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.midnight[20],
        border: "1px solid #E7EBED",
        borderRadius: 2,
        padding: "4px",
        width: leadFlowReports === undefined ? "100%" : "initial",
      }}
    >
      <DealsHeader title="Qualifying Chart" color={theme.palette.midnight.p} count={sum(flatMap(counts))} />
      {leadFlowReports === undefined ? (
        <LoadingSpinner loading />
      ) : (
        <Grid
          container
          direction="column"
          sx={{
            display: "grid",
            gridTemplateColumns: `auto repeat(8, ${SIZE}px)`,
            gridTemplateRows: `auto repeat(6, ${SIZE}px)`,
            width: `calc(8 * ${SIZE} + 14px)`,
            gap: `${GAP}px`,
          }}
        >
          {/* Top Header */}
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body1">day/%</Typography>
          </Grid>
          {/* Side Headers */}
          {Array(8)
            .fill(1)
            .map((_, index) => (
              <Grid key={index} item sx={{ textAlign: "center", border: "1px solid #E7EBED" }}>
                {index + 1}
              </Grid>
            ))}
          {percentages.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  border: "1px solid #E7EBED",
                }}
              >
                {rowIndex + 1}
              </Box>
              {row.map((value, colIndex) => (
                <Grid key={`${rowIndex}-${colIndex}`} item>
                  <Tooltip title={`${counts[rowIndex][colIndex]} contacts`}>
                    <Box
                      sx={{
                        bgcolor: `rgba(94,207,129, ${value})`, // set the background color opacity based on the percentage
                        width: "100%",
                        aspectRatio: "1",
                        border: "1px solid #E7EBED",
                      }}
                    />
                  </Tooltip>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Heatmap;
