import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { dateFormatLong } from "@/common/functions/dateFormat";
import { SequenceContacts } from "@/types/sequence";
import { createColumnHelper } from "@tanstack/react-table";
import { ContactStatusBadge } from "./ContactsStatusBadge";

const columnHelper = createColumnHelper<SequenceContacts>();

export const ContactsColumns = [
  columnHelper.accessor("firstName", {
    header: () => <TableHeader>First Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("lastName", {
    header: () => <TableHeader>Last Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("enrollmentDate", {
    header: () => <TableHeader>Enrollment Date</TableHeader>,
    cell: (info) => {
      const enrollmentDate = info.getValue() || "";
      return <TableCell>{dateFormatLong(enrollmentDate)}</TableCell>;
    },
  }),

  columnHelper.accessor("status", {
    header: () => <TableHeader>Status</TableHeader>,
    cell: (info) => {
      const status = info.getValue();
      return <ContactStatusBadge status={status} />;
    },
  }),
];
