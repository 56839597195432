import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { KnSlider } from "@/common/components/slider/Slider";
import { clearPhoneNumbers, listPhoneNumbers, listVoices } from "@/redux/reducers/ai-voice/upsertAgent";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PhoneNumberOption } from "./agent-step.utils";
import { ArrowDown } from "heroicons-react";
import { phoneNumberArrowIconStyle } from "./agent-step.styles";
import { AiAgentForm } from "../..";
import { VOICE_LANGUAGE_OPTIONS } from "../..";
import VoicesGallery from "../voices-gallery";
import { getFormatedPhoneNumberList, getSortedPhoneNumberDropdownOptions } from "./agent-step.utils";
import { useLocation } from "react-router";

const INTERRUPT_TOOLTIP = `Controls the agent's sensitivity to interruptions (background noise, coughs, um/ah, etc.).
\nRecommended setting: 2 to keep the conversation going.`;

const CREATIVITY_TOOLTIP = `Controls how much the agent can deviate from the script/prompt.
\nRecommended setting: 2 so that the agent will be personal and follow your script closely.`;

const SLIDER_MARKS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

export default function AgentStep() {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    control,
    watch,
    getValues,
    formState: { defaultValues },
  } = useFormContext<AiAgentForm>();
  const dispatch = useAppDispatch();
  const phones = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.phones);
  const voices = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.voices);
  const isLoadingPhones = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingPhones);
  const isLoadingVoices = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingVoices);
  const [phoneNumbersPagination, setPhoneNumbersPagination] = useState({
    page: 0,
    pageSize: 100,
  });
  const callDirection = getValues("call_direction");
  const language = watch("language");
  const [isPhoneSortOrderDesc, setIsPhoneSortOrderDesc] = useState(false);
  const [phoneNumberDropdownOptions, setPhoneNumberDropdownOptions] = useState<PhoneNumberOption[]>([]);
  const rawPhoneNumberList = useRef<PhoneNumberOption[]>([]);
  const location = useLocation();

  useEffect(() => {
    const refreshPhoneNumbers = () => {
      dispatch(clearPhoneNumbers());
      rawPhoneNumberList.current = [];
      setPhoneNumberDropdownOptions([]);
    };
    refreshPhoneNumbers();
  }, [dispatch, location.pathname]);

  useEffect(() => {
    rawPhoneNumberList.current = getFormatedPhoneNumberList(callDirection, phones);
  }, [callDirection, phones]);

  useEffect(() => {
    setPhoneNumberDropdownOptions(
      getSortedPhoneNumberDropdownOptions(rawPhoneNumberList.current, isPhoneSortOrderDesc),
    );
  }, [rawPhoneNumberList.current, isPhoneSortOrderDesc]);

  useEffect(() => {
    if ((phones?.nextPageUri || !phones?.list) && !isLoadingPhones)
      dispatch(listPhoneNumbers({ pageNumber: 0, pageSize: phoneNumbersPagination.pageSize }));
  }, [dispatch, phones?.list, phoneNumbersPagination.pageSize, phones?.nextPageUri]);

  useEffect(() => {
    if (!voices) {
      dispatch(listVoices());
    }
  }, [dispatch, voices]);

  const onMenuScrollToBottom = () => {
    setPhoneNumbersPagination((prev) => ({ ...prev, pageSize: prev.pageSize + 10 }));
  };

  function changePhoneSortOrder() {
    setIsPhoneSortOrderDesc(!isPhoneSortOrderDesc);
  }

  if (isLoadingVoices) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Controller
        name="agent_label"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Internal Agent Label" aria-required />
            <Input value={field.value} onChange={field.onChange} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Box
        sx={{
          display: "flex",
          gap: 4,
          my: 4,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Controller
            name="language"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }}>
                <FormLabel label={t("agent.language")} aria-required />
                <Dropdown
                  {...field}
                  options={VOICE_LANGUAGE_OPTIONS}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Controller
            name="agent_phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ flex: "1" }}>
                    <FormLabel label={t("agent.phoneNumber")} aria-required />
                    <Dropdown
                      {...field}
                      onMenuScrollToBottom={onMenuScrollToBottom}
                      isSearchable={true}
                      isPending={isLoadingPhones}
                      isDisabled={phoneNumberDropdownOptions.length == 0}
                      options={phoneNumberDropdownOptions}
                      value={field.value}
                      onChange={(o) => field.onChange(o.value)}
                      error={fieldState.error?.message}
                    />
                  </Box>
                  <Tooltip title="Click to toggle number list sorting order" placement="right">
                    <ArrowDown
                      onClick={() => changePhoneSortOrder()}
                      size={18}
                      style={
                        {
                          ...phoneNumberArrowIconStyle,
                          top: fieldState.error?.message ? "-32px" : "-12px",
                          transform: isPhoneSortOrderDesc ? "rotate(0deg)" : "rotate(180deg)",
                        } as CSSProperties
                      }
                    />
                  </Tooltip>
                </Box>
              </FormControl>
            )}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Controller
          name="voice_id"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("agent.voiceAgent")} />
              <VoicesGallery
                error={fieldState.error?.message}
                voiceId={field.value ? field.value : defaultValues?.voice_id}
                onSelect={(voiceId) => field.onChange(voiceId)}
                language={language}
              />
            </FormControl>
          )}
        />

        <Controller
          name="interrupt"
          control={control}
          render={({ field, fieldState }) => (
            <Tooltip title={INTERRUPT_TOOLTIP} placement="right">
              <FormControl>
                <FormLabel label={t("agent.interruptSensitivity")} aria-required />
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.border.default}`,
                    borderRadius: 2,
                    pt: 2,
                    px: 3,
                  }}
                >
                  <KnSlider
                    value={field.value}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    min={1}
                    max={5}
                    step={1}
                    marks={SLIDER_MARKS}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      py: 0.5,
                      px: 1,
                      bgcolor: theme.palette.midnight[20],
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.commonColors.text,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      Less Sensitive
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 0.5,
                      px: 1,
                      bgcolor: theme.palette.midnight[20],
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.commonColors.text,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      More Sensitive
                    </Typography>
                  </Box>
                </Box>
                {fieldState.error && <Box sx={{ color: "error.main" }}>{fieldState.error.message}</Box>}
              </FormControl>
            </Tooltip>
          )}
        />

        <Controller
          name="creativity"
          control={control}
          render={({ field, fieldState }) => (
            <Tooltip title={CREATIVITY_TOOLTIP} placement="right">
              <FormControl>
                <FormLabel label={t("agent.aiCreativity")} aria-required />
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.border.default}`,
                    borderRadius: 2,
                    pt: 2,
                    px: 3,
                  }}
                >
                  <KnSlider
                    value={field.value}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    min={1}
                    max={5}
                    step={1}
                    marks={SLIDER_MARKS}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      py: 0.5,
                      px: 1,
                      bgcolor: theme.palette.midnight[20],
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.commonColors.text,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      Less Creative
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 0.5,
                      px: 1,
                      bgcolor: theme.palette.midnight[20],
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.commonColors.text,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      More Creative
                    </Typography>
                  </Box>
                </Box>

                {fieldState.error && <Box sx={{ color: "error.main" }}>{fieldState.error.message}</Box>}
              </FormControl>
            </Tooltip>
          )}
        />
      </Box>
    </>
  );
}
