import { Box, IconButton, Menu, useTheme } from "@mui/material";
import { PaperClipOutline } from "heroicons-react";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import { VariableEmbed } from "./modules/VariableEmbed";
import EmojiPicker from "emoji-picker-react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  handleDropInterval?: (index: number) => void;
  enableAttachments?: boolean;
  quillRef?: (quill: ReactQuill | null) => void;
  insertEmoji?: (emoji: string) => void;
  error?: string;
  hideToolbar?: boolean;
  disabled?: boolean;
}

Quill.register(VariableEmbed);

export const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  enableAttachments,
  quillRef,
  error,
  insertEmoji,
  hideToolbar = false,
  disabled = false,
}) => {
  const theme = useTheme();

  const handleChanges = (content: string) => {
    onChange(content);
  };

  const { setValue, getValues } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const openEmojiMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const closeEmojiMenu = () => {
    setAnchorEl(null);
  };

  const FileUploader: React.FC = () => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
      const attachmentsValue = getValues("attachments") || [];

      setValue("attachments", [...attachmentsValue, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Box {...getRootProps()} style={{ cursor: "pointer" }}>
        <input {...getInputProps()} />
        <PaperClipOutline
          style={{
            width: "24px",
            height: "17px",
            position: "absolute",
            bottom: 12,
            stroke: "#374667",
          }}
        />
      </Box>
    );
  };

  const modules = useMemo(() => {
    return {
      toolbar:
        !hideToolbar && !disabled
          ? {
              disabled,
              container: "#toolbar",
            }
          : false,
      clipboard: {
        matchVisual: true,
      },
    };
  }, [disabled, hideToolbar]);

  const formats = ["header", "bold", "italic", "underline", "strike", "list", "variableEmbed"];

  return (
    <>
      <div
        style={{
          borderRadius: "8px",
          border: "1px solid #E7EBED",
        }}
      >
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={handleChanges}
          formats={formats}
          modules={modules}
          style={{
            height: "150px",
            border: error ? "1px solid" : "none",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderColor: error ? theme.palette.error.main : "transparent",
          }}
          readOnly={disabled}
        />
        <Box id="toolbar">
          {!hideToolbar && !disabled && (
            <>
              <select className="ql-header" defaultValue={""} onChange={(e) => e.persist()}>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option value="4">Heading 4</option>
                <option value="">Normal</option>
              </select>
              <button className="ql-bold" />
              <button className="ql-italic" />
              <button className="ql-underline" />
              <button className="ql-strike" />
              <button className="ql-list" value="bullet" />
              <button className="ql-list" value="ordered" />
              {enableAttachments && <FileUploader />}
              {insertEmoji && (
                <span className="ql-formats">
                  <IconButton
                    onClick={openEmojiMenu}
                    type="button"
                    size="small"
                    disableRipple={true}
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                    sx={{
                      marginTop: "-4px",
                      marginLeft: "20px",
                    }}
                  >
                    <FaceSmileIcon fontSize="small" />
                  </IconButton>
                </span>
              )}
            </>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeEmojiMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <EmojiPicker
            onEmojiClick={(emojiData) => {
              insertEmoji?.(emojiData.emoji);

              closeEmojiMenu();
            }}
          />
        </Menu>
      </div>
    </>
  );
};
