import DrawerLayout from "@/layouts/DrawerLayout";
import { closeViewDrawer } from "@/redux/reducers/admin/teams/slices/getTeams";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Drawer from "@mui/material/Drawer";
import { FormProvider, useForm } from "react-hook-form";
import TeamInfo from "./TeamInfo";
import { resetSelectedUser } from "@/redux/reducers/admin/users/slices/reset-passoword";

export default function ViewTeamDrawer() {
  const dispatch = useAppDispatch();
  const { viewDrawerOpen, selectedTeam } = useAppSelector(({ teams }) => teams.getTeams);
  const methods = useForm();

  const toggleDrawer = () => {
    dispatch(closeViewDrawer());
    dispatch(resetSelectedUser());
  };

  return (
    <FormProvider {...methods}>
      <Drawer
        open={viewDrawerOpen}
        onClose={() => {
          dispatch(closeViewDrawer());
          dispatch(resetSelectedUser());
        }}
        anchor="right"
      >
        <DrawerLayout
          customWidth="1208px"
          title={selectedTeam?.name ?? ""}
          closeDrawer={toggleDrawer}
          sx={{
            maxWidth: "1208px",
            width: "100%",
          }}
        >
          <TeamInfo teamId={selectedTeam?.id ?? ""} />
        </DrawerLayout>
      </Drawer>
    </FormProvider>
  );
}
