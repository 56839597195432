import TsTable from "@/common/components/ts-table";
import { getSequencesByContact } from "@/redux/reducers/sequences/slices/listSequences";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../button";
import SearchBar from "../searchbar/Searchbar";
import { Columns } from "./columns";

interface SeqUnEnrollDialogProps {
  handleClose: () => void;
  handleSubmit: (sequenceId: string) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SeqUnEnrollDialog({ handleClose, handleSubmit }: SeqUnEnrollDialogProps) {
  const dispatch = useAppDispatch();

  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const prospectSequences = useAppSelector(({ sequences }) => sequences.listSequences.prospectSequence);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (currentProspect?.id) {
      dispatch(getSequencesByContact(currentProspect?.id));
    }
  }, [dispatch, currentProspect]);

  const data = useMemo(() => {
    if (!currentProspect) return [];
    return prospectSequences
      ?.filter((sequence) => sequence.status !== "CANCELED")
      .filter((sequence) => sequence.name?.toLowerCase().includes(search.toLowerCase()));
  }, [currentProspect, prospectSequences, search]);

  const table = useReactTable<any>({
    data: data || [],
    columns: Columns,
    pageCount: data?.length,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
  });

  const selected = table.getSelectedRowModel();
  const selectedSequenceIdList = useMemo(() => {
    return selected.rows.map((r) => r.original.id);
  }, [selected.rows]);

  const onSubmit = () => {
    if (!selectedSequenceIdList) {
      return;
    }
    handleSubmit(selectedSequenceIdList[0]);
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#1A2135",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Un-Enroll From Sequence
        </Typography>
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#1A2135",
            }}
          />
        </Box>
      </DialogTitle>

      <Box
        sx={{
          background: "#E0E0E0",
          height: "1px",
          flexShrink: 0,
          alignSelf: "stretch",
        }}
      />
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          minWidth: "600px",
        }}
      >
        <Typography fontWeight={600} fontSize={16}>
          Select Sequence
        </Typography>
        <SearchBar
          sx={{ flexGrow: 1 }}
          placeholder="Search by Name"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
        <TsTable table={table} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onSubmit}>
            Confirm Un-enrollment
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
