import ProspectInfo from "@/common/components/prospect-info/ProspectInfo";
import { useState } from "react";
import { InboxTabSelector } from "../TabSelector";
import { TabValue } from "../TabSelector";

import { Box } from "@mui/material";
import { SmsTab } from "../tabs/SMS/SmsTab";
import { EmailTab } from "../tabs/EmailTab";
import CallsTab from "../tabs/CallsTab";
import ActivityTab from "../tabs/ActivityTab";
import config from "@/common/constants/config";

export const ViewInboxMessages = () => {
  const [tabValue, setTabValue] = useState<TabValue>(TabValue.SMS);

  return (
    <Box sx={{ display: "flex", gap: "16px", height: "100%" }}>
      {config.flagOutreach ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            gap: "16px",
          }}
        >
          <InboxTabSelector tabValue={tabValue} setTabValue={setTabValue} />
          <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {tabValue === TabValue.EMAIL ? (
              <EmailTab />
            ) : tabValue === TabValue.SMS ? (
              <SmsTab />
            ) : tabValue === TabValue.CALLS ? (
              <CallsTab />
            ) : (
              <ActivityTab />
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            gap: "16px",
          }}
        >
          <CallsTab />
        </Box>
      )}

      <Box>
        <ProspectInfo isInbox />
      </Box>
    </Box>
  );
};
