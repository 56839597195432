import { KnSequenceContactDTOStatusEnum } from "@/services/generated";
import { customColors } from "@/theme/palette/colors";
import { Box, Chip, useTheme } from "@mui/material";

type ContactStatusBadgeProps = {
  status?: KnSequenceContactDTOStatusEnum;
};

function formatSequenceStatus(status?: KnSequenceContactDTOStatusEnum) {
  if (status === KnSequenceContactDTOStatusEnum.InProgress) {
    return "Active";
  }

  if (
    status === KnSequenceContactDTOStatusEnum.PausedInProspectLevel ||
    status === KnSequenceContactDTOStatusEnum.PausedInTeamLevel ||
    status === KnSequenceContactDTOStatusEnum.PausedInTemplateLevel
  ) {
    return "Paused";
  }

  if (status === KnSequenceContactDTOStatusEnum.ContactAnswered) {
    return "Responded";
  }

  if (status === KnSequenceContactDTOStatusEnum.Unenrolled) {
    return "Unenrolled";
  }

  if (status === KnSequenceContactDTOStatusEnum.Canceled) {
    return "Canceled";
  }

  if (status === KnSequenceContactDTOStatusEnum.Completed) {
    return "Completed";
  }
}

export function ContactStatusBadge({ status }: ContactStatusBadgeProps) {
  const theme = useTheme();

  const bgColorByStatus: Partial<{ [key in KnSequenceContactDTOStatusEnum]: string }> = {
    IN_PROGRESS: customColors.pill.active,
    PAUSED_IN_PROSPECT_LEVEL: customColors.pill.paused,
    PAUSED_IN_TEAM_LEVEL: customColors.pill.paused,
    PAUSED_IN_TEMPLATE_LEVEL: customColors.pill.paused,
    CONTACT_ANSWERED: customColors.pill.answered,
    UNENROLLED: customColors.pill.unenrolled,
    CANCELED: theme.palette.commonColors.danger,
    COMPLETED: customColors.pill.completed,
  };

  const colorByStatus: Partial<{ [key in KnSequenceContactDTOStatusEnum]: string }> = {
    IN_PROGRESS: theme.palette.commonColors.white,
    PAUSED_IN_PROSPECT_LEVEL: theme.palette.commonColors.black,
    PAUSED_IN_TEAM_LEVEL: theme.palette.commonColors.black,
    PAUSED_IN_TEMPLATE_LEVEL: theme.palette.commonColors.black,
    CONTACT_ANSWERED: theme.palette.commonColors.black,
    UNENROLLED: theme.palette.commonColors.white,
    CANCELED: theme.palette.commonColors.white,
    COMPLETED: theme.palette.commonColors.black,
  };

  const defaultStyles = {
    bgcolor: status ? bgColorByStatus[status] : theme.palette.midnight.p,
    color: status ? colorByStatus[status] : theme.palette.commonColors.white,
    fontWeight: 500,
    fontSize: "12px",
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Chip
        label={formatSequenceStatus(status)}
        sx={{
          ...defaultStyles,
          height: "fit-content",
          fontWeight: 500,
          letterSpacing: "0.2px",
          "& .MuiChip-label": {
            padding: "4px 8px",
            lineHeight: 1.34,
          },
        }}
      />
    </Box>
  );
}
