import toast from "@/lib/toast";
import Services from "@/services";
import {
  AddTeamUserRequest,
  KnTeamUserRole,
  TeamInvite,
  TeamInviteRequest,
  TeamJoinRequest,
  TeamRequest,
  UpdateTeamUserRequest,
} from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTeam, getTeamUsers } from "./get";

interface Team extends TeamRequest {
  id?: string;
  media?: File | null;
}

type TeamSummary = {
  admin: number | undefined;
  salesManager: number | undefined;
  salesRepresentative: number | undefined;
  superAdmin: number | undefined;
  userLastLogin: string | undefined;
};

type State = {
  team?: Team | null;
  draftTeam?: Team | null;
  loading: boolean;
  loadingUpdateUser: boolean;
  error: string | null;
  users: KnTeamUserRole[];
  invites: TeamInvite[];
  joinError: string | null;
  canSave?: boolean;

  loadingTeamSummary: boolean;
  errorTeamSummary: string | null;
  teamSummary: TeamSummary | null;
};

const initialState: State = {
  team: null,
  draftTeam: null,
  loading: false,
  loadingUpdateUser: false,
  error: null,
  users: [],
  invites: [],
  joinError: null,
  canSave: true,

  loadingTeamSummary: false,
  errorTeamSummary: null,
  teamSummary: null,
};

export const createTeam = createAsyncThunk(
  "team/createTeam",
  async (draftTeam: Team, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Teams.createTeam(draftTeam);
      if (response.status === 200) {
        toast.success("Team successfully created");
      } else {
        toast.error(response.statusText);
      }

      if (draftTeam?.media) {
        await Services.Teams.uploadLogoPicture(draftTeam?.media);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateTeam = createAsyncThunk("team/updateTeam", async (_, { rejectWithValue, getState }) => {
  const state = getState() as { settings: any };

  let draftTeam = state.settings.team.draftTeam;

  try {
    if (!draftTeam?.id) {
      return rejectWithValue("No draft user");
    }

    if (draftTeam?.media) {
      const response = await Services.MediaController.uploadFile(draftTeam.media);

      draftTeam = { ...draftTeam, logoImgUrl: response.data };
    }

    await Services.Teams.updateTeam(draftTeam?.id, draftTeam);

    toast.success("Team successfully updated");

    return draftTeam;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const addTeamUser = createAsyncThunk(
  "team/addTeamUser",
  async (user: AddTeamUserRequest, { rejectWithValue, dispatch }) => {
    try {
      const userResponse = await Services.TeamUserRoles.addUser(user);
      toast.success("User successfully added");
      return userResponse;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateTeamUser = createAsyncThunk(
  "team/updateTeamUser",
  async (body: UpdateTeamUserRequest, { rejectWithValue, dispatch }) => {
    try {
      const userResponse = await Services.TeamUserRoles.updateUserRole(body);
      toast.success("User successfully updated");
      return userResponse;
    } catch (error) {
      toast.error("An error occurred when trying to update");
      return rejectWithValue(error.message);
    }
  },
);

export const inviteTeamUser = createAsyncThunk(
  "team/inviteTeamUser",
  async (user: TeamInviteRequest, { rejectWithValue, dispatch }) => {
    try {
      await Services.Invite.sendNow(user);
      return user;
    } catch (error) {
      // TODO figure out the error layout
      return rejectWithValue(error.response.data);
    }
  },
);

export const listInvites = createAsyncThunk("team/listInvites", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await Services.Invite.list();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const joinTeam = createAsyncThunk(
  "team/joinTeam",
  async (invitation: TeamJoinRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Invite.join(invitation);
      dispatch(getTeam());
      dispatch(getTeamUsers());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteUserFromTeam = createAsyncThunk(
  "team/deleteUserFromTeam",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await Services.TeamUserRoles.deleteUser(id);
      toast.success("User successfully deleted");
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getTeamSummary = createAsyncThunk(
  "team/getTeamSummary",
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.TeamUserRoles.getTeamAllUserSummary(teamId);

      const result = {
        admin: response.data.admin,
        salesManager: response.data.sales_manager,
        salesRepresentative: response.data.sales_representative,
        superAdmin: response.data.superadmin,
        userLastLogin: response.data.users_last_login,
      };

      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeam: (state, action) => {
      state.team = action.payload;
    },

    setDraftTeam: (state, { payload }) => {
      state.draftTeam = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeam.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.team = payload.data;
        }
        state.loading = false;
      })

      .addCase(createTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTeam.fulfilled, (state, { payload }) => {
        if (payload) {
          state.team = payload;
        }
        state.loading = false;
      })
      .addCase(createTeam.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(getTeamUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamUsers.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.users = payload.data;
        }
        state.loading = false;
      })
      .addCase(updateTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeam.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.team = payload;
      })
      .addCase(inviteTeamUser.fulfilled, (state, { payload }) => {
        if (payload) {
          state.invites = [...state.invites, { email: payload.email, status: "SENT", full_name: payload.fullName }];
        }
      })
      .addCase(listInvites.pending, (state) => {
        state.loading = true;
      })
      .addCase(listInvites.fulfilled, (state, { payload }) => {
        if (payload) {
          state.invites = payload;
        }
        state.loading = false;
      })
      .addCase(updateTeam.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string; // Handle error
      })

      .addCase(addTeamUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = [...state.users, payload.data];
      })

      .addCase(joinTeam.rejected, (state, { payload }) => {
        state.loading = false;
        state.joinError = payload as string;
      })

      .addCase(deleteUserFromTeam.fulfilled, (state, { payload }) => {
        state.users = state.users.filter((user) => user.user_id !== payload);
      })
      .addCase(updateTeamUser.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.users = state.users.map((user) => {
            if (user.user_id === payload.data.user_id) {
              return { ...user, userRole: payload.data.userRole };
            }
            return user;
          });
        }
        state.loadingUpdateUser = false;
      })
      .addCase(updateTeamUser.pending, (state, { payload }) => {
        state.loadingUpdateUser = true;
      })
      .addCase(updateTeamUser.rejected, (state, { payload }) => {
        state.loadingUpdateUser = false;
        state.error = payload as string; // Handle error
      })

      .addCase(getTeamSummary.pending, (state, { payload }) => {
        state.loadingTeamSummary = true;
        state.errorTeamSummary = null;
      })
      .addCase(getTeamSummary.fulfilled, (state, { payload }) => {
        state.loadingTeamSummary = false;
        state.teamSummary = payload;
      })

      .addCase(getTeamSummary.rejected, (state, { payload }) => {
        state.loadingTeamSummary = false;
      });
  },
});

export const { setTeam, setDraftTeam } = teamSlice.actions;

export default teamSlice.reducer;
