import { ConsentLevel } from "@/common/constants/consent-level";
import { KnTeamContact } from "@/services/generated";

export type ProspectInfo = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  companyEmail: string;
  companyDomain: string;
  companyAddress: string;
  companyDepartment: string;
  jobTitle: string;
  linkedinUrl: string;
  avatar?: string;
};

export interface DataImportSequenceContacts extends KnTeamContact {
  id: string;
}

export interface DataImportSequenceContactsFiltersInput {
  name?: string;
  pageNumber?: number;
  pageSize?: number;
}

export type Prospect = {
  id: number;
  prospect: {
    name: string;
    img: string;
  };
  email: string;
  number: string;
  owner: {
    name: string;
    img: string;
  };
  status: ProspectStatus;
  company: {
    name: string;
    logo: string;
  };
  createdDate: string;
  actions: string;
  assignee?: string;

  consent?: ConsentLevel;

  jobTitle?: string;
  location?: string;
  industry?: string;
};

export enum ProspectStatus {
  Open = "Open",
  InProgress = "In Progress",
  OpenDeal = "Open deal",
  Unqualified = "Unqualified",
  New = "New",
  Inactive = "Inactive",
}
