import { Controller, useFormContext } from "react-hook-form";
import { FormControl } from "@mui/material";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getPipelineDetails, getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import FormLabel from "@/common/components/form-label/FormLabel";

interface Option {
  label: string;
  value: string;
}

export default function SetDealFlowStageForm() {
  const { control, watch } = useFormContext();
  const dispatch = useAppDispatch();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const pipelineId = watch("action.pipelineId");

  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  const pipelineOptions: Option[] = useMemo(() => {
    if (!pipelines) return [];

    return pipelines.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [pipelines]);

  useEffect(() => {
    if (pipelineId) {
      dispatch(getPipelineDetails(pipelineId));
    }
  }, [dispatch, pipelineId]);

  const pipelineStageOptions: Option[] = useMemo(() => {
    if (!pipelines) return [];

    const pipelineStages = pipelines.find((p) => p.id === pipelineId)?.stages || [];
    return pipelineStages.map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    });
  }, [pipelines, pipelineId]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Controller
        name="action.pipelineId"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Select Pipeline" aria-required />
            <Dropdown
              options={pipelineOptions}
              value={field.value}
              onChange={(option) => field.onChange(option.value)}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />
      <Controller
        name="action.stageId"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Select Pipeline Stage" aria-required />
            <Dropdown
              options={pipelineStageOptions}
              value={field.value}
              onChange={(option) => field.onChange(option.value)}
              error={fieldState.error?.message}
            />
          </FormControl>
        )}
      />
    </div>
  );
}
