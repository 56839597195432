import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import TsTable from "@/common/components/ts-table";
import toast from "@/lib/toast";
import { useAppSelector } from "@/redux/store";
import { SequenceTemplate } from "@/types/sequence";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Table } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { SequenceFiltersInput } from "..";
import { useMissingTwilioCredentialsWarn } from "../utils/useMissingTwilioCredentials";
import SequenceVersions from "../versions";
import SequenceDetails from "../sequence-details";

type SequenceTableProps = {
  filters: SequenceFiltersInput | undefined;
  table: Table<SequenceTemplate>;
};

export default function SequenceTable({ table, filters }: SequenceTableProps) {
  const {
    sequences,
    loading: sequencesLoading,
    error: sequencesError,
  } = useAppSelector((selector) => selector.sequences.listSequences);
  const [currentSequenceId, setCurrentSequenceId] = useState<string>();
  const { TwilioWarn } = useMissingTwilioCredentialsWarn();
  const currentSequence = useMemo(() => {
    return (sequences || []).find((sequence) => sequence.id === currentSequenceId);
  }, [currentSequenceId, sequences]);

  const handleCellClick = async (id: string) => {
    setCurrentSequenceId(id);
  };

  // TODO: Temporary solution to show no sequences found message when filters are applied.
  const hasNameFilter = filters?.name !== undefined && filters?.name !== "";
  const hasSelectedStatusFilter =
    filters?.status !== undefined && filters?.status.length > 0 && filters?.status.length !== 3;
  const hasFilters = hasNameFilter || hasSelectedStatusFilter;

  useEffect(
    function showAlertWhenTakingTooLong() {
      let timeoutId: NodeJS.Timeout;

      if (sequencesLoading) {
        timeoutId = setTimeout(() => {
          toast.warning("Sequences are taking longer than expected.", { autoClose: 20000 });
        }, 60000); // 60 seconds
      }

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [sequencesLoading],
  );

  useEffect(
    function showAlertWhenSequenceTimeout() {
      if (sequencesError?.includes("Network")) {
        toast.error(
          "Your sequence enrollment was not successful, as we are experiencing issues with Sequences. Please try again in a few minutes.",
          { autoClose: 30000 },
        );
      }
    },
    [sequencesError],
  );

  if (sequencesLoading) return <LoadingSpinner />;

  return (
    <>
      {sequences && (
        <TsTable
          table={table}
          handleCellClick={handleCellClick}
          notFoundProps={{
            title:
              sequences.length === 0 && hasFilters
                ? "No sequences found that match your criteria"
                : "No sequences found",
            description:
              sequences.length === 0 && hasFilters ? (
                <span>Try a different search parameter or simply clear all the filters with the button below.</span>
              ) : (
                <span>
                  Begin creating an agent by clicking the <b>"Create Sequence"</b> button above.
                </span>
              ),
            Icon: SquaresPlusIcon,
          }}
        />
      )}
      {TwilioWarn && <TwilioWarn />}
      {currentSequence && (
        <SequenceDetails
          open={!!currentSequence}
          sequence={currentSequence}
          versions={currentSequence.versions ?? []}
          onClose={() => setCurrentSequenceId(undefined)}
        />
      )}
    </>
  );
}
