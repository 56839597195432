import Dropdown from "@/common/components/dropdown";
import { Roles } from "@/common/constants/roles";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";
import { useRoles } from "./useRoles";

interface UserRoleSelectorProps {
  value: KnTeamUserRoleUserRoleEnum;
  onChange: (value: { label: string; value: KnTeamUserRoleUserRoleEnum }) => void;
}

export default function UserRoleSelector({ value, onChange }: UserRoleSelectorProps) {
  const { userRoles, canUpdateUsers } = useRoles();
  return (
    <Dropdown
      label="Role"
      placeholder="Role"
      isDisabled={!canUpdateUsers(value)}
      options={userRoles.map((role) => ({
        label: role.roleName,
        value: role.roleCode,
        isDisabled: !canUpdateUsers(role.roleCode),
      }))}
      value={value}
      defaultValue={{
        value: Roles[value].roleCode,
        label: Roles[value].roleName,
      }}
      onChange={onChange}
    />
  );
}
