import TableCell from "@/common/components/table-cell";
import TableDate from "@/common/components/table-date";
import TableHeader from "@/common/components/table-header";
import createCheckboxCol from "@/common/functions/createCheckboxCol";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import TableActionButton from "./ActionButton";

const columnHelper = createColumnHelper<Prospect>();

export const ProspectColumns = [
  createCheckboxCol(columnHelper),

  columnHelper.accessor("firstName", {
    header: () => <TableHeader>Contact</TableHeader>,

    cell: (info) => {
      const row = info.row;
      return <TableCell>{`${info.getValue()}  ${row.original.lastName}`}</TableCell>;
    },
  }),

  columnHelper.accessor("email", {
    header: () => <TableHeader>Email</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => <TableHeader>Created Date</TableHeader>,
    cell: (info: CellContext<Prospect, number>) => {
      return <TableDate nanos={info?.getValue()} />;
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => <TableHeader>Actions</TableHeader>,
    cell: (props) => <TableActionButton params={props} />,
  }),
];
