import Checkbox from "@/common/components/checkbox/Checkbox";
import TsTable from "@/common/components/ts-table";
import { KnFirestoreImportJobImportJobTypeEnum } from "@/services/generated";
import { Box, Typography, useTheme } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { InformationCircle } from "heroicons-react";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import objectMapFields from "../objectMapFields";
import { DataImportFormFields, DataImportSchema } from "../types";
import useMapStepColumns from "./useMapStepColumns";

const autoFillmappedFirestoreFieldNameProperty = (
  originalFileColumnName: string,
  importType: KnFirestoreImportJobImportJobTypeEnum,
) => {
  return objectMapFields[importType as keyof typeof objectMapFields].find((field) => {
    return originalFileColumnName.replace(/\s+/g, "").toLowerCase() === field.toLowerCase();
  });
};

export default function MapStep() {
  const theme = useTheme();
  const methods = useFormContext<DataImportSchema>();
  const { importType } = useWatch<DataImportFormFields>();
  const columns = useMapStepColumns();
  const columnMappingStrategyList = methods.watch("columnMappingStrategyList");

  const data = useMemo(
    () =>
      (columnMappingStrategyList || []).map((column) => ({
        ...column,
        id: column.originalFileColumnName,
        mappedFirestoreFieldName:
          autoFillmappedFirestoreFieldNameProperty(column.originalFileColumnName, importType!) || "",
      })),
    [],
  );

  useEffect(() => {
    methods.setValue("columnMappingStrategyList", data);
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box>
        <Typography
          style={{
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Map columns in your file to contact properties
        </Typography>

        <Typography
          style={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
          }}
        >
          Each column header below should be mapped to a contact properly in SalesAi. Some of these have already been
          mapped based on their names. Anything that hasn't been mapped yet can be mapped to a note properly with the
          dropdown menu. You can always choose “Don't import column”.
        </Typography>
      </Box>

      <TsTable table={table} showPagination={false} />

      {importType === KnFirestoreImportJobImportJobTypeEnum.Contact && (
        <>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <InformationCircle color={theme.palette.decorative.informative} size={20} />
            <Typography sx={{ fontSize: 14 }}>Unmapped data will not be imported.</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
