import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import DrawerLayout from "@/layouts/DrawerLayout";
import { SequenceTemplateVersionResponse } from "@/services/generated";
import { SequenceTemplate } from "@/types/sequence";
import { Box, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useMemo, useState } from "react";

import { Button } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { TabList } from "@mui/lab";
import { Contacts } from "./contacts";
import { Versions } from "./versions";
import { customColors } from "@/theme/palette/colors";
import { useNavigate } from "react-router";
import { useAppDispatch } from "@/redux/store";

import { createNewVersionSequenceTemplate } from "@/redux/reducers/sequences/slices/createSequence";

interface SequenceVersionsProps {
  versions: SequenceTemplateVersionResponse[];
  sequence: SequenceTemplate;
  open?: boolean;
  onClose: () => void;
}

export default function SequenceDetails({ open = false, onClose, sequence, versions }: SequenceVersionsProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState("contacts");

  const versionsStatuses = useMemo(() => {
    return sequence.versions?.map((version) => version.status) || [];
  }, [sequence]);

  const handleNewDraft = async () => {
    try {
      const activeSequence = versions.find((sequence) => sequence.status === "ACTIVE");

      if (!activeSequence?.id) return;

      const newVersion = await dispatch(createNewVersionSequenceTemplate(activeSequence.id)).unwrap();

      if (newVersion.id) {
        handleNavigate(newVersion.id);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleNavigate = (id: string) => {
    navigate(routes.sequence.update.with({ id: id }));
    handleClose();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <DrawerLayout title="Sequence Details" closeDrawer={handleClose} sx={{ width: "701px" }}>
        <TabContext value={tabValue}>
          <Box sx={{ display: "flex", flexDirection: "column", height: "100%", gap: "16px" }}>
            <span>
              <TabList
                onChange={handleTabChange}
                TabIndicatorProps={{ style: { backgroundColor: customColors.midnight.p } }}
                sx={{ display: "flex", alignItems: "center", gap: "8px", height: "48px" }}
              >
                <Tab
                  label="Contacts"
                  value={"contacts"}
                  sx={{ textTransform: "capitalize", width: "106px", maxHeight: "none", height: "48px" }}
                />
                <Tab
                  label="Versions"
                  value={"versions"}
                  sx={{ textTransform: "capitalize", width: "106px", maxHeight: "none", height: "48px", ml: "8px" }}
                />
              </TabList>
              <Box sx={{ width: "100%", border: `1px solid ${theme.palette.commonColors.light}` }} />
            </span>

            <TabPanel value="contacts" sx={{ padding: "0" }}>
              <Contacts sequenceId={sequence.id} />
            </TabPanel>

            <TabPanel value="versions" sx={{ padding: "0" }}>
              <Versions versions={versions} sequence={sequence} />
            </TabPanel>
          </Box>

          <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
            <Button variant="secondary" onClick={handleClose} sx={{ textTransform: "capitalize", width: "50%" }}>
              Cancel
            </Button>
            {!versionsStatuses.includes("DRAFT") && !versionsStatuses.includes("INACTIVE") && (
              <Button variant="primary" onClick={handleNewDraft} sx={{ textTransform: "capitalize", width: "50%" }}>
                Create New Draft
              </Button>
            )}
          </Box>
        </TabContext>
      </DrawerLayout>
    </Drawer>
  );
}
