import { Roles } from "@/common/constants/roles";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo } from "react";

const rolePermissions: Record<KnTeamUserRoleUserRoleEnum, KnTeamUserRoleUserRoleEnum[]> = {
  [KnTeamUserRoleUserRoleEnum.Superadmin]: [
    KnTeamUserRoleUserRoleEnum.SalesRepresentative,
    KnTeamUserRoleUserRoleEnum.SalesManager,
    KnTeamUserRoleUserRoleEnum.Admin,
    KnTeamUserRoleUserRoleEnum.Superadmin,
  ],
  [KnTeamUserRoleUserRoleEnum.Admin]: [
    KnTeamUserRoleUserRoleEnum.SalesRepresentative,
    KnTeamUserRoleUserRoleEnum.SalesManager,
    KnTeamUserRoleUserRoleEnum.Admin,
  ],
  [KnTeamUserRoleUserRoleEnum.SalesManager]: [
    KnTeamUserRoleUserRoleEnum.SalesRepresentative,
    KnTeamUserRoleUserRoleEnum.SalesManager,
  ],
  [KnTeamUserRoleUserRoleEnum.SalesRepresentative]: [],
};

export const useRoles = () => {
  const { user, ...auth0 } = useAuth0();

  const userLoggedRole: KnTeamUserRoleUserRoleEnum = useMemo(() => {
    let userRole = user?.team_user_role;

    if (user?.is_impersonation) {
      userRole = user?.impersonated_user?.team_user_role;
    }

    return userRole;
  }, [user?.impersonated_user?.team_user_role, user?.is_impersonation, user?.team_user_role]);

  const isAdministrator = useMemo(() => {
    return (
      [KnTeamUserRoleUserRoleEnum.Superadmin, KnTeamUserRoleUserRoleEnum.Admin] as KnTeamUserRoleUserRoleEnum[]
    ).includes(userLoggedRole);
  }, [userLoggedRole]);

  const userRoles = useMemo(() => {
    const getBaseRoleOrCurrentRole = userLoggedRole || KnTeamUserRoleUserRoleEnum.SalesManager;

    return rolePermissions[getBaseRoleOrCurrentRole].map((rolePermission) => Roles[rolePermission]);
  }, [userLoggedRole]);

  const canUpdateUsers = useCallback(
    (userRoleToBeUpdated: KnTeamUserRoleUserRoleEnum) => {
      return rolePermissions[userLoggedRole]?.includes(userRoleToBeUpdated) || false;
    },
    [userLoggedRole],
  );

  return {
    role: userLoggedRole,
    userRoles,
    isAdministrator,
    canUpdateUsers,
    auth0: {
      user,
      ...auth0,
    },
  };
};
