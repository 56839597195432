import { Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const ProspectEngagesResponses = () => {
  const methods = useFormContext();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          Lead Flow
        </Typography>

        <Controller
          name="event.dealFlowId"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <label htmlFor="no_response" style={{ cursor: "pointer", width: "100%", marginTop: "10px" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <input
                  id="no_response"
                  type="radio"
                  className="radio"
                  {...field}
                  value={field.value}
                  checked={field.value === "no_response_from_prospect"}
                  onChange={() => {
                    field.onChange("no_response_from_prospect");
                  }}
                />
                <Typography variant="body2">No response from contact</Typography>
              </div>
            </label>
          )}
        />
      </div>
    </div>
  );
};
