import TableCell from "@/common/components/table-cell";
import TableDate from "@/common/components/table-date";
import TableHeader from "@/common/components/table-header";
import { SequenceTemplate } from "@/types/sequence";
import { createColumnHelper } from "@tanstack/react-table";
import StatusBadge from "../sequence-status";
import TableActionButton from "./ActionButton";

const columnHelper = createColumnHelper<SequenceTemplate>();

export const SequenceColumns = [
  columnHelper.accessor("name", {
    header: () => <TableHeader>Sequence Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("createdBy", {
    header: () => <TableHeader>Created By</TableHeader>,
    cell: (info) => {
      const createdBy = info.getValue();
      if (!createdBy) {
        return;
      }
      return <TableCell>{createdBy.name}</TableCell>;
    },
  }),

  columnHelper.accessor("sumProspectsCurrentEnrolled", {
    header: () => <TableHeader>Currently Enrolled Contacts</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("sumProspectsEverEnrolled", {
    header: () => <TableHeader>Total Enrolled Contacts</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("status", {
    header: () => <TableHeader>Status</TableHeader>,
    cell: (info) => {
      const status = info.getValue();
      const versionsLength = info.row.original.versions?.length || 0;
      const numRemainingVersions = versionsLength > 1 ? versionsLength - 1 : 0;
      if (!status) {
        return;
      }
      return <StatusBadge status={status} numRemainingVersions={numRemainingVersions} />;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => <TableHeader>Created Date</TableHeader>,
    cell: (info) => {
      return <TableDate date={info.getValue()} />;
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => <TableHeader>Actions</TableHeader>,
    cell: (props) => <TableActionButton params={props} />,
  }),
];
