import ProspectInfo from "@/common/components/prospect-info/ProspectInfo";
import DrawerLayout from "@/layouts/DrawerLayout";
import { closeViewDrawer, setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { FormProvider, useForm } from "react-hook-form";
import { batch } from "react-redux";

export default function ViewProspectDrawer() {
  const dispatch = useAppDispatch();
  const { viewDrawerIsOpen } = useAppSelector(({ prospects }) => prospects.listProspects);
  const methods = useForm();

  const toggleDrawer = () => dispatch(closeViewDrawer());

  const handleClose = () => {
    batch(() => {
      dispatch(setCurrentProspect(null));
      dispatch(closeViewDrawer());
    });
  };

  return (
    <FormProvider {...methods}>
      <Drawer open={viewDrawerIsOpen} onClose={() => handleClose()} anchor="right">
        <DrawerLayout customWidth="420px" title="Contact Information" testid="viewProspect" closeDrawer={toggleDrawer}>
          <ProspectInfo />
        </DrawerLayout>
      </Drawer>
    </FormProvider>
  );
}
