import Services from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { KnLookupRequest2SortDirectionEnum } from "@/services/generated";

type GetCurrentInboxType = {
  type?: string | undefined;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  prospectId: string | undefined;
};

enum Type {
  SMS = "SMS",
  EMAIL = "EMAIL",
  CALL = "CALL",
}

export const getEmailCurrentInbox = createAsyncThunk(
  "inbox/getEmailCurrentInbox",
  async ({ prospectId }: GetCurrentInboxType) => {
    try {
      if (!prospectId) {
        throw new Error("Contact ID is required");
      }
      const response = await Services.Inbox.lookupMessages({
        filters: [
          {
            property: "type",
            options: [
              {
                operator: "equal",
                values: [Type.EMAIL],
              },
            ],
          },
          {
            property: "prospectId",
            options: [
              {
                operator: "equal",
                values: [prospectId as string],
              },
            ],
          },
        ],
      });

      return response?.data?.results;
    } catch (error) {
      return error;
    }
  },
);

export const getSmsCurrentInbox = createAsyncThunk(
  "inbox/getSmsCurrentInbox",
  async ({ prospectId, sortBy, sortDirection }: GetCurrentInboxType) => {
    try {
      if (!prospectId) {
        throw new Error("Contact ID is required");
      }
      const response = await Services.Inbox.lookupMessages({
        filters: [
          {
            property: "type",
            options: [
              {
                operator: "equal",
                values: [Type.SMS],
              },
            ],
          },
          {
            property: "prospectId",
            options: [
              {
                operator: "equal",
                values: [prospectId as string],
              },
            ],
          },
        ],
        sortBy,
        sortDirection: sortDirection as KnLookupRequest2SortDirectionEnum,
      });

      return response?.data?.results;
    } catch (error) {
      return error;
    }
  },
);

export const getCallCurrentInbox = createAsyncThunk(
  "inbox/getCallCurrentInbox",
  async ({ prospectId }: GetCurrentInboxType) => {
    try {
      if (!prospectId) {
        throw new Error("Contact ID is required");
      }
      const response = await Services.Inbox.lookupMessages({
        filters: [
          {
            property: "type",
            options: [
              {
                operator: "equal",
                values: [Type.CALL],
              },
            ],
          },
          {
            property: "prospectId",
            options: [
              {
                operator: "equal",
                values: [prospectId as string],
              },
            ],
          },
        ],
      });

      return response?.data?.results;
    } catch (error) {
      return error;
    }
  },
);
