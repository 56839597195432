import { UploadPercentsHook } from "@/common/components/drag-and-drop/useUploadPercent";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import LinearProgressBar from "@/common/components/LinearProgressBar";
import { VariableEmbedDelta } from "@/common/components/text-editor/modules/VariableEmbed";
import { TextEditor } from "@/common/components/text-editor/TextEditor";
import DrawerLayout from "@/layouts/DrawerLayout";
import toast from "@/lib/toast";
import AiForm from "@/pages/inbox/AiForm";
import { ActionButtons } from "@/pages/inbox/tabs/ActionButtons";
import { getConsentsForCurrentUser } from "@/redux/reducers/consent/slices/getConsentsForCurrentUser";
import { genMsgCustom, genMsgSuggestMeetingTimes } from "@/redux/reducers/inbox/slices/generateMessage";
import { deleteAttachment } from "@/redux/reducers/sequences/slices/createSequence";
import { getSequenceMessageTags } from "@/redux/reducers/sequences/slices/listSequences";
import { getTeamUsers } from "@/redux/reducers/settings/team/get";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { GenMsgCustomMessageTypeEnum, TextResponse } from "@/services/generated";
import { CodeBracketSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ReactQuill from "react-quill-new";
import * as Yup from "yup";

interface CreateMessageProps {
  closeDrawer: () => void;
  isOpen: boolean;
  onAddAction: (data: any) => void;
  removeAttachment: (index: number) => void;
  fromEmail?: string;
  subjectTemplate?: string;
  messageTemplate?: string;
  attachments?: EmailFormAttachment[];
  uploadPercents?: UploadPercentsHook;
}

interface EmailFormAttachment {
  id?: string;
  fileName?: string;
  name?: string;
  size?: number;
}

const schema = Yup.object({
  fromEmail: Yup.string().label("From").required(),
  subjectTemplate: Yup.string().label("Subject").required(),
  messageTemplate: Yup.string().label("Message").required(),
  attachments: Yup.array().of(Yup.mixed<EmailFormAttachment>()).default([]),
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  userIds: Yup.array().of(Yup.string().required()),
  prompt: Yup.string().label("Prompt"),
  tags: Yup.array().of(Yup.string()),
});

export type Form = Yup.InferType<typeof schema>;

export default function DraftTemplateEmail({
  closeDrawer,
  isOpen,
  onAddAction,
  fromEmail = "",
  subjectTemplate = "",
  messageTemplate = "",
  attachments = [],
  uploadPercents,
  removeAttachment,
}: CreateMessageProps) {
  const methods = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      fromEmail,
      subjectTemplate,
      messageTemplate,
      attachments,
    },
  });

  const { control, getValues, setValue, setError } = methods;

  const dispatch = useAppDispatch();
  const consents = useAppSelector((state) => state.consent.consents);
  const users = useAppSelector(({ settings }) => settings.team.users);
  const tags = useAppSelector((selector) => selector.sequences.listSequences.sequenceMessageTags);

  const quillRef = useRef<ReactQuill | null>(null);

  const quillRefCallBack = useCallback(
    (quill: ReactQuill | null) => {
      if (quill) {
        quillRef.current = quill;

        const editor = quill.getEditor();

        editor.on("text-change", (_, __, source) => {
          if (source !== "user") return;

          const text = editor.getContents().reduce((acc, curr) => {
            if (typeof curr.insert === "string") acc += curr.insert;
            else {
              const embed = curr.insert as unknown as VariableEmbedDelta;
              acc += embed.variableEmbed.value;
            }
            return acc;
          }, "");

          const regex = /\[(.|\n)*?\]/g;
          const content = [];
          let match;
          let cursor = 0;
          let embedCount = 0;
          let retained = 0;

          while ((match = regex.exec(text)) !== null) {
            const value = match[0];
            const index = match.index;
            const length = value.length;

            const valid = tags.includes(value);

            retained += index - cursor;

            const currentTag = editor.getContents(retained + embedCount, 1);

            if (currentTag.ops.some((op) => (op.insert as unknown as VariableEmbedDelta).variableEmbed)) {
              content.push({
                retain: index - cursor + 1,
              });
            } else {
              const elements = editor.getContents(retained + embedCount, length);

              let deleteCount = 0;

              for (const delta of elements.ops) {
                if ((delta.insert as unknown as VariableEmbedDelta).variableEmbed) {
                  deleteCount++;
                  break;
                }
                deleteCount += (delta.insert as string).length ?? 0;
              }

              content.push(
                {
                  retain: index - cursor,
                },
                {
                  delete: deleteCount,
                },
              );

              content.push({
                insert: {
                  variableEmbed: {
                    valid,
                    value,
                  },
                },
              });
            }

            embedCount += 1;
            cursor = index + length;
          }

          content.push({
            retain: text.length - cursor,
          });

          editor.updateContents(content);
        });

        const messageTemplate = getValues("messageTemplate");

        const text = editor.getText().trim();

        if (messageTemplate && !text) {
          setValue("messageTemplate", messageTemplate);

          const delta = editor.clipboard.convert({ html: messageTemplate });

          editor.setContents(delta);

          editor.setSelection(editor.getLength(), 0);

          return;
        }
      }
    },
    [getValues, setValue, tags],
  );

  const [isShowGenAi, setIsShowGenAi] = useState(false);

  useEffect(() => {
    if (!consents?.length) {
      dispatch(getConsentsForCurrentUser());
    }
  }, [consents, dispatch]);

  useEffect(() => {
    if (!users?.length) {
      dispatch(getTeamUsers());
    }
    if (tags.length === 0) {
      dispatch(getSequenceMessageTags());
    }
  }, [dispatch, tags.length, users?.length]);

  useEffect(() => {
    uploadPercents?.resetPercents();
  }, []);

  const emailOptions = useMemo(() => {
    return consents?.map((consent) => ({
      value: consent?.emailAddress,
      label: consent?.emailAddress,
    }));
  }, [consents]);

  const usersWithCal = useMemo(() => {
    return (users || []).filter((u) => u.calendarConnected);
  }, [users]);

  const handleTagSelect = (e: any) => {
    const value = e.value;

    if (value && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const position = editor.getSelection(true).index;

      editor.insertEmbed(position, "variableEmbed", {
        valid: true,
        value,
      });

      editor.insertText(position + 1, " ");
      editor.setSelection(position + 2, "silent");
    }
  };

  const generateMessage = async (e: Event) => {
    e.preventDefault();

    const formData = getValues();

    try {
      let response: AxiosResponse<TextResponse>;

      if (formData.type.value === "suggest_meeting_times") {
        response = await dispatch(
          genMsgSuggestMeetingTimes({
            message_type: GenMsgCustomMessageTypeEnum.Email,
            meet_with_user_id_list: formData.userIds || [],
          }),
        ).unwrap();
      } else {
        response = await dispatch(
          genMsgCustom({
            message_type: GenMsgCustomMessageTypeEnum.Email,
            prompt: formData.prompt || "",
          }),
        ).unwrap();
      }

      if (response.data.message) {
        setIsShowGenAi(false);

        setTimeout(() => {
          const editor = quillRef.current?.editor;

          if (editor) {
            setValue("messageTemplate", response.data.message!);

            const delta = editor.clipboard.convert({ text: response.data.message });

            editor.setContents(delta, "user");

            editor.setSelection(editor.getLength(), 0);
          }
        }, 1000);
      }
    } catch (error) {
      toast.error("Something went wront!");
    }
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      const text = quill.getText();

      if (!text.trim()) {
        setError("messageTemplate", {
          message: "Invalid message",
        });

        return;
      }

      const content = quill.getContents();

      for (const op of content.ops) {
        const insert = op.insert as unknown as VariableEmbedDelta | undefined;

        if (insert && insert.variableEmbed && !insert.variableEmbed.valid) {
          setError("messageTemplate", {
            message: "Invalid tag values",
          });

          return;
        }
      }
    }

    onAddAction(getValues());
  };

  const handleDeleteAttachment = async (index: number) => {
    try {
      const currentAttachments = getValues("attachments");

      const newAttachments = currentAttachments.filter((_, i) => i !== index);

      const removedAttachment = currentAttachments[index];

      setValue("attachments", newAttachments);

      if (removedAttachment?.id) {
        await dispatch(
          deleteAttachment({
            id: removedAttachment.id,
          }),
        );
      }

      removeAttachment(index);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const EmailComposer = () => (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: 6 }}>
      <FormControl sx={{ flexGrow: 1, width: "100%", borderRadius: "0px" }}>
        <Controller
          name="fromEmail"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <div style={{ zIndex: 2 }}>
                <FormLabel label="Sender Email" aria-required />
                <Dropdown
                  {...field}
                  isDisabled={uploadPercents?.isUploading}
                  options={emailOptions}
                  onChange={(option) => field.onChange(option.value)}
                  error={fieldState.error?.message}
                />
              </div>
            );
          }}
        />
      </FormControl>
      <FormControl sx={{ flexGrow: 1, width: "100%" }}>
        <Controller
          name="subjectTemplate"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <div style={{ position: "relative" }}>
                <FormLabel label="Subject" />
                <Input
                  {...field}
                  error={fieldState.error?.message}
                  disabled={uploadPercents?.isUploading}
                  placeholder="Subject"
                />
              </div>
            );
          }}
        />
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <FormControl sx={{}}>
          <Controller
            name="tags"
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                placeholderIcon={<CodeBracketSquareIcon width={24} />}
                placeholder={" "}
                hideDropDownIcon
                hideBorder
                openLeft
                isClearable={false}
                hideSelectedValue
                menuMinWidth="250px"
                isDisabled={uploadPercents?.isUploading}
                options={tags.map((tag) => ({
                  label: tag.toLowerCase(),
                  value: tag,
                }))}
                onChange={handleTagSelect}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormControl>
      </Box>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="messageTemplate"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextEditor
                {...field}
                quillRef={quillRefCallBack}
                disabled={uploadPercents?.isUploading}
                error={fieldState.error?.message}
                enableAttachments
              />
            );
          }}
        />
      </FormControl>
      <Box>
        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="attachments"
            control={control}
            render={({ field }) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {field.value && (
                    <>
                      <FormLabel label="Attachments" />
                      {field.value.map((file, index) => {
                        const fileName = file?.fileName || file?.name;

                        return (
                          <Box
                            sx={{
                              padding: "16px",
                              background: "#F2F4F8",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={`${index}-${fileName}`}
                          >
                            <Box style={{ display: "flex", gap: "8px" }}>
                              <span>{fileName}</span>
                              {file?.size && <span>({(file.size / 1024).toFixed(2)}kb)</span>}
                            </Box>
                            <Box style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                              {uploadPercents?.isUploading && uploadPercents.percents[index] < 100 && (
                                <LinearProgressBar
                                  variant="determinate"
                                  sx={{
                                    width: 100,
                                    height: 8,
                                    borderRadius: 8,
                                  }}
                                  dark
                                  value={uploadPercents?.percents[index]}
                                />
                              )}
                              <XMarkIcon
                                style={{ cursor: "pointer", width: "24px", height: "24px" }}
                                onClick={(event) => {
                                  if (uploadPercents?.isUploading) return;

                                  handleDeleteAttachment(index);
                                }}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </Box>
              );
            }}
          />
        </FormControl>
      </Box>
      <ActionButtons
        sendText="Save Email"
        onSubmit={onSubmit}
        setIsGenerating={setIsShowGenAi}
        aiMessage="Generate AI Message"
      />
    </div>
  );

  return (
    <Drawer open={isOpen} onClose={closeDrawer} anchor="right">
      <DrawerLayout title="Messages" closeDrawer={closeDrawer}>
        <FormProvider {...methods}>
          <form>
            {isShowGenAi ? (
              <AiForm
                users={usersWithCal}
                onClose={() => setIsShowGenAi(false)}
                onGenerate={(e) => generateMessage(e)}
              />
            ) : (
              <>
                <EmailComposer />
              </>
            )}
          </form>
        </FormProvider>
      </DrawerLayout>
    </Drawer>
  );
}
