import Button from "@/common/components/button";

import { KnAiAgentCallToActionEnum } from "@/services/generated";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AiAgentForm } from "../..";
import CallToAction from "./call-to-action";

export type CTAOption = {
  label: string;
  value: string;
};

const DEFAULT_CTA = { value: "NONE", label: "Select" };

const callToActions: CTAOption[] = [
  {
    label: "Book a Meeting",
    value: KnAiAgentCallToActionEnum.BookMeeting,
  },
  {
    label: "Live Transfer",
    value: KnAiAgentCallToActionEnum.LiveTransfer,
  },
];
interface CallToActionBuilderProps {
  isCallFlowDiscoveryTransfer: boolean;
}

export default function CallToActionBuilder({ isCallFlowDiscoveryTransfer }: CallToActionBuilderProps) {
  const { getValues, setValue } = useFormContext<AiAgentForm>();

  const [primaryCallToAction, setPrimaryCallToAction] = useState<CTAOption>(DEFAULT_CTA);
  const [otherCallToAction, setOtherCallToAction] = useState<CTAOption | undefined>(undefined);

  const [availableCallToActions, setAvailableCallToActions] = useState<CTAOption[]>([]);

  useEffect(() => {
    if (isCallFlowDiscoveryTransfer) setValue("call_to_action", KnAiAgentCallToActionEnum.LiveTransfer as never);
  }, [isCallFlowDiscoveryTransfer, setValue]);

  useEffect(() => {
    setAvailableCallToActions(
      callToActions.filter((cta) => {
        if (isCallFlowDiscoveryTransfer) {
          return cta.value === KnAiAgentCallToActionEnum.LiveTransfer;
        }

        return cta.value !== primaryCallToAction.value && cta.value !== otherCallToAction?.value;
      }),
    );
  }, [otherCallToAction?.value, primaryCallToAction.value, isCallFlowDiscoveryTransfer]);

  useEffect(() => {
    // TODO: Default values
    const callToAction = getValues("call_to_action") as KnAiAgentCallToActionEnum;

    switch (callToAction) {
      case "BOOK_MEETING":
        setPrimaryCallToAction({
          value: callToAction,
          label: "Book a Meeting",
        });
        break;
      case "LIVE_TRANSFER":
        setPrimaryCallToAction({
          value: callToAction,
          label: "Live Transfer",
        });
        break;
      default:
        setPrimaryCallToAction(DEFAULT_CTA);
    }

    const callToAction2 = getValues("call_to_action_2")?.[0] as KnAiAgentCallToActionEnum;

    switch (callToAction2) {
      case "BOOK_MEETING":
        setOtherCallToAction({
          value: callToAction2,
          label: "Book a Meeting",
        });
        break;
      case "LIVE_TRANSFER":
        setOtherCallToAction({
          value: callToAction2,
          label: "Live Transfer",
        });
        break;
      default:
        setOtherCallToAction(undefined);
    }
  }, [getValues, isCallFlowDiscoveryTransfer]);

  return (
    <Box>
      <CallToAction
        isPrimary
        callToAction={primaryCallToAction}
        onRemove={() => {
          if (otherCallToAction) {
            setPrimaryCallToAction(otherCallToAction);

            setValue("call_to_action", otherCallToAction.value as never);
          } else {
            setPrimaryCallToAction(DEFAULT_CTA);

            setValue("call_to_action", "NONE" as never);
          }
          setValue("call_to_action_2", [] as never);

          setOtherCallToAction(undefined);
        }}
        setCallToAction={(callToAction) => {
          const option = callToActions.find((cta) => cta.value === callToAction) ?? DEFAULT_CTA;

          setPrimaryCallToAction(option);
        }}
        callToActionOptions={availableCallToActions}
        isCallFlowDiscoveryTransfer={isCallFlowDiscoveryTransfer}
      />
      {otherCallToAction && (
        <CallToAction
          onRemove={() => {
            setOtherCallToAction(undefined);

            setValue("call_to_action_2", [] as never);
          }}
          switchPrimary={() => {
            const oldPrimary = primaryCallToAction;

            setPrimaryCallToAction((previousPrimary) => {
              setValue("call_to_action_2", previousPrimary.value as never);

              return otherCallToAction;
            });

            setOtherCallToAction((previousOther) => {
              setValue("call_to_action", previousOther?.value as never);

              return oldPrimary;
            });
          }}
          setCallToAction={(callToAction) => {
            const option = callToActions.find((cta) => cta.value === callToAction) ?? DEFAULT_CTA;

            setOtherCallToAction(option);
          }}
          callToAction={otherCallToAction}
          callToActionOptions={availableCallToActions}
          isCallFlowDiscoveryTransfer={isCallFlowDiscoveryTransfer}
        />
      )}

      {!isCallFlowDiscoveryTransfer && (
        <Button
          size="sm"
          sx={{
            backgroundColor: "#374667",
            color: "#fff",
            ...(!otherCallToAction && {
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#54658D",
                color: "#54658D",
              },
            }),
          }}
          leftIcon={<PlusIcon />}
          onClick={() => {
            setOtherCallToAction(DEFAULT_CTA);
          }}
          disabled={!!otherCallToAction}
        >
          Add Call To Action
        </Button>
      )}
    </Box>
  );
}
