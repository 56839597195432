import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { createColumnHelper } from "@tanstack/react-table";
import { dateTimeFormat } from "@/common/functions/dateFormat";
import TableDate from "@/common/components/table-date";

import { DataImportSequenceContacts } from "@/types/prospects";

const columnHelper = createColumnHelper<DataImportSequenceContacts>();

export const ContactsColumns = [
  columnHelper.accessor("firstName", {
    header: () => <TableHeader>First Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("lastName", {
    header: () => <TableHeader>Last Name</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => <TableHeader>Created Date</TableHeader>,
    cell: (info) => {
      const value = info.getValue();

      if (typeof value === "number") {
        const seconds = Math.floor(value / 1_000_000);
        const nanos = (value % 1_000_000) * 1_000;
        const formattedDate = dateTimeFormat(seconds, nanos);

        return <TableDate date={formattedDate} ignoreDateFormat={true} />;
      }

      const { seconds = 0, nanos = 0 } = value || {};
      const formattedDate = dateTimeFormat(seconds, nanos);

      return <TableDate date={formattedDate} ignoreDateFormat={true} />;
    },
  }),
];
